import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import Highcharts from 'highcharts';
import { AdminClientService } from '../../../../core/http/admin-client.service';

@Component({
	selector: 'app-table-filter-by-day',
	templateUrl: './table-filter-by-day.component.html',
	styleUrls: ['./table-filter-by-day.component.scss'],
})
export class TableFilterByDayComponent implements OnInit {
	Highcharts = Highcharts;
	chartOptions: {};
	yearSelected: Date;
	today = new Date();
	dataFilterByDay = [];
	tableFilterForm = new FormGroup({
		leng: new FormControl('es'),
		year: new FormControl(this.today.getFullYear()),
	});
	convertir: boolean = false;

	constructor(private adminService: AdminClientService) {}

	ngOnInit() {
		this.adminService.getMetricsByDay(this.tableFilterForm.value).then((res) => {
			if(res.mensaje_return.CODE === 200){
				this.dataFilterByDay = res.result;
				this.chartOptions = {
					chart: {
						type: 'column',
						heigth: 200,
					},
					title: {
						text: '',
					},
					xAxis: {
						categories: this.dataFilterByDay.map((res) => res.Dia),
						labels: {
							style: {
								color: '#000000',
								fontFamily: 'Noto-Ikea-Regular',
								fontSize: '14px',
								fontWeight: 'bold',
							},
						},
					},
					yAxis: {
						title: {
							text: '',
						},
					},
					legend: {
						enabled: false,
					},
					series: [
						{
							lineWidth: 3,
							marker: {
								enabled: true,
							},
							color: '#5B8FF9',
							name: 'Pickup',
							data: this.dataFilterByDay.map(
								(item) => item.cantidad_total_pickup
							),
							dataLabels: {
								enabled: true,
							},
						},
					],
					credits: {
						enabled: false,
					},
				};
			}
			else{
				this.chartOptions = {
					chart: {
						type: 'column',
						heigth: 200,
					},
					title: {
						text: '',
					},
					xAxis: {
						categories: this.dataFilterByDay.map((res) => res.Dia),
						labels: {
							style: {
								color: '#000000',
								fontFamily: 'Noto-Ikea-Regular',
								fontSize: '14px',
								fontWeight: 'bold',
							},
						},
					},
					yAxis: {
						title: {
							text: '',
						},
					},
					legend: {
						enabled: false,
					},
					series: [
						{
							lineWidth: 3,
							marker: {
								enabled: true,
							},
							color: '#5B8FF9',
							name: 'Pickup',
							data: this.dataFilterByDay.map(
								(item) => item.cantidad_total_pickup
							),
							dataLabels: {
								enabled: true,
							},
						},
					],
					credits: {
						enabled: false,
					},
				};
			}
		})
	}
	updateTable() {
		this.tableFilterForm
			.get('year')
			.setValue(this.yearSelected.getFullYear());
		this.adminService.getMetricsByDay(this.tableFilterForm.value).then((res) => {
			if(res.mensaje_return.CODE === 200){
				this.dataFilterByDay = res.result;
				this.chartOptions = {
					chart: {
						type: 'column',
						heigth: 200,
					},
					title: {
						text: '',
					},
					xAxis: {
						categories: this.dataFilterByDay.map((res) => res.Dia),
						labels: {
							style: {
								color: '#000000',
								fontFamily: 'Noto-Ikea-Regular',
								fontSize: '14px',
								fontWeight: 'bold',
							},
						},
					},
					yAxis: {
						title: {
							text: '',
						},
					},
					legend: {
						enabled: false,
					},
					series: [
						{
							lineWidth: 3,
							marker: {
								enabled: true,
							},
							color: '#5B8FF9',
							name: 'Pickup',
							data: this.dataFilterByDay.map(
								(item) => item.cantidad_total_pickup
							),
							dataLabels: {
								enabled: true,
							},
						},
					],
					credits: {
						enabled: false,
					},
				};
			}
		});
	}

	convertirPorcentaje() {
		this.convertir = !this.convertir;
		if (this.convertir === true) {
			let pickup = [];
			let porcentaje_pickup_by_day: number = 0;
			let porcentaje_pickup = [];
			let total_pickup: number = 0;
			this.dataFilterByDay.forEach((item) => {
				pickup.push(item.cantidad_total_pickup);
				total_pickup += item.cantidad_total_pickup;
			});
			pickup.forEach((res) => {
				porcentaje_pickup_by_day = (res / total_pickup) * 100;
				porcentaje_pickup.push(
					parseFloat(porcentaje_pickup_by_day.toFixed(2))
				);
			});
			this.chartOptions = {
				chart: {
					type: 'column',
					heigth: 200,
				},
				title: {
					text: '',
				},
				xAxis: {
					categories: this.dataFilterByDay.map((res) => res.Dia),
					labels: {
						style: {
							color: '#000000',
							fontFamily: 'Noto-Ikea-Regular',
							fontSize: '14px',
							fontWeight: 'bold',
						},
					},
				},
				yAxis: {
					title: {
						text: '',
					},
				},
				legend: {
					enabled: false,
				},
				plotOptions: {
					series: {
						stacking: 'normal',
						dataLabels: {
							enabled: true,
							formatter: function () {
								return this.y + '%';
							},
						},
					},
				},
				series: [
					{
						lineWidth: 3,
						marker: {
							enabled: true,
						},
						color: '#5B8FF9',
						name: 'Pickup',
						data: porcentaje_pickup.map((item) => item),
						stack: 'percent',
						dataLabels: {
							enabled: true,
						},
					},
				],
				credits: {
					enabled: false,
				},
			};
		} else {
			this.chartOptions = {
				chart: {
					type: 'column',
					heigth: 200,
				},
				title: {
					text: '',
				},
				xAxis: {
					categories: this.dataFilterByDay.map((res) => res.Dia),
					labels: {
						style: {
							color: '#000000',
							fontFamily: 'Noto-Ikea-Regular',
							fontSize: '14px',
							fontWeight: 'bold',
						},
					},
				},
				yAxis: {
					title: {
						text: '',
					},
				},
				legend: {
					enabled: false,
				},
				plotOptions: {
					series: {
						stacking: 'normal',
						dataLabels: {
							enabled: true,
							formatter: function () {
								return this.y;
							},
						},
					},
				},
				series: [
					{
						lineWidth: 3,
						marker: {
							enabled: true,
						},
						color: '#5B8FF9',
						name: 'Pickup',
						data: this.dataFilterByDay.map(
							(item) => item.cantidad_total_pickup
						),
						stack: 'normal',
						dataLabels: {
							enabled: true,
						},
					},
				],
				credits: {
					enabled: false,
				},
			};
		}
	}
}
