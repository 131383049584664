import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { FilterService } from 'src/app/core/http/FilterDataServiceOxxoToOxxo.service';
@Component({
  selector: 'app-filter-clients',
  templateUrl: './filter-clients.component.html',
  styleUrls: ['./filter-clients.component.scss']
})
export class FilterClientsComponent implements OnInit {
	@Input() filters:boolean=false;
	checkboxOne						: boolean = false;
	checkboxTwo						: boolean = false;
	checkboxThree 					: boolean = false;
	checkboxFour					: boolean = false;
	checkboxFive					: boolean = false;
	arrayFilterStars: Set<number> = new Set<number>();
	constructor(private filterService: FilterService) { }
	ngOnInit(): void {
	}
	actualizarStarsEnArreglo(valor: number, isChecked: boolean) {
		if (isChecked) {
			this.arrayFilterStars.add(valor);
		} else {
			this.arrayFilterStars.delete(valor);
		}
		this.filterService.updateStarsFilter(this.arrayFilterStars);
	}
	validarStars(){
		this.actualizarStarsEnArreglo(1, this.checkboxOne);
		this.actualizarStarsEnArreglo(2, this.checkboxTwo);
		this.actualizarStarsEnArreglo(3, this.checkboxThree);
		this.actualizarStarsEnArreglo(4, this.checkboxFour);
		this.actualizarStarsEnArreglo(5, this.checkboxFive);
	}
	openRate:boolean=false;
	openRateDiv(){
		this.openRate = !this.openRate;
	}
	closeModal(){
		this.filters = false
	}

}
