<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="header-profile">
            Mi Perfil
        </div>
    </div>

    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <div class="information-profile">
            <div class="information-profile-label">
                Informacion de la cuenta
            </div>
        </div>
    </div>
    <div class="col-6 col-sm-6 col-md-8 col-lg-9 col-xl-9">
    </div>

    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <div class="form-profile">
            <form>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-profile-label">
                            Nombre Completo
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <input class="form-profile-input" name="fname" [(ngModel)]="fname" type="text" matInput autocomplete="off" [disabled]="true">
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="form-profile-line">
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-profile-label">
                            Empresa
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <input class="form-profile-input" name="company" [(ngModel)]="company" type="text" matInput autocomplete="off" [disabled]="true">
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-profile-label form-input-margin-first">
                            ID de Empleado
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <input class="form-profile-input form-input-margin-first" name="id_user" [(ngModel)]="id_user" type="text" matInput autocomplete="off" [disabled]="true">
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-profile-label form-input-margin-first">
                            Idioma Preferido
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="display: flex; justify-content: center; align-items: center;">
                        <mat-select name="lang" [(ngModel)]="lang" class="form-profile-input" style="justify-content: center; display: flex; align-items: center;">
                            <mat-option value="EN">English</mat-option>
                            <mat-option value="ES">Español</mat-option>
                        </mat-select>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="form-profile-line">
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-profile-label">
                            E-mail
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <input class="form-profile-input" name="email" [(ngModel)]="email" type="text" matInput autocomplete="off" [disabled]="true">
                    </div>


                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-profile-label form-input-margin-first">
                            Contraseña
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <input class="form-profile-input form-input-margin-first" name="pass" [(ngModel)]="pass" type="{{type}}"  matInput autocomplete="off">
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="text-align: -webkit-right">
                        <button (click)="submit()" class="btn-primary-gbl">
                            {{'user.submit' | translate}}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

