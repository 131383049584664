//Angular
import {
	Component,
	Input,
	Output,
	OnInit,
	SimpleChanges,
	ViewChild,
	EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

//Services
import { MatTableDataSource } from '@angular/material/table';
import { Module } from 'src/app/core/models/admin-client.interfaces';
import { ClientGrid } from '../dialogs-admin/client-grid.component';
import { SharedService } from '../../../../core/services/table_to_map.service';

@Component({
	selector: 'app-table-orderss-admin',
	templateUrl: './table-orderss-admin.component.html',
	styleUrls: ['./table-orderss-admin.component.scss'],
})
export class TableOrderssAdminComponent implements OnInit {
	@Output() emiter_select = new EventEmitter<string>();
	@Input() data;
	@Input() columns: string;
	@Input() hover;
	@Input() link = 'true';
	delivery = [];
	unclaimed = [];
	coords;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	tempData = [];
	dataSource;
	displayedColumns: string[];
	local;
	locks: [];
	lockers: [];
	grid;
	count_door = 1;
	module: Module[] = [];
	status = [];
	currentURL: any = '';
	public page: number = 0;

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private sharedService: SharedService
	) {}

	ngOnInit() {
		this.currentURL = document.location.href.split('/');
		this.currentURL = this.currentURL[this.currentURL.length - 1];
		this.local = JSON.parse(localStorage.getItem('data'));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['hover']) {
			if (this.hover) {
				this.coords = JSON.parse(this.hover);
			}
		} else {
			if (this.data) {
				this.tempData = JSON.parse(this.data);
			}
			this.tempData.forEach((e) => {
				this.delivery.push(e.deliveries);
				this.unclaimed.push(e.unclaimed);
			});
			if (this.data) {
				this.data = JSON.parse(this.data);
			}
			this.displayedColumns = this.columns.split(',');
			if (this.data) {
				this.dataSource = new MatTableDataSource(this.data);
			}
			if (this.data) {
				this.dataSource.paginator = this.paginator;
			}
		}
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	async seeLocker(locker, i) {
		let grid = [];

		locker.MODULE.forEach((e, i) => {
			grid.push({
				name_locker_module: e.name_locker_module,
				template_module: e.template_module,
			});
		});

		localStorage.setItem('grid', JSON.stringify(grid));
		localStorage.setItem('id_locker', locker.id);
		localStorage.setItem('lat', locker.lat);
		localStorage.setItem('lng', locker.lng);
		localStorage.setItem('address', locker.address);
		localStorage.setItem(
			'status_access_locker',
			locker.status_access_locker
		);
		localStorage.setItem('locker_name', locker.lockerID);
		this.router.navigateByUrl(`l-retail/locker/${locker.id}`, { skipLocationChange: true});
	}

	viewInMap(locker, i) {
		this.sharedService.sendClickEvent(locker.lat, locker.lng, 17);
	}

	async seeGrid(locker, i) {
		this.dialog.open(ClientGrid, {
			width: window.innerHeight < window.innerWidth ? '50%' : '99%',
			height: window.innerHeight < window.innerWidth ? '60%' : '35%',
			data: {
				lat: locker.lat,
				lng: locker.lng,
				address: locker.address,
				lockerID: locker.lockerID,
			},
		});
	}

	ngOnDestroy() {
		if (this.currentURL != 'order') this.dialog.closeAll();
	}

	radioChange(event: any, locker: any) {
		this.emiter_select.emit(locker);
	}
}
