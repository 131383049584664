<div class="container-main">
	<div class="grid-header">
		<div class="span-txt">
		<span class="txt-title">
			{{'overview.cardsOverview.LockerUsageByComparmentSize' | translate}}
		</span>
			<div class="icon-info">
			<app-tooltip-center-sm
				class="tooltip-span"
				[tooltip]="tooltipUseLocker">
			</app-tooltip-center-sm>
			</div>
		</div>
	</div>
	<div class="chart">
		<apx-chart
		[series]="dataChartUseLocker.series"
		[chart]="dataChartUseLocker.chart"
		[responsive]="dataChartUseLocker.responsive"
		[plotOptions]="dataChartUseLocker.plotOptions"
		[xaxis]="dataChartUseLocker.xaxis"
		[legend]="dataChartUseLocker.legend"
		[dataLabels]="dataChartUseLocker.dataLabels"
		[stroke]="dataChartUseLocker.stroke"
		[fill]="dataChartUseLocker.fill"
		></apx-chart>
	</div>
	<div class="container-legend">
		<div class="align-text">
			<span><div class="circle-1"></div> {{'overview.orderDelivered' | translate}}</span>
			<span><div class="circle-2"></div> {{'overview.orderRecolected' | translate}}</span>
		</div>
	</div>
</div>
