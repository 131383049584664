<div class="container-fluid">
	<div class="row">


		<div class="col-12 noPaddings">
			<div class="headerClass" *ngIf="isDesactivate === false">
				{{'dialogChangeStatusUser.headerTitleActivate' | translate}}
			</div>
			<div class="headerClass" *ngIf="isDesactivate === true">
				{{'dialogChangeStatusUser.headerTitleDesactivate' | translate}}
			</div>
		</div>
		<div class="col-12 noPaddings">
			<div class="subHeaderClass" *ngIf="isDesactivate === false">
				{{'dialogChangeStatusUser.subHeaderTitleActivate' | translate}}
			</div>
			<div class="subHeaderClass" *ngIf="isDesactivate === true">
				{{'dialogChangeStatusUser.subHeaderTitleDesactivate' | translate}}
			</div>
		</div>


		<div class="col-12 noPaddings">
			<div class="row">
				<div class="col-4">
					<button class="cancelClass" (click)="closeModal()">
						{{'dialogChangeStatusUser.buttonCancel' | translate}}
					</button>
				</div>
				<div class="col-8 desactiveClass">
					<app-button *ngIf="isDesactivate === true"
						(click)="toggleStatus()"
						[activo]="false"
						btnNombre="dialogChangeStatusUser.buttonDesactivate"
						btnType="primaryDangerMedium">
					</app-button>
					<app-button *ngIf="isDesactivate === false"
					(click)="toggleStatus()"
					[activo]="false"
					btnNombre="dialogChangeStatusUser.buttonActivate"
					btnType="primaryDangerMedium">
				</app-button>
				</div>
			</div>
		</div>
	</div>
</div>
