import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'app-date-text-expired',
	templateUrl: './date-text-expired.component.html',
	styleUrls: ['./date-text-expired.component.scss'],
})
export class DateTextExpiredComponent implements ICellRendererAngularComp {
	fecha: Date;
	estatus: boolean;
	constructor() {}

	ngOnInit(): void {}

	agInit(params: ICellRendererParams<any, any>): void {
		this.estatus = params.data.pickedUp;
		if (params.value !== '') {
			this.fecha = new Date(params.value);
		} else {
			this.fecha = null;
		}
	}
	refresh(params: ICellRendererParams<any, any>): boolean {
		return false;
	}
}
