//ANGULAR
import { Component, ViewChild } from '@angular/core';
// APEXCHARTS
import {
	ApexAxisChartSeries,
	ApexChart,
	ChartComponent,
	ApexDataLabels,
	ApexPlotOptions,
	ApexYAxis,
	ApexLegend,
	ApexStroke,
	ApexXAxis,
	ApexFill,
	ApexTooltip,
} from 'ng-apexcharts';

//Tipos de ApexCharts, que son utilizados para definir opciones de gráficos.
export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	yaxis: ApexYAxis;
	xaxis: ApexXAxis;
	fill: ApexFill;
	tooltip: ApexTooltip;
	stroke: ApexStroke;
	legend: ApexLegend;
};
@Component({
	selector: 'app-delivered-packages-size',
	templateUrl: './delivered-packages-size.component.html',
	styleUrls: ['./delivered-packages-size.component.scss'],
})
export class DeliveredPackagesSizeComponent {
	@ViewChild('chart') chart: ChartComponent;
	public chartOptions: Partial<ChartOptions>;

	tooltipHistorical: string = 'oche de de de de';

	constructor() {
		this.chartOptions = {
			legend: {
				position: 'top',
				show: false,
			},
			series: [
				{
					name: 'Recolectados',
					data: [1500, 400, 4355, 257],
				},
			],
			chart: {
				type: 'bar',
				width: '699',
				height: 194,
				toolbar: {
					show: false, // Oculta el menú
				},
			},
			plotOptions: {
				bar: {
					horizontal: true,
					columnWidth: '65%',
					borderRadius: 3, // ajusta el valor según lo necesites
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				show: true,
				width: 2,
				colors: ['transparent'],
			},
			xaxis: {
				categories: ['Ch.', 'Med.', 'L.', 'XL.'],
				labels: {
					style: {
						colors: [
							'var(--color-primary-300)',
							'var(--color-primary-300)',
							'var(--color-primary-300)',
							'var(--color-primary-300)',
							'var(--color-primary-300)',
							'var(--color-primary-300)',
							'var(--color-primary-300)',
						],
					},
				},
			},
			yaxis: {
				// min: 50,
				// max: 4000,
				labels: {
					style: {
						colors: ['var(--color-primary-300)'],
					},
				},

				tickAmount: (400 - 50) / 50, // Este cálculo muestra divisiones cada 50 unidades
			},
			fill: {
				opacity: 1,
				colors: ['var(--color-chart-300)'],
			},
			tooltip: {
				y: {
					formatter: function (val) {
						return '$ ' + val + ' thousands';
					},
				},
			},
		};
	}
}
