<div class="row">
    <div class="col-12">
        <div class="headerEraseOrderBox">
            <label class="lblEraseOrderHeader">
                Eliminar Orden
            </label>
            <div class="subtitleEraseOrder">
                ¿Estas seguro de que deseas eliminar esta orden? perderás todos los datos asociados a ella. Esta acción es permanente.
            </div>
        </div>
    </div>
    <div class="col-xl-6" style="text-align: -webkit-right; margin-top: 12px;" >
        <button class="btn-cancelar" (click)="closeModal()">
            <div class="btn-text">
                Cancelar
            </div>
        </button>
    </div>
    <div class="col-xl-6" style="margin-top: 12px;">
        <button class="btn-eliminar" (click)="deletePackageSchedule()">
            <div class="btn-text">
                Si, deseo eliminarla
            </div>
        </button>
    </div>
</div>
