import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class EventSelectionService {
	private selectedEventsSubject = new BehaviorSubject<any[]>([]);
	public selectedEvents$: Observable<any[]> = this.selectedEventsSubject.asObservable();

	constructor() { }

	addSelectedEvent(item: any) {
		const updatedSelectedEvents = [...this.selectedEventsSubject.getValue()];
		updatedSelectedEvents.push({
			id_company_webhook_service: item.id_company_webhook_service,
			id_status_company_webhook_service: item.id_status_company_webhook_service
		});
		this.selectedEventsSubject.next(updatedSelectedEvents);
	}

	getSelectedEvents(): any[] {
		return this.selectedEventsSubject.getValue();
	}

	addSelectEventInCedis(item:any){
		const updatedSelectedEvents = [...this.selectedEventsSubject.getValue()];
		updatedSelectedEvents.push({
			tracking: item.tracking,
		});
		this.selectedEventsSubject.next(updatedSelectedEvents);
	}

	getSelectedEventsInCedis(): any[] {
		return this.selectedEventsSubject.getValue();
	}
}
