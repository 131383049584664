import { HttpClient, HttpContext, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private baseUrl: string = environment.url_server_backend;
	temp: string;

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};

	constructor(private http: HttpClient) {}

	// WELCOME-CODE SERVICE -------------------------------------------------->
	async validateResidenceCode(params: any) {
		return await this.http
			.get(this.baseUrl + '/server/auth/validate-code-register', {
				params: params,
			})
			.toPromise();
	}

	validate_user_password(data): Observable<any> {
		this.temp = this.baseUrl + '/server/user/validate-user-password';
		return this.http
			.post<any>(this.temp, JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.errorHandl));
	}
	// WELCOME-CODE ERROR
	errorHandl(error) {
		return throwError('Error en auth' + error);
	}

	// STEP-1 SERVICE -------------------------------------------------->
	async obtenerUnit(params: any) {
		return this.http
			.get(this.baseUrl + '/server/auth/list-unit', { params: params })
			.toPromise();
	}

	// STEP-2 SERVICE -------------------------------------------------->
	async registerConsumerAccount(params) {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
		};
		let url = this.baseUrl + '/server/auth/register-consumer-account';

		return await this.http.post(url, params, options).toPromise();
	}

	async validateUserPassword(params){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		let url =  this.baseUrl + '/server/user/validate-user-password';
		const body = JSON.stringify(params);
		const request = new HttpRequest('POST', url, body, { headers });
		const response = await this.http.request(request).toPromise();
		return response;
	}
}
