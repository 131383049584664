<!-- GRID HEADER -->
<div class="grid-header">
    <!-- TITLE CONTAINER -->
    <div class="span-txt">
      <!-- TITLE -->
      <span class="txt-title">
        {{'overview.cardsOverview.headquarters' | translate }}
      </span>
      <!-- ICON INFO -->
      <div class="icon-info">
        <!-- TOOLTIP -->
        <app-tooltip 
            class="tooltip-span" 
            [tooltip]="tooltipHeadquarters">
        </app-tooltip>
        
      </div>
  
    </div>
    <!-- BTN EXPORTAR -->
    <dropdown-export></dropdown-export>
</div>

<!--? TOTAL CHART -->
<div class="total">
    <span class="txt-total" >Total</span>
    <h4 class="txt-total-num" > {{totalSeries}} </h4>
</div>

<!-- CHART -->
<div class="chart-container" id="chart">
    <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [plotOptions]="chartOptions.plotOptions"
        [labels]="chartOptions.labels"
        [legend]="chartOptions.legend"
        [colors]="chartOptions.colors"
        [responsive]="chartOptions.responsive">
    </apx-chart>
</div>