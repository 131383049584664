import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { utils } from 'xlsx';
import * as XLSX from 'xlsx';
import { ClientSign } from '../dialogs-admin/client-dialog';
import { SuccesDialogComponent } from '../dialogs-admin/succes-dialog/succes-dialog.component';
import { UpdateEmail } from '../dialogs-admin/update-email';
import { ConfirmDelete } from '../dialogs-admin/confirm-delete';
import { OrderCreateComponent } from '../dialogs-admin/order-create/order-create.component';
import { AdminClientService } from 'src/app/core/http/admin-client.service';

@Component({
	selector: 'app-orders-table-admin',
	templateUrl: './orders-table-admin.component.html',
	styleUrls: ['./orders-table-admin.component.scss'],
})
export class OrdersTableAdminComponent implements OnInit {
	@Output() updateEvent = new EventEmitter<string>();
	@Input() header;
	@Input() data;
	@Input() columns: string;

	@Output() EmitDate = new EventEmitter<string>();

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	//@HostListener('window:resize', ['$event'])
	date = [];
	local;
	lockers: [];
	dataSource: MatTableDataSource<[]>;
	dataSourceFilterDate: MatTableDataSource<[]>;
	displayedColumns: string[];
	minDate: Date;
	maxDate: Date;
	dataSendUpdate;
	ok: string = '';
	starRating = 0;
	date_currient = new Date();
	name_file =
		'data_' +
		this.date_currient.getDate() +
		'_' +
		(this.date_currient.getMonth() + 1) +
		'_' +
		this.date_currient.getFullYear();
	disabled_dowloade: string = '';
	disabled_dowloade_button: boolean = true;
	validExts = new Array('xlsx', 'xls', 'csv');
	validExtsFile = new Array('TRACKING', 'EMAIL', 'PHONE');
	exceltoJson;
	dataTrackingFail: any = [];
	button_mobile: boolean = false;

	selectMonth;

	rangeDates: Date[];
	today = new Date();
	year = this.today.getFullYear();
	month = this.today.getMonth();
	day = this.today.getDate();
	lastMonth: Date;
	startDate: Date;
	endDate: Date;

	constructor(
		private dialog: MatDialog,
		private adminService: AdminClientService,
		private router: Router,
		private component: AppComponent,
		private translate: TranslateService
	) {}
	ngOnDestroy() {
		this.dialog.closeAll();
	}

	ngOnInit() {
		this.minDate = new Date();
		this.maxDate = new Date();
		this.minDate.setDate(this.minDate.getDate() + 1);
		this.maxDate.setDate(this.maxDate.getDate() + 2);
		this.displayedColumns = this.columns.split(',');
		this.local = JSON.parse(localStorage.getItem('data'));
		if (window.innerWidth <= 1100) {
			this.button_mobile = true;
		} else {
			this.button_mobile = false;
		}
	}
	onFileChange(event: any) {
		this.exceltoJson = {};
		let headerJson = {};
		let validateHeader: boolean = true;

		const target: DataTransfer = <DataTransfer>event.target;
		const reader: FileReader = new FileReader();
		reader.readAsBinaryString(target.files[0]);
		let fileExt = target.files[0].name.split('.').pop();
		if (this.validExts.indexOf(fileExt) == 0) {
			this.exceltoJson['filename'] = target.files[0].name;
			reader.onload = (e: any) => {
				const binarystr: string = e.target.result;
				const wb: XLSX.WorkBook = XLSX.read(binarystr, {
					type: 'binary',
				});
				for (let i = 0; i < wb.SheetNames.length; ++i) {
					const wsname: string = wb.SheetNames[i];
					const ws: XLSX.WorkSheet = wb.Sheets[wsname];
					const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
					this.exceltoJson[`sheet${i + 1}`] = data;
					const headers = this.get_header_row(ws);
					headerJson[`header${i + 1}`] = headers;
					if (headers.length == this.validExtsFile.length) {
						headers.forEach((h: any) => {
							if (
								this.validExtsFile.indexOf(h.toUpperCase()) < 0
							) {
								validateHeader = false;
							}
						});
					} else {
						validateHeader = false;
					}
				}
				if (validateHeader) {
					this.exceltoJson['headers'] = headerJson;
					if (this.exceltoJson.sheet1.length > 0) {
						this.creteOrder();
					} else {
					}
				} else {
					this.translate
						.get(['general.error_file_fields', 'general.error'])
						.subscribe((t: any) => {
							let dialogRef = this.dialog.open(ClientSign, {
								width: '40%',
								data: {
									title: t['general.error'],
									message: t['general.error_file_fields'],
									type: 'message',
								},
							});
						});
					this.updateEvent.emit('false');
				}
			};
		} else {
			this.translate.get(['general.err_type_file', 'general.error']).subscribe((t: any) => {
				let dialogRef = this.dialog.open(ClientSign, {
					width: '40%',
					data: {
						title: t['general.error'],
						message: t['general.err_type_file'],
						type: 'message',
					},
				});
			});
			this.updateEvent.emit('false');
		}
		event.target.value = '';
	}
	addEventMonth(dateValue) {
		let dateYear = dateValue.value.substring(0, 4);
		let dateMes = dateValue.value.substring(5, 7);
		const date = new Date(dateYear, dateMes);
		const firstDayOfMonth = new Date(
			date.getFullYear(),
			date.getMonth(),
			1
		).getDate();
		const lastDayOfMonth = new Date(
			date.getFullYear(),
			date.getMonth(),
			0
		).getDate();
		const dateInicio =
			dateMes + '/0' + firstDayOfMonth + '/' + dateYear + ' 00:00:00';
		const dateFin =
			dateMes + '/' + lastDayOfMonth + '/' + dateYear + ' 23:59:59';
		this.EmitDate.emit(dateInicio + '*' + dateFin);
	}

	selectMonthEvent() {
		let fecha = new Date(this.selectMonth);
		let dateInicio;
		let dateFin;
		if (fecha.getMonth() < 10) {
			const firstDayOfMonth = new Date(
				fecha.getFullYear(),
				fecha.getMonth(),
				1
			).getDate();
			const lastDayOfMonth = new Date(
				fecha.getFullYear(),
				fecha.getMonth(),
				0
			).getDate();
			dateInicio =
				'0' +
				(fecha.getMonth() + 1) +
				'/0' +
				firstDayOfMonth +
				'/' +
				fecha.getFullYear() +
				' 00:00:00';
			dateFin =
				'0' +
				(fecha.getMonth() + 1) +
				'/' +
				lastDayOfMonth +
				'/' +
				fecha.getFullYear() +
				' 23:59:59';
		} else {
			const firstDayOfMonth = new Date(
				fecha.getFullYear(),
				fecha.getMonth(),
				1
			).getDate();
			const lastDayOfMonth = new Date(
				fecha.getFullYear(),
				fecha.getMonth(),
				0
			).getDate();
			dateInicio =
				fecha.getMonth() +
				1 +
				'/0' +
				firstDayOfMonth +
				'/' +
				fecha.getFullYear() +
				' 00:00:00';
			dateFin =
				fecha.getMonth() +
				1 +
				'/' +
				lastDayOfMonth +
				'/' +
				fecha.getFullYear() +
				' 23:59:59';
		}
		this.EmitDate.emit(dateInicio + '*' + dateFin);
	}
	downloadExcel() {
		// Define las columnas del archivo Excel que se creará
		let headerExcel = [
			'tracking',
			'lockerID',
			'email',
			'employeId',
			'nameEmploye',
			'inLockerDate',
			'inLockerTime',
			'packagePickupDate',
			'packagePickupTime',
		];
		// Crea un array vacío para almacenar los datos que se agregarán al archivo Excel
		let dataExcel = [];
		// Filtra los datos del objeto dataSource para generar los datos para el archivo Excel
		const filteredData = this.dataSource.data.filter((e: any) => {
			// Comprueba si la longitud de la cadena en la posición 11 a 19 de la variable e.packagePickupDate es igual a 7
			if (e.packagePickupDate.substring(11, 19).length === 7) {
				// Si la longitud de la cadena en la posición 11 a 19 de la variable e.inLocker es igual a 7
				if (e.inLocker.substring(11, 19).length === 7) {
					// Crea un objeto con los datos filtrados y los agrega al array dataExcel
					let info = {
						tracking: e.tracking,
						lockerID: e.lockerID,
						email: e.email,
						employeId: e.employeId,
						nameEmploye: e.nameEmploye,
						inLockerDate: e.inLocker.substring(0, 10),
						inLockerTime: '0' + e.inLocker.substring(11, 19),
						packagePickupDate: e.packagePickupDate.substring(0, 10),
						packagePickupTime:
							'0' + e.packagePickupDate.substring(11, 19),
					};
					dataExcel.push(info);
				} else if (e.inLocker.substring(11, 19).length === 8) {
					let info = {
						tracking: e.tracking,
						lockerID: e.lockerID,
						email: e.email,
						employeId: e.employeId,
						nameEmploye: e.nameEmploye,
						inLockerDate: e.inLocker.substring(0, 10),
						inLockerTime: e.inLocker.substring(11, 19),
						packagePickupDate: e.packagePickupDate.substring(0, 10),
						packagePickupTime:
							'0' + e.packagePickupDate.substring(11, 19),
					};
					dataExcel.push(info);
				}
			} else if (e.packagePickupDate.substring(11, 19).length === 8) {
				if (e.inLocker.substring(11, 19).length === 7) {
					let info = {
						tracking: e.tracking,
						lockerID: e.lockerID,
						email: e.email,
						employeId: e.employeId,
						nameEmploye: e.nameEmploye,
						inLockerDate: e.inLocker.substring(0, 10),
						inLockerTime: '0' + e.inLocker.substring(11, 19),
						packagePickupDate: e.packagePickupDate.substring(0, 10),
						packagePickupTime: e.packagePickupDate.substring(
							11,
							19
						),
					};
					dataExcel.push(info);
				} else if (e.inLocker.substring(11, 19).length === 8) {
					let info = {
						tracking: e.tracking,
						lockerID: e.lockerID,
						email: e.email,
						employeId: e.employeId,
						nameEmploye: e.nameEmploye,
						inLockerDate: e.inLocker.substring(0, 10),
						inLockerTime: e.inLocker.substring(11, 19),
						packagePickupDate: e.packagePickupDate.substring(0, 10),
						packagePickupTime: e.packagePickupDate.substring(
							11,
							19
						),
					};
					dataExcel.push(info);
				}
			}
		});

		filteredData.forEach((e: any) => {
			// Comprueba si la longitud de la cadena en la posición 11 a 19 de la variable e.packagePickupDate es igual a 7
			if (e.packagePickupDate.substring(11, 19).length === 7) {
				// Si la longitud de la cadena en la posición 11 a 19 de la variable e.inLocker es igual a 7
				if (e.inLocker.substring(11, 19).length === 7) {
					// Crea un objeto con los datos filtrados y los agrega al array dataExcel
					let info = {
						tracking: e.tracking,
						lockerID: e.lockerID,
						email: e.email,
						employeId: e.employeId,
						nameEmploye: e.nameEmploye,
						inLockerDate: e.inLocker.substring(0, 10),
						inLockerTime: '0' + e.inLocker.substring(11, 19),
						packagePickupDate: e.packagePickupDate.substring(0, 10),
						packagePickupTime:
							'0' + e.packagePickupDate.substring(11, 19),
					};
					dataExcel.push(info);
				} else if (e.inLocker.substring(11, 19).length === 8) {
					let info = {
						tracking: e.tracking,
						lockerID: e.lockerID,
						email: e.email,
						employeId: e.employeId,
						nameEmploye: e.nameEmploye,
						inLockerDate: e.inLocker.substring(0, 10),
						inLockerTime: e.inLocker.substring(11, 19),
						packagePickupDate: e.packagePickupDate.substring(0, 10),
						packagePickupTime:
							'0' + e.packagePickupDate.substring(11, 19),
					};
					dataExcel.push(info);
				}
			} else if (e.packagePickupDate.substring(11, 19).length === 8) {
				if (e.inLocker.substring(11, 19).length === 7) {
					let info = {
						tracking: e.tracking,
						lockerID: e.lockerID,
						email: e.email,
						employeId: e.employeId,
						nameEmploye: e.nameEmploye,
						inLockerDate: e.inLocker.substring(0, 10),
						inLockerTime: '0' + e.inLocker.substring(11, 19),
						packagePickupDate: e.packagePickupDate.substring(0, 10),
						packagePickupTime: e.packagePickupDate.substring(
							11,
							19
						),
					};
					dataExcel.push(info);
				} else if (e.inLocker.substring(11, 19).length === 8) {
					let info = {
						tracking: e.tracking,
						lockerID: e.lockerID,
						email: e.email,
						employeId: e.employeId,
						nameEmploye: e.nameEmploye,
						inLockerDate: e.inLocker.substring(0, 10),
						inLockerTime: e.inLocker.substring(11, 19),
						packagePickupDate: e.packagePickupDate.substring(0, 10),
						packagePickupTime: e.packagePickupDate.substring(
							11,
							19
						),
					};
					dataExcel.push(info);
				}
			}
		});
		const worksheet = utils.json_to_sheet(dataExcel, {
			header: headerExcel,
		});
		const workbook = utils.book_new();
		utils.book_append_sheet(workbook, worksheet, 'Data');
	}
	onResize(event) {
		if (event.target.innerWidth <= 1100 && this.button_mobile === false) {
			this.button_mobile = true;
		} else if (
			event.target.innerWidth > 1100 &&
			this.button_mobile === true
		) {
			this.button_mobile = false;
		}
	}
	ngOnChanges() {
		if (this.data) {
			this.updateDataList(JSON.parse(this.data));
		}
	}
	get_header_row(sheet) {
		let headers = [];
		let range = XLSX.utils.decode_range(sheet['!ref']);
		let C,
			R = range.s.r; /* start in the first row */
		/* walk every column in the range */
		for (C = range.s.c; C <= range.e.c; ++C) {
			let cell =
				sheet[
					XLSX.utils.encode_cell({ c: C, r: R })
				]; /* find the cell in the first row */
			let hdr = 'UNKNOWN ' + C; // <-- replace with your desired default
			if (cell?.t) {
				hdr = XLSX.utils.format_cell(cell);
				headers.push(hdr);
			}
		}
		return headers;
	}
	validarEmail(email) {
		let regex =
			/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		return regex.test(email) ? true : false;
	}
	async creteOrder() {
		localStorage.removeItem('register_package');
		let date = new Date();
		let day = date.getDate();
		let month = date.getMonth() + 1;
		let year = date.getFullYear();
		let res: any;
		let data = {
			language: 'en',
			date_schedule: year + '-' + month + '-' + day,
			data_schedule_package: [],
		};
		this.dataTrackingFail = [];
		this.exceltoJson.sheet1.forEach((p: any) => {
			if (
				p.EMAIL != '' &&
				p.TRACKING != '' &&
				p.TRACKING.toString().length >= 8
			) {
				if (this.validarEmail(p.EMAIL)) {
					data.data_schedule_package.push({
						code_tracking: 'OX000' + p.TRACKING,
						main_tlf: p.PHONE,
						email_consumer: p.EMAIL,
					});
				} else {
					this.dataTrackingFail.push({
						code_tracking: 'OX000' + p.TRACKING,
						main_tlf: p.PHONE,
						email_consumer: p.EMAIL,
					});
				}
			} else {
				this.dataTrackingFail.push({
					code_tracking: 'OX000' + p.TRACKING,
					main_tlf: p.PHONE,
					email_consumer: p.EMAIL,
				});
			}
		});
		res = await this.adminService.validateTracking(data);
		if (res.mensaje_return.ERROR) {
			this.dialog.open(SuccesDialogComponent, {
				width: '40%',
				data: { status: true },
			});
			this.updateEvent.emit('true');
		} else {
			data.data_schedule_package = [];
			data.data_schedule_package = res.retunr_package_success;

			if (res.retunr_package_failure.length > 0)
				res.retunr_package_failure.forEach((p: any) => {
					this.dataTrackingFail.push({
						code_tracking: p.code_tracking,
						main_tlf: p.main_tlf,
						email_consumer: p.email_consumer,
					});
				});
			this.translate
				.get(['general.title_confirm'])
				.subscribe((t: any) => {
					let dialogRef = this.dialog
						.open(ClientSign, {
							width: '90%',
							height: '85%',
							data: {
								type: 'confirm_package',
								title: t['general.title_confirm'],
								retunr_package_success:
									res.retunr_package_success,
								retunr_package_failure: this.dataTrackingFail,
							},
						})
						.afterClosed()
						.subscribe(() => {
							if (
								localStorage.getItem('register_package') ===
								'true'
							) {
								data = {
									language: 'en',
									date_schedule:
										year + '-' + month + '-' + day,
									data_schedule_package:
										res.retunr_package_success,
								};
								this.createOrder(data)
							}
						});
				});
		}
	}
	async createOrder(data: any) {
		let res: any = await this.adminService.createOrder(data);
		if (res.mensaje_return.ERROR) {
			this.dialog.open(SuccesDialogComponent, {
				width: '40%',
				data: { status: false, codigo: res.mensaje_return.CODE },
			});
			this.updateEvent.emit('false');
		} else {
			this.dialog.open(SuccesDialogComponent, {
				width: '40%',
				data: { status: true },
			});
			this.updateEvent.emit('true');
		}
	}
	updateDataList(data_list: any) {
		this.dataSource = new MatTableDataSource(data_list);
		this.dataSourceFilterDate = new MatTableDataSource(data_list);
		this.dataSource.filteredData.forEach((d: any) => {
			this.translate
				.get('table.' + d.status.toLowerCase())
				.subscribe((text: string) => {
					d.status_traslate = text;
				});
		});

		if (this.dataSource.filteredData.length == 0)
			this.disabled_dowloade = '-disabled';
		else this.disabled_dowloade_button = false;

		this.dataSource.paginator = this.paginator;
	}
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	addEvent(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
		this.EmitDate.emit(dateRangeStart.value + '*' + dateRangeEnd.value);
	}
	addEventRangeDates() {
	}

	signatureModal(img) {
		this.dialog.open(ClientSign, {
			width: window.innerHeight < window.innerWidth ? '55%' : '80%',
			height: window.innerHeight < window.innerWidth ? '65%' : '40%',
			data: { image: img, type: 'sign' },
		});
	}
	pickedModal(evidence: any, tracking: string) {
		this.dialog.open(ClientSign, {
			width: '100vw',
			height: '100vw',
			data: {
				tracking: tracking,
				url_view_image_pickup: evidence[0].url_view_image_pickup,
				url_view_imagen_signature:
					evidence[0].url_view_imagen_signature,
				type: 'evidence',
			},
		});
	}
	pickedModalQRSchedule(img) {
		this.dialog.open(ClientSign, {
			width: window.innerHeight < window.innerWidth ? '35%' : '60%',
			height: window.innerHeight < window.innerWidth ? '65%' : '50%',
			data: {
				image: img,
				type: 'qr_schedule',
			},
		});
	}
	pickedModalQR(img) {
		this.dialog.open(ClientSign, {
			width: window.innerHeight < window.innerWidth ? '35%' : '60%',
			// height: window.innerHeight<window.innerWidth?'65%':'50%',
			data: {
				image: img,
				type: 'qr',
			},
		});
	}
	redirectTo(uri: string) {
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([uri]));
	}
	edit(element: any) {
		this.dialog
			.open(UpdateEmail, {
				width: window.innerHeight < window.innerWidth ? '30%' : '50%',
				height: window.innerHeight < window.innerWidth ? '35%' : '10%',
				data: {
					order: element.order,
					email: element.email,
					id_package_code: element.id_package_code,
					inLocker: element.inLocker,
				},
			})
			.afterClosed()
			.subscribe(() => {
				if (localStorage.getItem('update-email-consumer') == 'true') {
					localStorage.removeItem('update-email-consumer');
					setTimeout(() => {
						this.redirectTo('l-retail/packages');
					}, 1000);
				}
			});
	}
	cancel() {
		this.redirectTo('l-retail/packages');
	}
	async update() {
		let res: any = await this.adminService.updateExpirationDate(
			this.dataSendUpdate
		);
		if (res.mensaje_return.ERROR === true) {
			this.ok = 'err';
		} else {
			this.ok = 'ok';
		}
		setTimeout(() => {
			this.redirectTo('l-retail/packages');
		}, 3000);
	}
	async updateTemp(order, date: Date) {
		if (date) {
			let month =
				date.getMonth().toString().length == 1
					? '0' + (date.getMonth() + 1)
					: date.getMonth() + 1;
			let day =
				date.getDate().toString().length == 1
					? '0' + date.getDate()
					: date.getDate();
			let d = date.getFullYear() + '-' + month + '-' + day;
			this.dataSendUpdate = {
				language: localStorage.getItem('language').toString(),
				id_company: this.local.data_company.id_company,
				id_package_code: order.id_package_code,
				date_update: d,
			};
			this.update();
		}
	}
	compare(
		a: number | string | Date,
		b: number | string | Date,
		isAsc: boolean
	) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}
	deletePackageSchedule(element: any) {
		this.translate
			.get('orden.confirmDelete')
			.subscribe((message: string) => {
				this.dialog
					.open(ConfirmDelete, {
						data: {
							message: message,
						},
					})
					.afterClosed()
					.subscribe(() => {
						if (localStorage.getItem('deleted')) {
							let params = {
								language: localStorage
									.getItem('language')
									.toLowerCase(),
								id_service: element.id_service,
							};
							this.adminService
								.deleteSchedulePackage(params)
								.then((res: any) => {
									if (res.mensaje_return.ERROR === false) {
										const data =
											this.dataSource.filteredData.slice();
										let data_soure: any = data.filter(
											(service: any) =>
												service.id_service !==
												element.id_service
										);
										this.updateDataList(data_soure);
									} else {
										localStorage.setItem('deleted', '0');
									}
								});
						}
						localStorage.removeItem('deleted');
					});
			});
	}
	createPackageSchedule() {
		this.translate
			.get(['orden.title', 'buttons.create_orden'])
			.subscribe((t: any) => {
				let dialogRef = this.dialog.open(OrderCreateComponent, {
					width: '80%',
					height: '90%',
					data: {
						title: t['orden.title'],
						text_button: t['buttons.create_orden'],
						edit_package: false,
					},
				});
				dialogRef.componentInstance.update.subscribe((data: any) => {
					this.updateEvent.emit('true');
				});
			});
	}
	editPackageSchedule(element: any) {
		this.translate
			.get(['orden.title_update', 'buttons.update_button'])
			.subscribe((t: any) => {
				let dialogRef = this.dialog.open(OrderCreateComponent, {
					data: {
						title: t['orden.title_update'],
						text_button: t['buttons.update_button'],
						edit_package: true,
						data_package: element,
					},
				});
				dialogRef.componentInstance.update.subscribe((data: any) => {
					this.updateEvent.emit('true');
				});
			});
	}
	sortData(sort: Sort) {
		const data = this.dataSource.filteredData.slice();
		let data_soure: any = data.slice().sort((a: any, b: any) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'order':
					return this.compare(
						Number(a.order),
						Number(b.order),
						isAsc
					);
				case 'tracking':
					return this.compare(
						String(a.tracking),
						String(b.tracking),
						isAsc
					);
				case 'lockerID':
					return this.compare(
						String(a.lockerID),
						String(b.lockerID),
						isAsc
					);
				case 'email':
					return this.compare(
						String(a.email),
						String(b.email),
						isAsc
					);
				case 'employeId':
					return this.compare(
						Number(a.employeId),
						Number(b.employeId),
						isAsc
					);
				case 'nameEmploye':
					return this.compare(
						String(a.nameEmploye),
						String(b.nameEmploye),
						isAsc
					);
				case 'doors':
					return this.compare(
						Number(a.doors),
						Number(b.doors),
						isAsc
					);
				case 'compartment':
					return this.compare(
						String(a.compartment),
						String(b.compartment),
						isAsc
					);
				case 'status':
					return this.compare(
						String(a.status),
						String(b.status),
						isAsc
					);
				case 'inLocker':
					return this.compare(
						new Date(a.inLocker),
						new Date(b.inLocker),
						isAsc
					);
				case 'packagePickupDate':
					return this.compare(
						new Date(a.packagePickupDate),
						new Date(b.packagePickupDate),
						isAsc
					);
				case 'pickedUp':
					return this.compare(
						new Date(a.pickedUp),
						new Date(b.pickedUp),
						isAsc
					);
				case 'registrationDateExpired':
					return this.compare(
						new Date(a.registrationDateExpired),
						new Date(b.registrationDateExpired),
						isAsc
					);
				case 'registrationDateRefund':
					return this.compare(
						new Date(a.registrationDateRefund),
						new Date(b.registrationDateRefund),
						isAsc
					);
				default:
					return 0;
			}
		});
		this.updateDataList(data_soure);
	}

	async seeUser(idUsuerio) {
		this.router.navigateByUrl(`l-retail/user/search/${idUsuerio}`, {skipLocationChange: true});
	}
	moreInfoPackage(data_package: any) {
		this.translate
			.get(['orden.package_information'])
			.subscribe((t: any) => {
				let dialogRef = this.dialog.open(ClientSign, {
					width: '60%',
					height: '80%',
					data: {
						title: t['orden.package_information'],
						type: 'package_information',
						data_package: data_package,
					},
				});
			});
	}
}
