import { Component, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
	ApexAxisChartSeries,
	ApexChart,
	ChartComponent,
	ApexDataLabels,
	ApexPlotOptions,
	ApexResponsive,
	ApexXAxis,
	ApexLegend,
	ApexFill,
} from 'ng-apexcharts';

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	responsive: ApexResponsive[];
	xaxis: ApexXAxis;
	legend: ApexLegend;
	fill: ApexFill;
};

@Component({
	selector: 'app-tracking-expired-orders',
	templateUrl: './tracking-expired-orders.component.html',
	styleUrls: ['./tracking-expired-orders.component.scss'],
})
export class TrackingExpiredOrdersComponent {
	@ViewChild('chart') chart: ChartComponent;
	public dataChartExpiredOrders: Partial<ChartOptions>;

	tooltipTracking: string;
	// DATES
	@Input() periodBase: string = '';
	@Input() periodComp: string = '';
	@Input() changeStyles: string = 'dates-default';
	@Input() dataBase: number[] = [];
	@Input() dataComp: number[] = [];

	constructor(private translate: TranslateService) {
		translate.setDefaultLang(
			localStorage.getItem('language').toLowerCase()
		);
		this.tooltipTracking = this.translate.instant(
			'tooltip.ordersTrackingExpired'
		);
	}

	ngOnInit() {
		this.loadDataChart();
	}

	ngOnChanges() {
		this.loadDataChart();
	}

	private loadDataChart() {
		this.dataChartExpiredOrders = {
			series: [
				{
					name: this.translate.instant('overview.basePeriod'),
					data: this.dataBase,
				},
				{
					name: this.translate.instant('overview.comparisonPeriod'),
					data: this.dataComp,
				},
			],
			chart: {
				type: 'bar',
				height: 300,
				stacked: true,
				toolbar: {
					show: true,
				},
				zoom: {
					enabled: true,
				},
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						legend: {
							position: 'bottom',
							offsetX: -10,
							offsetY: 0,
						},
					},
				},
			],
			plotOptions: {
				bar: {
					horizontal: false,
				},
			},
			xaxis: {
				type: 'category',
				categories: [''],
			},
			legend: {
				position: 'bottom',
				offsetY: 10,
				markers: {
					fillColors: ['#18345D', '#0082CB'], // Colores personalizados para los marcadores de la leyenda
				},
			},
			fill: {
				opacity: 1,
				colors: ['#18345D', '#0082CB'],
			},
		};
	}
}
