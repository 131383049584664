<div class="expired">
	<div class="row">
		<div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
			<div class="input-container">
				<input
				[(ngModel)]="filterText"
				type="text"
				class="input-gbl"
				(keyup)="onFilterTextChange($event)"
				placeholder="{{'dashboard.search_user' | translate}}"/>
				<button type="submit" class="btn-search">
					<div class="icon-search">
					</div>
				</button>
			</div>
		</div>
		<div class="col-sm-12 col-md-12 col-lg-8 col-xl-8">
			<div class="filters">
				<!-- <p-calendar class="selectYear"
							[showIcon]="true"
							[(ngModel)]="rangeDates"
							(ngModelChange)="addEventRangeDates()"
							selectionMode="range"
							[readonlyInput]="true"
							inputId="range" >
				</p-calendar> -->
				<app-button-icon [activo]="false" btnNombre="Exportar .xls" btnType="primaryMedium" icon="icon-file-download">
				</app-button-icon>
			</div>

		</div>

		<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
			<ag-grid-angular
				#agGrid
				class="ag-theme-alpine"
				[gridOptions]="gridOptions"
				[rowData]="rowData"
				suppressScrollOnNewData = true
				[columnDefs]="this.columnDefsGeneralOTO"
				style="width: 100%; height: 714px; border: none; margin-top:24px"
				[quickFilterText]="this.filterText"
				(gridReady)="onGridReady($event)">
			</ag-grid-angular>
		</div>
	</div>
</div>
