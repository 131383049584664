<!-- GRID HEADER -->
<div class="grid-header">
    <!-- TITLE CONTAINER -->
    <div class="span-txt">
		<!-- TITLE -->
		<span class="txt-title">
			{{'overview.cardsOverview.qualifyingAverages' |translate}}
		</span>
			<!-- ICON INFO -->
		<div class="icon-info">
			<!-- TOOLTIP -->
			<app-tooltip-center-sm
				class="tooltip-span"
				[tooltip]="tooltipRating">
			</app-tooltip-center-sm>
		</div>
    </div>
</div>

   <div class="grid-average">
    <!-- COLUMN 1-->
    <div class="column-1">
        <!-- SPAN -->
        <span class="span-kpi">{{qualification}}</span>
        <!-- STARS -->
        <div class="stars">
			<app-star-rating [rating]="rating">
			</app-star-rating>
        </div>
        <!-- FOOTER -->
        <span class="span-kpi-footer">
            {{'overview.averageReviews' | translate}} {{quantityQualification}} {{'overview.reviews'|translate}}
        </span>
    </div>
    <!-- COLUMN 2 -->
    <div class="column-2">
        <div class="chart">
            <apx-chart
				class="custome-apexchart"
				[series]="chartOptions.series"
				[chart]="chartOptions.chart"
				[dataLabels]="chartOptions.dataLabels"
				[plotOptions]="chartOptions.plotOptions"
				[xaxis]="chartOptions.xaxis"
				[responsive]="chartOptions.responsive"
				[legend]="chartOptions.legend"
				[fill]="chartOptions.fill"
				[stroke]="chartOptions.stroke">
            </apx-chart>
        </div>
    </div>
</div>
