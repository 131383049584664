import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TableCarrier } from 'src/app/core/models/carriers/module-carrier/carrier-mod.interface';
import { DialogChangeStatusCarrierComponent } from '../dialog-change-status-carrier/dialog-change-status-carrier.component';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-dialog-carrier-detail',
	templateUrl: './dialog-carrier-detail.component.html',
	styleUrls: ['./dialog-carrier-detail.component.scss']
})
export class DialogCarrierDetailComponent implements OnInit, OnDestroy {

	btnOrdenes:string = 'Ver ordenes';
	btnUsuarios:string = 'Ver usuarios';
	carrier:TableCarrier;
	HourRegister;
	MinutesRegister:number;
	DateRegister;
	carrierId:number;
	status;


	constructor(
		private translate: TranslateService,
		private dialogRef: MatDialogRef<DialogCarrierDetailComponent>,
		@Inject(MAT_DIALOG_DATA) private data,
		private router: Router,
		private dialog: MatDialog,
		private datePipe: DatePipe
	) { }

	ngOnInit(): void {
		this.carrier = this.data;
		this.carrierId = this.carrier.id_courier;
		let dateRegister = new Date(this.data.registration_date);
		this.HourRegister = dateRegister.toLocaleString("es-MX",{
			hour:'2-digit',
			minute:'2-digit'
		});
		this.MinutesRegister = dateRegister.getMinutes();
		this.DateRegister = dateRegister.toLocaleString("es-MX",{
			day:'2-digit',
			month:'short',
			year:'2-digit',
		});
		this.translate.use(localStorage.getItem('language').toLowerCase());
	}
	goToOrdersCarriers(){
		localStorage.setItem('courier_id',''+this.carrierId)
		this.router.navigateByUrl('l-retail/carriers/'+this.carrier.courier_name+'/orders');
		this.dialogRef.close();
	}
	goToUsersCarriers(){
		localStorage.setItem('courier_id',''+this.carrierId)
		this.router.navigateByUrl('l-retail/carriers/'+this.carrier.courier_name+'/users');
		this.dialogRef.close();
	}
	ngOnDestroy(): void {
		this.dialogRef.close();
	}

	closeModal() {
		this.dialogRef.close();
	}

	deshabilitarCarrier(){
		const dialogRef = this.dialog.open(DialogChangeStatusCarrierComponent, {
			width: '365px',
			height: '422px',
			data : {
				data: this.carrier,
				isFromDialog:true
			}
		});

		dialogRef.afterClosed().subscribe((result) => {
		});
	}


}
