<!-- ROW 1 -->

<div class="row justify-content-center mb-3">
	<div class="col-10 col-md-5 col-lg-4 col-xl-2 mb-3">

		<div class="card text-center" style="height: 350px">
			<div class="card-body">
				<div class="card-title">
					<img src="../../../../../assets/icons/info-solid.svg" matTooltip="hello world" class="icon-info float-end"/>
				</div>
				<h5 class="card-title cards-vivipost fw-semibold">
					<p>
						{{ "general.total" | translate | uppercase }}<br/>
						{{ "lockers.total-customer" | translate | uppercase }}
					</p>
				</h5>
				<p class="card-text card-txt-vivipost fw-light text-muted">
					{{'general.customers' | translate}}<br/>
					<span class="big">
						{{metrics[0][1][0].total_user_locker}} 
					</span>
				</p>
			</div>
			<div class="card-footer">
				<img src="\assets\images\HomeDepot\arrow.svg" style="height: 12px"/>
				<p style="color: #00c48c">
					+ {{this.percent_up[0].percent_up_total_unique_customers}}%
				</p>
			</div>
		</div>
  	</div>
	<div class="col-10 col-md-5 col-lg-4 col-xl-2 mb-3">
		<div class="card text-center" style="height: 350px">
			<div class="card-body">
				<div class="card-title">
					<img src="../../../../../assets/icons/info-solid.svg" matTooltip="{{'info.lockers.historical.total_locker_use' | translate }}" class="icon-info float-end"/>
				</div>
				<h5 class="card-title cards-vivipost fw-semibold">
					<p>
						<br/>
						{{ 'lockers.use_total' | translate | uppercase}}
					</p>
				</h5>
				<p class="card-text card-txt-vivipost fw-light text-muted">
					{{'general.orders' | translate}} <br/>
					<span class="big">
						{{metrics[0][1][0].total_locker_usage}} 
					</span>
				</p>
			</div>
			<div class="card-footer">
				<img src="\assets\images\HomeDepot\arrow.svg" style="height: 12px"/>
				<p style="color: #00c48c">
					+ {{this.percent_up[0].percent_up_total_locker_usage}}%
				</p>
			</div>
		</div>
  	</div>
	<div class="col-10 col-md-5 col-lg-4 col-xl-2 mb-3">
		<div class="card text-center" style="height: 350px">
			<div class="card-body">
				<div class="card-title">
					<img src="../../../../../assets/icons/info-solid.svg" matTooltip="{{'info.lockers.historical.total_locker_usage' | translate }}" class="icon-info float-end"/>
				</div>
				<h5 class="card-title cards-vivipost fw-semibold">
					<p>
						{{ 'general.total' | translate |uppercase}}<br>
						{{ 'lockers.total_expired_packages' | translate  | uppercase}}
					</p>
				</h5>
				<p class="card-text card-txt-vivipost fw-light text-muted">
					{{'general.orders' | translate}} <br/>
					<span class="big">
						{{metrics[0][1][0].total_expired_packages}}
					</span>
				</p>
			</div>
			<div class="card-footer">
				<img src="\assets\images\HomeDepot\arrow.svg" style="height: 12px"/>
				<p style="color: #00c48c">
					+ {{this.percent_up[0].percent_up_total_expired_packages}}%
				</p>
			</div>
		</div>
  	</div>
	<div class="col-10 col-md-5 col-lg-4 col-xl-2 mb-3">
		<div class="card text-center" style="height: 350px">
			<div class="card-body">
				<div class="card-title">
					<img src="../../../../../assets/icons/info-solid.svg" matTooltip="{{'info.lockers.historical.orders_delivered_today' | translate }}" class="icon-info float-end"/>
				</div>
				<h5 class="card-title cards-vivipost fw-semibold">
					<p>
						{{'general.orders' | translate | uppercase}}<br>
						{{ 'lockers.orders-delivered' | translate | uppercase}}<br>
						{{ 'general.by' | translate | uppercase}} {{ 'general.ikea' | translate | uppercase }}
					</p>
				</h5>
				<p class="card-text card-txt-vivipost fw-light text-muted">
					{{'general.orders' | translate}} <br/>
					<span class="big">
						{{metrics[0][1][0].total_delivery_today}} 
					</span>
				</p>
			</div>
			<div class="card-footer">
				<img src="\assets\images\HomeDepot\arrow.svg" style="height: 12px"/>
				<p style="color: #00c48c">
					+ {{this.percent_up[0].percent_up_orders_deliveres}}%
				</p>
			</div>
		</div>
  	</div>
	<div class="col-10 col-md-5 col-lg-4 col-xl-2 mb-3">
		<div class="card text-center" style="height: 350px">
			<div class="card-body">
				<div class="card-title">
					<img src="../../../../../assets/icons/info-solid.svg" matTooltip="{{'info.lockers.historical.refunded_today' | translate }}" class="icon-info float-end"/>
				</div>
				<h5 class="card-title cards-vivipost fw-semibold">
					<p>
						{{ 'general.total' | translate | uppercase}}<br>
						{{ 'lockers.total_expired_packages' | translate | uppercase}}<br>
						{{'lockers.picked-up' | translate | uppercase}}
					</p>
				</h5>
				<p class="card-text card-txt-vivipost fw-light text-muted">
					{{'general.orders' | translate}} <br/>
					<span class="big">
						{{metrics[0][1][0].total_refunded_today}} 
					</span>
				</p>
			</div>
		</div>
  	</div>

	<div class="col-10 col-md-5 col-lg-4 col-xl-2 mb-3">
		<div class="card text-center" style="height: 350px">
			<div class="card-body">
				<div class="card-title">
					<img src="../../../../../assets/icons/info-solid.svg" matTooltip="{{'info.lockers.historical.refunded_today' | translate }}" class="icon-info float-end"/>
				</div>
				<h5 class="card-title cards-vivipost fw-semibold">
					<p>
						{{ 'lockers.collection_success' | translate | uppercase }}
					</p>
				</h5>
				<div id="chart-locker-client">
					<apx-chart [series]="series" [chart]="chart" [plotOptions]="plotOptions" [stroke]="stroke" [fill]="fill"></apx-chart>
				</div>
				<p class="card-text card-txt-vivipost fw-light text-muted">
					{{'deliveries.orders' | translate}}
				</p>
			</div>
		</div>
  	</div>
</div>