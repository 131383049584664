import { Platform } from '@angular/cdk/platform';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CellClickedEvent, ColDef, GridOptions } from 'ag-grid-community';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import * as XLSX from 'xlsx';

@Component({
	selector: 'app-return-clients',
	templateUrl: './return-clients.component.html',
	styleUrls: ['./return-clients.component.scss'],
})
export class ReturnClientsComponent implements OnInit {
	rangeDates: Date[];
	InitialDate = new Date();
	FinalDate = new Date();
	public defaultColDef: ColDef = {
		flex: 1,
		minWidth: 70,
		resizable: false,
		sortable: true,
		filter: true,
	};

	tabOrders = true;
	tabLotes = false;
	tabClientes = false;

	filterText: string = '';
	gridOptions: GridOptions;
	rowData: any[];
	dataTemporal = [];
	public filteredData: any[] = [];

	constructor(
		private translate: TranslateService,
		private http: HttpClient,
		private platform: Platform,
		private router: Router,
		private adminService: AdminClientService,
		private dialog: MatDialog
	) {
	}

	ngOnInit() {
		// 	this.gridOptions = {
		// 		columnDefs : [
		// 			{
		// 				headerName: this.translate.instant('table-client.email'),
		// 				field:'email',
		// 				width:406,
		// 				sortable:true,
		// 				cellStyle:{
		// 					'text-align': 'center'
		// 				},
		// 				cellRenderer: this.centeredCellRenderer
		// 			},
		// 			{
		// 				headerName: this.translate.instant('table-client.phone'),
		// 				field:'main_tlf',
		// 				sortable:true,
		// 				cellStyle:{
		// 					'text-align': 'center'
		// 				},
		// 				cellRenderer: this.centeredCellRenderer
		// 			},
		// 			{
		// 				headerName: this.translate.instant('table-client.number_visits'),
		// 				field:'count_return',
		// 				sortable:true,
		// 				cellStyle:{
		// 					'text-align': 'center'
		// 				},
		// 				cellRenderer: this.centeredCellRenderer
		// 			},
		// 			{
		// 				headerName: this.translate.instant('table-client.registration_date'),
		// 				field:'registration_date',
		// 				sortable:true,
		// 				cellStyle:{
		// 					'text-align': 'center'
		// 				},
		// 				cellRenderer: this.centeredCellRenderer,
		// 			}
		// 		],
		// 		animateRows:true,
		//   defaultColDef: {
		//     flex: 1
		//   },
		//   pagination: true,
		//   paginationPageSize: 10,
		//   paginationAutoPageSize: true
		// 	} as MyGridOptions;
		// 	this.queryConsumer();
	}

	addEventRangeDates() {
		this.InitialDate = this.rangeDates[0];
		if (this.rangeDates[1] !== null) {
			this.FinalDate = this.rangeDates[1];
		}
	}

	onCellClicked(e: CellClickedEvent): void {}

	onFilterTextChange(event) {
		this.filterText = event.target.value;
		this.gridOptions.api.setQuickFilter(this.filterText);
		let api = this.gridOptions.api;
		let temporal = [];
		api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}
	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}
	async queryConsumer() {
		let body = {
			language: localStorage.getItem('language'),
		};
		await this.adminService.queryConsumerCompany(body).then((res) => {
			res.result_return.map((e) => {
				this.dataTemporal.push({
					email: e.email,
					main_tlf: e.main_tlf,
					count_return: e.count_return,
					registration_date: e.registration_date,
				});
			});
			this.dataTemporal = [...this.dataTemporal];
		});
		this.rowData = this.dataTemporal;
	}
	onExport() {
		const fileName = 'clientes.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'Clientes',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		// Obtener los datos de la tabla
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				return row;
			});
			// Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		} else if (this.rowData.length > 0 && this.filteredData.length === 0) {
			const Data = this.rowData || [];
			const excelData = Data.map((row) => {
				return row;
			});
			//   // Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		}
	}
}

