import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogRecordatoriosComponent } from 'src/app/modules/admin-client/components/dialog-recordatorios/dialog-recordatorios.component';

@Component({
	selector: 'app-dialog-see-json-webhook',
	templateUrl: './dialog-see-json-webhook.component.html',
	styleUrls: ['./dialog-see-json-webhook.component.scss']
})
export class DialogSeeJsonWebhookComponent implements OnInit {
	url:string;
	event_sent:string;
	registration_date:string;
	service:string;


	constructor(
		private dialogRef: MatDialogRef<DialogRecordatoriosComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
	) { }

	ngOnInit(): void {
		this.url = this.data.data.data.url;
		this.event_sent = JSON.parse(this.data.data.data.event_sent);
		this.registration_date = this.data.data.data.registration_date;
		this.service = this.data.data.data.service
	}

	closeModal(){
		this.dialogRef.close();
	}

}
