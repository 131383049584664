import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { FilterService } from 'src/app/core/http/FilterDataServiceOxxoToOxxo.service';

@Component({
	selector: 'app-filter-oxxo-to-oxxo',
	templateUrl: './filter-oxxo-to-oxxo.component.html',
	styleUrls: ['./filter-oxxo-to-oxxo.component.scss']
})
export class FilterOxxoToOxxoComponent implements OnInit {
	@Input() filters:boolean=false;
	checkboxEnLockerDestino			: boolean = false;
	checkboxEnLockerOrigen			: boolean = false;
	checkboxEnTransitoCEDISOrigen	: boolean = false;
	checkboxCEDISOrigen				: boolean = false;
	checkboxEnRevision				: boolean = false;
	checkboxDetenido				: boolean = false;
	checkboxRevisado				: boolean = false;
	checkboxEnTransitoCEDISDestino	: boolean = false;
	checkboxCEDISDestino			: boolean = false;
	checkboxEnTransitoLockerDestino	: boolean = false;
	checkboxExpirado 				: boolean = false;
	checkboxPagoPendiente			: boolean = false;
	checkboxPagoExpirado			: boolean = false;
	checkboxNoSeEntrego				: boolean = false;
	checkboxAgendado				: boolean = false;
	checkboxRecolectadoPorCarrier	: boolean = false;
	checkboxRecolectadoDestinatario	: boolean = false;
	checkboxProcesoDevolucion		: boolean = false;
	checkboxRecRemitente			: boolean = false;
	checkboxProcesoLegal			: boolean = false;
	checkboxOne						: boolean = false;
	checkboxTwo						: boolean = false;
	checkboxThree 					: boolean = false;
	checkboxFour					: boolean = false;
	checkboxFive					: boolean = false;
	checkboxBasico					: boolean = false;
	checkboxExtendido				: boolean = false;
	arrayFilterEstatus: Set<number> = new Set<number>();
	arrayFilterStars: Set<number> = new Set<number>();
	arrayFilterCoverage: Set<string> = new Set<string>();
	constructor(private filterService: FilterService) { }
	ngOnInit(): void {
	}

	actualizarValorEnArreglo(valor: number, isChecked: boolean) {
		if (isChecked) {
			this.arrayFilterEstatus.add(valor);
		} else {
			this.arrayFilterEstatus.delete(valor);
		}
		this.filterService.updateEstatusFilter(this.arrayFilterEstatus);
	}

	actualizarStarsEnArreglo(valor: number, isChecked: boolean) {
		if (isChecked) {
			this.arrayFilterStars.add(valor);
		} else {
			this.arrayFilterStars.delete(valor);
		}
		this.filterService.updateStarsFilter(this.arrayFilterStars);
	}

	actualizarCoverageEnArreglo(valor: string, isChecked: boolean) {
		if (isChecked) {
			this.arrayFilterCoverage.add(valor);
		} else {
			this.arrayFilterCoverage.delete(valor);
		}
		this.filterService.updateCoverageFilter(this.arrayFilterCoverage);
	}

	validarCoverage(){
		this.actualizarCoverageEnArreglo('BÁSICO', this.checkboxBasico);
		this.actualizarCoverageEnArreglo("EXTENDIDO", this.checkboxExtendido);
	}
	validarStars(){
		this.actualizarStarsEnArreglo(1, this.checkboxOne);
		this.actualizarStarsEnArreglo(2, this.checkboxTwo);
		this.actualizarStarsEnArreglo(3, this.checkboxThree);
		this.actualizarStarsEnArreglo(4, this.checkboxFour);
		this.actualizarStarsEnArreglo(5, this.checkboxFive);
	}


	validarCasillasSeleccionadas() {
		this.actualizarValorEnArreglo(47, this.checkboxEnLockerOrigen);
		this.actualizarValorEnArreglo(57, this.checkboxEnLockerDestino);
		this.actualizarValorEnArreglo(49, this.checkboxEnTransitoCEDISOrigen);
		this.actualizarValorEnArreglo(50, this.checkboxCEDISOrigen);
		this.actualizarValorEnArreglo(54, this.checkboxEnRevision);
		this.actualizarValorEnArreglo(56, this.checkboxDetenido);
		this.actualizarValorEnArreglo(55, this.checkboxRevisado);
		this.actualizarValorEnArreglo(51, this.checkboxEnTransitoCEDISDestino);
		this.actualizarValorEnArreglo(52, this.checkboxCEDISDestino);
		this.actualizarValorEnArreglo(53, this.checkboxEnTransitoLockerDestino);
		this.actualizarValorEnArreglo(59, this.checkboxExpirado);
		this.actualizarValorEnArreglo(39, this.checkboxPagoPendiente);
		this.actualizarValorEnArreglo(45, this.checkboxPagoExpirado);
		this.actualizarValorEnArreglo(46, this.checkboxNoSeEntrego);
		this.actualizarValorEnArreglo(35, this.checkboxAgendado);
		this.actualizarValorEnArreglo(60, this.checkboxRecolectadoPorCarrier);
		this.actualizarValorEnArreglo(61, this.checkboxRecolectadoDestinatario);
		this.actualizarValorEnArreglo(62, this.checkboxProcesoDevolucion);
		this.actualizarValorEnArreglo(63, this.checkboxRecRemitente);
		this.actualizarValorEnArreglo(64, this.checkboxProcesoLegal);
	}


	openStatus:boolean=false;
	openStatusDiv(){
		this.openStatus = !this.openStatus;
		this.openInLocker = false;
		this.openInProcess = false;
		this.openExpired = false;
		this.openScheduled = false;
		this.openReturns = false;
		this.openRate = false;
		this.openCoverage = false;
	}
	openInLocker:boolean=false;
	openStatusInLocker(){
		this.openInLocker = !this.openInLocker;
		this.openInProcess = false;
		this.openExpired = false;
		this.openScheduled = false;
		this.openReturns = false;
	}

	openInProcess:boolean=false;
	openStatusInProcess(){
		this.openInLocker = false;
		this.openInProcess = !this.openInProcess;
		this.openExpired = false;
		this.openScheduled = false;
		this.openReturns = false;
	}

	openExpired:boolean=false;
	openStatusExpired(){
		this.openInLocker = false;
		this.openInProcess = false;
		this.openExpired = !this.openExpired;
		this.openScheduled = false;
		this.openReturns = false;
	}

	openScheduled:boolean=false;
	openStatusScheduled(){
		this.openInLocker = false;
		this.openInProcess = false;
		this.openExpired = false;
		this.openScheduled = !this.openScheduled;
		this.openReturns = false;
	}

	openReturns:boolean=false;
	openStatusReturns(){
		this.openInLocker = false;
		this.openInProcess = false;
		this.openExpired = false;
		this.openScheduled = false;
		this.openReturns = !this.openReturns;
	}

	openRate:boolean=false;
	openRateDiv(){
		this.openStatus = false;
		this.openInProcess = false;
		this.openExpired = false;
		this.openScheduled = false;
		this.openReturns = false;
		this.openRate = !this.openRate;
		this.openCoverage = false;
	}

	openCoverage:boolean=false;
	openCoverageDiv(){
		this.openStatus = false;
		this.openInProcess = false;
		this.openExpired = false;
		this.openScheduled = false;
		this.openReturns = false;
		this.openRate = false;
		this.openCoverage = !this.openCoverage;
	}
}
