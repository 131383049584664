import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { TableUserService } from 'src/app/core/http/user.service';
import { LocalAdminInterface } from 'src/app/core/models/users.interfaces';
import * as XLSX from 'xlsx';
import { DateTextComponent } from '../date-text/date-text.component';
import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-table-local-admins',
	templateUrl: './table-local-admins.component.html',
	styleUrls: ['./table-local-admins.component.scss'],
})
export class TableLocalAdminsComponent implements OnInit {
	valueInput;
	filterText: string = '';
	gridOptions: GridOptions;
	public filteredData: any[] = [];
	localadmin: LocalAdminInterface[];
	public columnDefs: ColDef[];

	ngOnInit(): void {
		this.updateDataUser();

		this.loadData();
	}
	constructor(
		private translate: TranslateService,
		private userService: TableUserService
	) {
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
			localStorage.setItem('language', event.lang);
			this.translate
				.get([
					'table-localAdmins.fullName',
					'table-localAdmins.email',
					'table-localAdmins.masterCode',
					'table-localAdmins.activeDays',
					'table-localAdmins.expiredDate',
				])
				.subscribe((t: any) => {
					this.columnDefs = [
						{
							headerName: t['table-localAdmins.fullName'],
							field: 'first_name',
							sortable: true,
							autoHeight: true,
							headerClass:'borderHeaderLocalAdmin',
							cellClass:'localAdminCenter',
							cellRenderer: this.centeredCellRenderer,
						},
						{
							headerName: t['table-localAdmins.email'],
							field: 'email',
							sortable: true,
							autoHeight: true,
							headerClass:'borderHeaderLocalAdmin',
							cellClass:'localAdminCenter',
							cellRenderer: this.centeredCellRenderer,
						},
						{
							headerName: t['table-localAdmins.masterCode'],
							field: 'code_master',
							sortable: true,
							autoHeight: true,
							headerClass:'borderHeaderLocalAdmin',
							cellClass:'localAdminCenter',
							cellRenderer: this.centeredCellRenderer,
						},
						{
							headerName: t['table-localAdmins.activeDays'],
							field: 'active_days',
							sortable: true,
							autoHeight: true,
							headerClass:'borderHeaderLocalAdmin',
							cellClass:'localAdminCenter',
							cellRenderer: this.centeredCellRenderer,
						},
						{
							headerName: t['table-localAdmins.expiredDate'],
							field: 'registration_date',
							sortable: true,
							autoHeight: true,
							headerClass:'borderHeaderLocalAdmin',
							cellClass:'localAdminCenter',
							cellRenderer: DateTextComponent
						},
					];
				});
			this.updateDataUser();
		});
	}

	onGridReady(params: any) {
		const { api } = params;
	}
	updateDataUser(): void {
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant('table-localAdmins.fullName'),
					field: 'first_name',
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderLocalAdmin',
					cellClass:'localAdminCenter',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-localAdmins.email'),
					field: 'email',
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderLocalAdmin',
					cellClass:'localAdminCenter',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-localAdmins.masterCode'),
					field: 'code_master',
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderLocalAdmin',
					cellClass:'localAdminCenter',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-localAdmins.activeDays'),
					field: 'active_days',
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderLocalAdmin',
					cellClass:'localAdminCenter',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-localAdmins.expiredDate'),
					field: 'registration_date',
					sortable: true,
					autoHeight: true,
					headerClass:'borderHeaderLocalAdmin',
					cellClass:'localAdminCenter',
					cellRenderer: DateTextComponent
				},

			],
			animateRows: true,
			pagination: true,
			paginationPageSize: 10,
			defaultColDef: {
				flex: 1,
			},
			paginationAutoPageSize: true,
			rowClass:'rowLocalAdminCenter',
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`
		}
	}

	loadData() {
		this.localadmin = [];
		let data = {
			language: localStorage.getItem('language').toLowerCase(),
		};
		this.userService.getLocalAdmin(data).subscribe((res: any) => {
			res.result_return.map((item: LocalAdminInterface) => {
				let hourRegistrationDate = item.registration_date.substring(0,2);
				let minutesRegistrationDate = item.registration_date.substring(3,5);
				let secondsRegistrationDate = item.registration_date.substring(6,8);
				let monthRegistrationDate = parseInt(item.registration_date.substring(9,11));
				let dayRegistrationDate = item.registration_date.substring(12,14);
				let yearRegistrationDate = item.registration_date.substring(15,19);
				let registrationDateString = yearRegistrationDate +'-'+monthRegistrationDate+'-'+dayRegistrationDate+' '+hourRegistrationDate+':'+minutesRegistrationDate+':'+secondsRegistrationDate;

				this.localadmin.push({
					id_user: item.id_user,
					code_master: item.code_master,
					email: item.email,
					surname: item.surname,
					first_name: item.first_name,
					alias_username: item.alias_username,
					id_status: item.id_status,
					id_language: item.id_language,
					courier_name: item.courier_name,
					language_name: item.language_name,
					acronym_language: item.acronym_language,
					registration_date: registrationDateString,
					name_status: item.name_status,
					active_days: item.active_days,
				});
			});
			this.localadmin = [...this.localadmin];
		});
	}

	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}

	onFilterTextChange(event) {
		this.filterText = event.target.value;
		this.gridOptions.api.setQuickFilter(this.filterText);
		let api = this.gridOptions.api;
		let temporal = [];
		api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}

	onExport() {
		const fileName = 'LocalAdmin-Users.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'LocalAdmin-Users',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		// Obtener los datos de la tabla
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				const { id_status,id_language,id_user,	...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			// Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		} else if (
			this.localadmin.length > 0 &&
			this.filteredData.length === 0
		) {
			const Data = this.localadmin || [];
			const excelData = Data.map((row) => {
				const { id_status,id_language,id_user,	...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			//   // Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		}
	}
}
