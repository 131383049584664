<!-- GRID HEADER -->
<div class="grid-header">
  <!-- TITLE CONTAINER -->
  <div class="span-txt">
    <!-- TITLE -->
    <span class="txt-title">
      Histórico de paquetes
    </span>
    <!-- ICON INFO -->
    <div class="icon-info">
      <!-- TOOLTIP -->
      <app-tooltip 
          class="tooltip-span" 
          [tooltip]="tooltipHistorical">
      </app-tooltip>
      
    </div>

  </div>
    <!-- BTN EXPORTAR -->
    <dropdown-export></dropdown-export>
</div>
  

<!-- CHART -->
<div class="chart-container" id="chart">
    <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [xaxis]="chartOptions.xaxis"
        [stroke]="chartOptions.stroke"
        [colors]="chartOptions.colors"
        [dataLabels]="chartOptions.dataLabels"
        [legend]="chartOptions.legend"
        [markers]="chartOptions.markers"
        [grid]="chartOptions.grid"
        [yaxis]="chartOptions.yaxis"
    ></apx-chart>
</div>
