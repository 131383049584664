import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class MenuDropdownService {
	status: boolean = false;

	constructor() {}
}
