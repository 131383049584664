<div class="content-body" fxLayout="column" fxLayoutGap="36px">
  <div class="content-cards">
	  <!-- *Grafica 1 -->
	  <mat-card>
	    <mat-card-header>
	      <mat-card-title class="title-card">{{'general.title_version' | translate}}</mat-card-title>
	    </mat-card-header>
	    <mat-card-content>	    	
	      <mat-accordion *ngIf="version.length > 0">
	      	<div  *ngFor="let v of version; let i = index;">
	      	<mat-expansion-panel [expanded]="(i==0) ? true : false" (opened)="panelOpenState = (i==0) ? false : true"
				                       (closed)="panelOpenState = (i==0) ? true : false">

	      		<mat-expansion-panel-header>
				      <mat-panel-title>{{ v.version }}</mat-panel-title>
				      <mat-panel-description>{{ v.version_date | date:'mediumDate' }}</mat-panel-description>
				    </mat-expansion-panel-header>
				    <div *ngIf="leng==='ES'">
				    	<ol><li *ngFor="let register of v.register.ES"> {{ register }} </li></ol>
				    </div>
				    <div *ngIf="leng==='EN'">
				    	<ol><li class="nav-item" *ngFor="let register of v.register.EN"> {{ register }} </li></ol>
				    </div>
	      	</mat-expansion-panel>
	      	<div style="margin-top: 25px;"></div>
	      	</div>
				</mat-accordion>
	    </mat-card-content>	    
	  </mat-card>
	</div>
</div>