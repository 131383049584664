import { Component, Input } from '@angular/core';
import { AdminClientService } from '../../../../core/http/admin-client.service';

@Component({
	selector: 'app-packages-return-admin',
	templateUrl: './packages-return-admin.component.html',
	styleUrls: ['./packages-return-admin.component.scss'],
})
export class PackagesReturnAdminComponent {
	@Input() dataForMonthly!: any[];
	datar;
	metrics: any;
	cantReturn;

	ngOnInit(): void {
		this.getMetrics();
	}

	constructor(private adminService: AdminClientService) {}

	async getMetrics() {
		this.metrics = await this.adminService.getMetrics(this.dataForMonthly);
		this.cantReturn = [];
		this.metrics[4][1].forEach((e: any) => {
			this.cantReturn.push(e.cant);
		});
		this.datar = JSON.stringify(this.cantReturn);
	}
}
