import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class TableUserService {
	public baseUrl: string = environment.url_server_backend;
	private token: string;

	setToken(token: string) {
		this.token = token;
	}

	getToken() {
		return this.token ? this.token : localStorage.getItem('token');
	}

	constructor(private http: HttpClient) {}

	getCarriers(params: any) {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params: params,
		};
		let url = this.baseUrl + '/server/user/carrier';
		return this.http.get(url, options);
	}

	getSuperAdmin(params: any) {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params: params,
		};
		let url = this.baseUrl + '/server/user/super-admin-client';
		return this.http.get(url, options);
	}

	async getSuperAdminAsincrono(params:any){
		let options ={
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params: params,
		}
		let url = this.baseUrl + '/server/user/super-admin-client';
		return await this.http.get(url, options).toPromise();
	}

	async getAdminAsincrono(params:any){
		let options ={
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params: params,
		}
		let url = this.baseUrl + '/server/user/admin';
		return await this.http.get(url, options).toPromise();
	}

	async getEYRAsincrono(params:any){
		let options ={
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params: params,
		}
		let url = this.baseUrl + '/server/user/e&r';
		return await this.http.get(url, options).toPromise();
	}

	async getCCCAsincrono(params:any){
		let options ={
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params: params,
		}
		let url = this.baseUrl + '/server/user/CCC';
		return await this.http.get(url, options).toPromise();
	}

	getCarrierAdmin(params: any) {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params: params,
		};
		let url = this.baseUrl + '/server/user/carrier-admin';
		return this.http.get(url, options);
	}

	async getCarrierAdminAsincrono(params:any){
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params: params,
		};
		let url = this.baseUrl + '/server/user/carrier-admin';
		return await this.http.get(url,options).toPromise();
	}


	getLocalAdmin(params: any) {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params: params,
		};
		let url = this.baseUrl + '/server/user/local-admin';
		return this.http.get(url, options);
	}
	getSupervisor(params: any) {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params: params,
		};
		let url = this.baseUrl + '/server/user/supervisor';
		return this.http.get(url, options);
	}
	async getUser(params) {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params: params,
		};
		return await this.http
			.get(this.baseUrl + '/server/company/query-user', options)
			.toPromise();
	}
	getMetrics(params: any) {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params: params,
		};
		let url = this.baseUrl + '/server/metric/query-metric-user';
		return this.http.get(url, options);
	}
	getMetricsUser(params: any) {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params: params,
		};
		let url = this.baseUrl + '/server/metric/query-metric-user';
		return this.http.get(url, options);
	}

	getMetricsOrders(params: any) {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params: params,
		};
		let url = this.baseUrl + '/server/metric/query-metric-order';
		return this.http.get(url, options);
	}
	getMetricsCarriers(params: any) {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params: params,
		};
		let url = this.baseUrl + '/server/metric/query-metric-carrier';
		return this.http.get(url, options);
	}
}
