import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { CardData } from 'src/app/core/models';

@Component({
	selector: 'card',
	templateUrl: './card-drag-drop.component.html',
	styleUrls: ['./card-drag-drop.component.scss'],
})
export class CardDragDropComponent implements OnInit {
	// CARD STYLE
	/*
    null  : display none
    sm    : small size
    md    : medium size
  */
	@Input() cardSize: string;
	@Input() colorBg: string = '--color-container';
	// DATA CARD
	@Input() dataCard: CardData[] = [];
	@Input() footer: boolean;
	@Input() header: boolean;
	@Input() body: boolean;
	@Input() cardsCol: number;
	@Input() cardsRow: number;
	@Input() MaxCol: number;
	// LocalStorage set IDS
	private storeCardsIds: string | null = null;

	constructor(
		private renderer: Renderer2,
		private elementRef: ElementRef){}

	ngOnInit(): void {
		this.cardsCol = Math.min(this.dataCard.length, this.MaxCol);
		this.cardsRow = Math.ceil(this.cardsCol / this.MaxCol);

		// Obtener el orden almacenado en el localStorage
		const storeCardsIds = localStorage.getItem('cardsIds');

		if (storeCardsIds) {
			const cardsIds = JSON.parse(storeCardsIds);

			// Crear un nuevo arreglo de dataCard con el orden almacenado
			this.dataCard = cardsIds.map((cadsId: string) => {
				// Buscar el objeto correspondiente en el arreglo original
				const card = this.dataCard.find((h) => h.id === cadsId);

				if (card) {
					// Clonar el objeto y establecer el orden almacenado
					return { ...card, isHovered: false };
				} else {
					// Si no se encuentra el objeto en el arreglo original, crear uno nuevo
					return { id: cadsId, isHovered: false };
				}
			});
		}
	}

	ngOnDestroy(): void {
		if (this.storeCardsIds) {
			const cardsIds = this.dataCard.map((card) => card.id);
			localStorage.setItem('cardsIds', JSON.stringify(cardsIds));
		}
	}

	// DRAG AND DROP ---------------------------------------------------------------------------->
	// Variables for tracking dragging and hover state

	// Index of the card being dragged
	public draggeIndex: number;

	// Indicates whether the dragged card is being hovered
	public isHover: boolean = false;

	// Method invoked when dragging of a card starts
	public onDragStart(event: DragEvent, index: number): void {
		event.dataTransfer?.setData('text/plain', index.toString());
		this.draggeIndex = index;
		// WHEN YOU START TO DRAG
		const draggedElement = event.currentTarget as HTMLElement;
		draggedElement.classList.add('dragged-item');
	}

	// Method invoked when a card is dragged over the target area
	public onDragOver(event: DragEvent): void {
		event.preventDefault();
		this.isHover = true;
	}

	// Method invoked when leaving the target area while dragging a card
	public onDragLeave(event: DragEvent): void {
		event.preventDefault();
		this.isHover = false;
		this.dataCard.forEach((itenm) => (itenm.isHovered = false));
	}

	// Method invoked when a card is dropped on the target area
	public onDrop(event: DragEvent, targetIndex: number): void {
		event.preventDefault();
		const sourceIndex = Number(event.dataTransfer?.getData('text/plain'));
		this.swapCards(sourceIndex, targetIndex);
		this.draggeIndex = -1;
		this.dataCard.forEach((item) => (item.isHovered = false));
	}

	// Swaps the positions of two cards in the data array
	public swapCards(sourceIndex: number, targetIndex: number): void {
		const temp = this.dataCard[sourceIndex];
		this.dataCard[sourceIndex] = this.dataCard[targetIndex];
		this.dataCard[targetIndex] = temp;
		this.isHover = false;

		// Update the order in the Local Storage
		const cardsIds = this.dataCard.map((header) => header.id);
		localStorage.setItem('cardsIds', JSON.stringify(cardsIds));
	}

	public onDragEnd(event: DragEvent): void {
		const draggedElement = event.currentTarget as HTMLElement;
		draggedElement.classList.remove('dragged-item');
	}
}
