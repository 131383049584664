import { Platform } from '@angular/cdk/platform';
import {
	Component,
	Input,
	Output,
	OnInit,
	SimpleChanges,
	ViewChild,
	EventEmitter,
	ElementRef,
	ChangeDetectorRef,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import JSMpeg from 'jsmpeg-player';
import { AdminClientService } from '../../../../core/http/admin-client.service';

@Component({
	selector: 'app-cam-table-admin',
	templateUrl: './cam-table-admin.component.html',
	styleUrls: ['./cam-table-admin.component.scss'],
})
export class CamTableAdminComponent implements OnInit {
	date: Date[]; //! DATE TIME PROPERTY

	@ViewChild('canvas', { static: false })
	public canvas: ElementRef<HTMLCanvasElement>;
	@Input() dataTable;
	@Input() id_locker;
	@Input() dataColum;
	@Output() emisor = new EventEmitter<string>();
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	canvasEl: HTMLCanvasElement;
	private cx: CanvasRenderingContext2D;

	urlWS: string | any = 'wss://localhost:';
	urlVireVideo: string = 'ws://server-dev.vivipost.com:'; // [server-dev.vivipost.com:, localhost:]
	player;
	camera: boolean = false;
	cameraName: string = '';
	urlSafe: SafeResourceUrl;
	device = false;
	safari = false;
	viewCAM: boolean = false;

	time_start: Date;
	time_end: Date;
	date_stamp: Date;
	dtime_start: Date;
	dtime_end: Date;
	ddate_stamp: Date;
	stime_start: Date;
	stime_end: Date;
	sdate_stamp: Date;

	tempData = [];
	dataSource;
	displayedColumns;
	url_cam_event = '';
	spinner: boolean = false;

	dataTableEvent: any = [];
	data_event: any = [];
	dataTableAuxEvent: any;
	dataColumnEvent: any;
	singleCam;
	id_cam: number;

	// formSearchClips:any;
	formSearchClips = new FormGroup({
		ddate: new FormControl(),
		dsdate: new FormControl(),
		dfdate: new FormControl(),
	});

	constructor(
		private adminService: AdminClientService,
		private platform: Platform,
		public sanitizer: DomSanitizer
	) {
		if (platform.SAFARI) {
			this.safari = true;
		} else this.safari = false;
		if (this.platform.ANDROID || this.platform.IOS) {
			this.device = true;
		} else this.device = false;
	}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges) {
		if (this.dataTable) {
			this.tempData = JSON.parse(this.dataTable);
		}

		if (this.dataTable) {
			this.dataTable = JSON.parse(this.dataTable);
		}
		this.displayedColumns = JSON.parse(this.dataColum);
		this.dataTable
			? (this.dataSource = new MatTableDataSource(this.dataTable))
			: null;
		this.dataTable ? (this.dataSource.paginator = this.paginator) : null;
	}

	activarCam() {
		this.camera = true;
	}

	viewCam(element: any) {
		this.url_cam_event = '';
		this.urlSafe = '';
		this.id_cam = Number(element.id);
		this.cameraName = element.monitor_name;
		this.queryVideoCam(element.id);
	}

	async disconectVideoCam(callback: any) {
		const data = {
			language: localStorage.getItem('language'),
		};
		let res: any = await this.adminService.disconectVideoCam(
			AdminClientService
		);
		if (res.mensaje_return)
			if (!res.mensaje_return.ERROR) {
				this.player.stop();
				this.urlWS = '';
				this.player.source.url = this.urlWS;
				callback(true);
			}
	}

	hiddenCam() {
		this.camera = !this.camera;
		this.disconectVideoCam((status: boolean) => {});
		this.player.destroy();
		this.data_event = [];
	}

	async queryVideoCam(id_cam: number) {
		if (this.camera) {
			this.disconectVideoCam((status: boolean) => {
				if (status) {
					setTimeout(() => {
						this.camera = false;
						this.urlWS = '';
						this.queryVideoCam(id_cam);
					}, 2000);
				}
			});
		} else {
			this.camera = true;
			const data = {
				language: localStorage.getItem('language'),
				id_locker: Number(localStorage.getItem('id_locker')),
				id_cam: id_cam,
			};
			let res: any = await this.adminService.queryVideoCam(
				AdminClientService
			);
			if (res.mensaje_return)
				if (!res.mensaje_return.ERROR) {
					this.canvasEl = this.canvas.nativeElement;
					this.urlWS = this.urlVireVideo + res.wsPort;
					this.player = new JSMpeg.Player(this.urlWS, {
						canvas: this.canvasEl,
					});
				}
		}
	}

	onclickEvent() {
		this.url_cam_event = this.adminService.getdataCameraEvnet();
		this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
			this.url_cam_event
		);
	}

	async record() {
		if (this.formSearchClips.valid) {
			//Logic for valid form
		} else {
			Object.keys(this.formSearchClips.controls).forEach((field) => {
				const control = this.formSearchClips.get(field);
				control.markAsTouched({ onlySelf: true });
			});
		}

		this.spinner = true;
		if (!this.safari && !this.device) {
			let temp1 = this.date_stamp
				.toString()
				.replace(
					'00:00:00',
					`${this.time_start.getHours()}:${this.time_start.getMinutes()}:${this.time_start.getSeconds()}`
				);
			let temp2 = this.date_stamp
				.toString()
				.replace(
					'00:00:00',
					`${this.time_end.getHours()}:${this.time_end.getMinutes()}:${this.time_end.getSeconds()}`
				);
			let date1 = new Date(temp1).getTime();
			let date2 = new Date(temp2).getTime();
			let params = {
				language: localStorage.getItem('language').toLowerCase(),
				id_locker: this.id_locker,
				date_start: date1,
				date_end: date2,
				cam: this.id_cam,
			};
			this.data_event = await this.adminService.camEvent(
				AdminClientService
			);
		} else if (!this.safari && this.device) {
			let temp1 = this.ddate_stamp
				.toString()
				.replace(
					'00:00:00',
					`${this.dtime_start.getHours()}:${this.time_start.getMinutes()}:${this.time_start.getSeconds()}`
				);
			let temp2 = this.ddate_stamp
				.toString()
				.replace(
					'00:00:00',
					`${this.dtime_end.getHours()}:${this.time_end.getMinutes()}:${this.time_end.getSeconds()}`
				);
			let date1 = new Date(temp1).getTime();
			let date2 = new Date(temp2).getTime();
			let params = {
				language: localStorage.getItem('language').toLowerCase(),
				id_locker: this.id_locker,
				date_start: date1,
				date_end: date2,
				cam: this.id_cam,
			};
			this.data_event = await this.adminService.camEvent(
				AdminClientService
			);
		} else if (this.safari) {
			let temp1 = this.sdate_stamp
				.toString()
				.replace(
					'00:00:00',
					`${this.stime_start.getHours()}:${this.time_start.getMinutes()}:${this.time_start.getSeconds()}`
				);
			let temp2 = this.sdate_stamp
				.toString()
				.replace(
					'00:00:00',
					`${this.stime_end.getHours()}:${this.time_end.getMinutes()}:${this.time_end.getSeconds()}`
				);
			let date1 = new Date(temp1).getTime();
			let date2 = new Date(temp2).getTime();
			let params = {
				language: localStorage.getItem('language').toLowerCase(),
				id_locker: this.id_locker,
				date_start: date1,
				date_end: date2,
				cam: this.id_cam,
			};
			this.data_event = await this.adminService.camEvent(
				AdminClientService
			);
		}

		if (!this.data_event.mensaje_return.ERROR) {
			if (this.data_event.result_event.length) {
				this.dataTableEvent = [];
				this.data_event.result_event.forEach((event) => {
					this.dataTableEvent.push({
						id_event: event.id,
						name: event.name,
						start_time: event.start_time,
						end_time: event.end_time,
						duration: event.length,
						see_event: event.url_view,
						id_locker: this.id_locker,
						id_cam: this.id_cam,
					});
				});
			} else {
				this.dataTableEvent = [];
				this.dataTableEvent.push({
					name_event: 'NO RECORDS',
				});
			}
		} else {
			this.dataTableEvent = [];
			this.dataTableEvent.push({
				name_event: 'NO RECORDS',
			});
		}

		this.spinner = false;
		this.dataTableAuxEvent = JSON.stringify(this.dataTableEvent);
		this.dataColumnEvent = JSON.stringify(
			Object.keys(this.dataTableEvent[0])
		);
		setTimeout(() => {
			window.scrollTo({
				left: 0,
				top: document.body.scrollHeight,
				behavior: 'smooth',
			});
		}, 1000);
	}

	ngOnDestroy() {
		if (this.camera) {
			this.disconectVideoCam((status: boolean) => {});
			this.player.destroy();
		}
	}
}
