import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions } from 'ag-grid-community';
import { WebhookService } from 'src/app/core/http/webhook.service';
import { AccessToApiResult, EventWebhook, QueryWebhook, ReturnResultWebHook } from 'src/app/core/models/webhook/webhook.interface';
import { DialogSeeJsonWebhookComponent } from '../dialogs/dialog-see-json-webhook/dialog-see-json-webhook.component';
import { Router } from '@angular/router';

@Component({
	selector: 'app-activity',
	templateUrl: './activity.component.html',
	styleUrls: ['./activity.component.scss']
})
export class ActivityComponent {
	apiResultWebhook:AccessToApiResult;
	eventsWebHook =[];
	rowData =[];
	columnDefs: ColDef[];
	gridOptions: GridOptions;
	idWebhook:number;
	@ViewChild(AgGridAngular) agGrid!: AgGridAngular;

	constructor(
		private translate: TranslateService,
		private dialog: MatDialog,
		private webhook: WebhookService,
		private router: Router
	){
	}

	ngOnInit(){
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.getApiAccessToWebHook();
		this.getTable();
		this.translate.onLangChange.subscribe((event:LangChangeEvent)=>{
			this.translate.use(event.lang);
			this.translate.get([
				'WebHook.table.url',
				'WebHook.table.eventType',
				'WebHook.table.dateEvent',
				'WebHook.table.json',
			]).subscribe((t:any)=>{
				this.columnDefs = [
					{
						headerName: t['WebHook.table.url'],
						field:'url',
						cellClass:'activityWebhookClass',
						headerClass:'borderHeaderWebhook',
						autoHeight: true,
						sortable: true
					},
					{
						headerName: t['WebHook.table.eventType'],
						field:'service',
						cellClass:'activityWebhookClass',
						headerClass:'borderHeaderWebhook',
						autoHeight: true,
						sortable: true
					},
					{
						headerName: t['WebHook.table.dateEvent'],
						field:'registration_date',
						cellClass:'activityWebhookClass',
						headerClass:'borderHeaderWebhook',
						autoHeight: true,
						sortable: true
					},
					{
						headerName: '',
						field:'verJson',
						cellClass:'activityWebhookClassLink',
						headerClass:'borderHeaderWebhook',
						autoHeight: true,
						sortable: true,
						onCellClicked: ($event) => this.openToSeeJson($event),
					}
				]
			})
		});

	}
	getApiAccessToWebHook(){
		let objectAccessToApi = {
			language:localStorage.getItem('language').toLowerCase()
		}
		this.webhook.getWebHook(objectAccessToApi).then((res:QueryWebhook)=>{
			res.return_data_webhooks.forEach(item=>{
				this.apiResultWebhook = item
			})
			this.idWebhook = this.apiResultWebhook.id_webhock
			this.getDataWebHook(this.apiResultWebhook.id_webhock);
		})
	}
	getDataWebHook(id_webhock:number){
		let objectToGetWebHookCode={
			language: localStorage.getItem('language').toLowerCase(),
			id_webhock:id_webhock,
			order_by:'DESC'
		}
		this.webhook.getQueryEventWebHook(objectToGetWebHookCode).then((res:EventWebhook)=>{
			res.return_data_webhooks.forEach(item=>{
				this.eventsWebHook.push(
					{
						event_sent: item.event_sent,
						registration_date: item.registration_date,
						service: item.service,
						url: item.url,
						verJson: localStorage.getItem('language') === 'es' ? 'Ver Json' : 'Look Json'
					}
				)
				this.eventsWebHook = [...this.eventsWebHook];
				this.rowData = this.eventsWebHook;
			})
		});


	}
	getTable(){
		this.gridOptions = {
			columnDefs :[
				{
					headerName: this.translate.instant('WebHook.table.url'),
					field:'url',
					cellClass:'activityWebhookClass',
					headerClass:'borderHeaderWebhook',
					autoHeight: true,
					sortable: true
				},
				{
					headerName: this.translate.instant('WebHook.table.eventType'),
					field:'service',
					cellClass:'activityWebhookClass',
					headerClass:'borderHeaderWebhook',
					autoHeight: true,
					sortable: true
				},
				{
					headerName: this.translate.instant('WebHook.table.dateEvent'),
					field:'registration_date',
					cellClass:'activityWebhookClass',
					headerClass:'borderHeaderWebhook',
					autoHeight: true,
					sortable: true
				},
				{
					headerName: '',
					field:'verJson',
					cellClass:'activityWebhookClassLink',
					headerClass:'borderHeaderWebhook',
					autoHeight: true,
					sortable: true,
					onCellClicked: ($event) => this.openToSeeJson($event)
				}
			],
			animateRows: true,
			headerHeight:59,
			rowHeight:59,
			pagination:true,
			paginationPageSize: 10,
			domLayout: 'autoHeight',
			rowClass:'rowWebhookClass',
			defaultColDef: {
				flex: 1,
			},
			overlayNoRowsTemplate:
				`<div>
					<div>
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
							<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
							<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
						</svg>
					</div>
					<div class="labelNoRegistros">
						Aun no hay registros, <br>
						intentalo mas tarde
					</div>
				</div>`,
		}
	}
	onCellClicked(e: CellClickedEvent): void {
	}
	async getTableCompleted() {
		this.agGrid.api.setRowData(this.eventsWebHook);
	}

	backPage(){
		let userTypeID = parseInt(localStorage.getItem('user_type'));
		if(userTypeID === 9 || userTypeID === 7 || userTypeID === 8){
			this.router.navigateByUrl("l-retail/config-webhook");
		}
		if(userTypeID === 10){
			this.router.navigateByUrl("carriers/config-webhook");
		}


	}

	openToSeeJson(event){
		const dialogRef = this.dialog.open(DialogSeeJsonWebhookComponent,{
			data:{
				data:event
			}
		});
	}
}
