import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { TableUserService } from 'src/app/core/http/user.service';
import { SupervisoresInterface } from 'src/app/core/models/users.interfaces';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { EstatusTableOrderAdminComponent } from '../../estatus-table-order-admin/estatus-table-order-admin.component';
import { DateTextComponent } from '../../date-text/date-text.component';
import { UserDetailModalComponent } from '../../user-detail-modal/user-detail-modal.component';

@Component({
  selector: 'app-table-user-supervisor-carrier',
  templateUrl: './table-user-supervisor-carrier.component.html',
  styleUrls: ['./table-user-supervisor-carrier.component.scss']
})
export class TableUserSupervisorCarrierComponent implements OnInit {
	valueInput;
	filterText: string = '';
	gridOptions: GridOptions;
	public filteredData: any[] = [];
	supervisors: SupervisoresInterface[];
	language: string;
	public columnDefs: ColDef[];
	carrierID:number;
	constructor(
		private translate: TranslateService,
		private userService: TableUserService,
		private dialog: MatDialog
	) {}

	ngOnInit() {
		this.carrierID = parseInt(localStorage.getItem('courier_id'));
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.updateDataUser();
		this.loadData();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.language = event.lang;
			this.translate.use(event.lang);
			this.translate.get([
					'table-supervisor.fullName',
					'table-supervisor.email',
					'table-supervisor.employeeId',
					'table-supervisor.activeDays',
					'table-supervisor.expiredDate',
					'table-supervisor.statusUser',
				])
				.subscribe((t: any) => {
					this.columnDefs = [
						{
							headerName: t['table-supervisor.fullName'],
							field: 'first_name',
							sortable: true,
							autoHeight: true,
							autoHeaderHeight:true,
							cellClass:'supervisorClassLink',
							headerClass:'borderHeaderSupervisor',
							cellRenderer: this.centeredCellRenderer,
							onCellClicked: ($event) => this.openModalUserDetail($event)
						},
						{
							headerName: t['table-supervisor.email'],
							field: 'email',
							sortable: true,
							autoHeight: true,
							autoHeaderHeight:true,
							cellClass:'supervisorClass',
							headerClass:'borderHeaderSupervisor',
							cellRenderer: this.centeredCellRenderer,
						},
						{
							headerName: t['table-supervisor.employeeId'],
							field: 'id_user',
							sortable: true,
							autoHeight: true,
							autoHeaderHeight:true,
							cellClass:'supervisorClass',
							headerClass:'borderHeaderSupervisor',
							cellRenderer: this.centeredCellRenderer,
						},
						{
							headerName: t['table-supervisor.activeDays'],
							field: 'active_days',
							sortable: true,
							autoHeight: true,
							autoHeaderHeight:true,
							cellClass:'supervisorClass',
							headerClass:'borderHeaderSupervisor',
							cellRenderer: this.centeredCellRenderer,
						},
						{
							headerName: t['table-supervisor.statusUser'],
							field: 'name_status',
							sortable: true,
							autoHeight: true,
							autoHeaderHeight:true,
							cellClass:'supervisorClass',
							headerClass:'borderHeaderSupervisor',
							cellRenderer: EstatusTableOrderAdminComponent,
						},
						{
							headerName: t['table-supervisor.expiredDate'],
							field: 'registration_date',
							width:438,
							sortable: true,
							autoHeight: true,
							autoHeaderHeight:true,
							cellClass:'supervisorClass',
							headerClass:'borderHeaderSupervisor',
							cellRenderer: DateTextComponent
						}
					];
				});
		});
	}

	loadData() {
		this.supervisors = [];
		let data = {
			language: localStorage.getItem('language').toLowerCase(),
			is_module_courier : true,
			id_courier: this.carrierID
		};
		this.userService.getSupervisor(data).subscribe((res: any) => {
			if (res.result_return) {
				res.result_return.map((item) => {
					let hourRegistrationDate = item.registration_date.substring(0,2);
					let minutesRegistrationDate = item.registration_date.substring(3,5);
					let secondsRegistrationDate = item.registration_date.substring(6,8);
					let monthRegistrationDate = parseInt(item.registration_date.substring(9,11));
					let dayRegistrationDate = item.registration_date.substring(12,14);
					let yearRegistrationDate = item.registration_date.substring(15,19);
					let registrationDateString = yearRegistrationDate +'-'+monthRegistrationDate+'-'+dayRegistrationDate+' '+hourRegistrationDate+':'+minutesRegistrationDate+':'+secondsRegistrationDate;
					this.supervisors.push({
						acronym_language: item.acronym_language,
						active_days: item.active_days,
						alias_username: item.alias_username,
						audit_package: item.audit_package,
						audit_employee: item.audit_employee,

						courier_name: item.courier_name,
						email: item.email,
						enable_token: item.enable_token,
						first_name: item.first_name,
						id_language: item.id_language,
						id_status: item.id_status,
						id_user: item.id_user,
						language_name: item.language_name,
						name_status: item.name_status,
						registration_date: registrationDateString,
						surname: item.surname,
						url_token: item.url_token,
					});
				});
				this.supervisors = [...this.supervisors];
			}
		});
	}
	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}
	onFilterTextChange(event) {
		this.filterText = event.target.value;
		this.gridOptions.api.setQuickFilter(this.filterText);
		let api = this.gridOptions.api;
		let temporal = [];
		api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}
	onExport() {
		const fileName = 'Users.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'Carriers-Supervisores',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		// Obtener los datos de la tabla
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				const { id_status,audit_package,audit_employee,url_token,id_language,id_user,	...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			// Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		} else if (
			this.supervisors.length > 0 &&
			this.filteredData.length === 0
		) {
			const Data = this.supervisors || [];
			const excelData = Data.map((row) => {
				const { id_status,audit_package,audit_employee,url_token,id_language,id_user,	...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			//   // Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		}
	}
	onGridReady(params: any) {
		const { api } = params;
	}
	updateDataUser(): void {
		this.translate.setDefaultLang(
			localStorage.getItem('language').toLowerCase()
		);
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant('table-supervisor.fullName'),
					field: 'first_name',
					sortable: true,
					autoHeight: true,
					autoHeaderHeight:true,
					cellClass:'supervisorClassLink',
					headerClass:'borderHeaderSupervisor',
					cellRenderer: this.centeredCellRenderer,
					onCellClicked: ($event) => this.openModalUserDetail($event)
				},
				{
					headerName: this.translate.instant('table-supervisor.email'),
					field: 'email',
					sortable: true,
					autoHeight: true,
					autoHeaderHeight:true,
					cellClass:'supervisorClass',
					headerClass:'borderHeaderSupervisor',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-supervisor.employeeId'),
					field: 'id_user',
					sortable: true,
					autoHeight: true,
					autoHeaderHeight:true,
					cellClass:'supervisorClass',
					headerClass:'borderHeaderSupervisor',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-supervisor.activeDays'),
					field: 'active_days',
					sortable: true,
					autoHeight: true,
					autoHeaderHeight:true,
					cellClass:'supervisorClass',
					headerClass:'borderHeaderSupervisor',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-supervisor.statusUser'),
					field: 'name_status',
					sortable: true,
					autoHeight: true,
					autoHeaderHeight:true,
					cellClass:'supervisorClass',
					headerClass:'borderHeaderSupervisor',
					cellRenderer: EstatusTableOrderAdminComponent,
				},
				{
					headerName: this.translate.instant('table-supervisor.expiredDate'),
					field: 'registration_date',
					sortable: true,
					autoHeight: true,
					autoHeaderHeight:true,
					cellClass:'supervisorClass',
					headerClass:'borderHeaderSupervisor',
					cellRenderer: DateTextComponent
				},
			],
			animateRows: true,
			pagination: true,
			paginationPageSize: 10,
			paginationAutoPageSize: true,
			overlayNoRowsTemplate:'<span style="padding: 10px; border: 1px solid #ccc;">No hay registros</span>',
			rowClass:'rowSupervisoresClass',
			defaultColDef: {
				flex: 1,
			}
		}
	}
	openModalUserDetail(event) {
		const dialogRef = this.dialog.open(UserDetailModalComponent, {
			width: '1456px',
			height: '1030px',
			data: event.data,
		});
	}
}

