import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UseLockersOperationComponent } from './use-lockers-operation/use-lockers-operation.component';
import { TrackingExpiredOrdersComponent } from './tracking-expired-orders/tracking-expired-orders.component';
import { FrequencyUseComponent } from './frequency-use/frequency-use.component';
import { RatingAverageComponent } from './rating-average/rating-average.component';
import { HeadquartersComponent } from './headquarters/headquarters.component';
import { HistoricalOrdersComponent } from './historical-orders/historical-orders.component';
import { UseLockersCompartmentComponent } from './use-lockers-compartment/use-lockers-compartment.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { TranslateModule } from '@ngx-translate/core';
import { RecolectedRefundOrdersComponent } from './recolected-refund-orders/recolected-refund-orders.component';

@NgModule({
	declarations: [
		UseLockersOperationComponent,
		TrackingExpiredOrdersComponent,
		FrequencyUseComponent,
		RatingAverageComponent,
		HeadquartersComponent,
		HistoricalOrdersComponent,
		UseLockersCompartmentComponent,
		RecolectedRefundOrdersComponent,
	],
	exports: [
		UseLockersOperationComponent,
		TrackingExpiredOrdersComponent,
		FrequencyUseComponent,
		RatingAverageComponent,
		HeadquartersComponent,
		HistoricalOrdersComponent,
		UseLockersCompartmentComponent,
		RecolectedRefundOrdersComponent,
	],
	imports: [CommonModule, SharedModule, NgApexchartsModule, TranslateModule],
})
export class ChartsOverviewModule {}
