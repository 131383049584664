import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminClientService } from '../../../../core/http/admin-client.service';

@Component({
	selector: 'confirm-delete',
	templateUrl: './confirm-delete.html',
	styleUrls: ['./confirm-delete.scss'],
})
export class ConfirmDelete {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		private adminService: AdminClientService
	) {}
	message;

	ngOnInit() {
		this.message = this.data.message;
	}
	async deleteUser() {
		localStorage.setItem('deleted', '1');
	}
}
