<div class="container-graphic">

    <div class="col header-graphic">
        <!-- HEADER -->
        <div class="col">
            <div class="header-txt">
                {{'orders.orderMetrics' | translate}}
            </div>
        </div>
        <!-- CHART -->
        <div class="col" id="chart">
            <apx-chart 
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [xaxis]="chartOptions.xaxis"
            [stroke]="chartOptions.stroke"
            [tooltip]="chartOptions.tooltip"
            [dataLabels]="chartOptions.dataLabels"
            [legend]="chartOptions.legend"
            [markers]="chartOptions.markers"
            [grid]="chartOptions.grid"
            [yaxis]="chartOptions.yaxis"
            [title]="chartOptions.title"
            ></apx-chart>
        </div>
    </div>
</div>