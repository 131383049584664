<!-- <div>
    <div>
        <h2 class="title-confirm">¿Estas seguro de eliminar?</h2>
        <h4 class="subtitle-confirm">Esta acción es irreversible</h4>
    </div>
    <div>
        <button mat-button mat-dialog-close class="btn-cancel">Cancelar</button>
        <button mat-button mat-dialog-close (click)="deleteUser()" class="button-confirm">Aceptar</button>
    </div>
</div>  -->

<div class="container">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h2 class="title-confirm">
                ¿Estas seguro de eliminar?
            </h2>

            <i mat-dialog-close class="bi bi-x"></i>
            <!-- <i class="btn-cancel">Cancelar</i> -->

            <h4 class="subtitle-confirm">Esta acción es irreversible</h4>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="    margin-bottom: 2rem;">
            <button mat-dialog-close class="btn-cancel" style="background-color: white !important;">Cancelar</button>
            <button mat-dialog-close (click)="deleteUser()" class="button-confirm">Aceptar</button>
        </div>

    </div>

</div>