<div *ngIf="this.statusID === 39">
	<div class="pago-pendiente">
		{{'oxxoToOxxo.status.'+this.statusID|translate}}
	</div>
</div>
<div *ngIf="this.statusID === 35">
	<div class="scheduled">
		{{'oxxoToOxxo.status.scheduled'|translate}}
	</div>
</div>
<div *ngIf="this.statusID === 47">
	<div class="inLockerOrigin">
		{{'oxxoToOxxo.status.'+this.statusID|translate}}
	</div>
</div>
<div *ngIf="this.statusID === 49">
	<div class="InTransitCEDIS">
		{{'oxxoToOxxo.status.'+this.statusID|translate}}
	</div>
</div>
<div *ngIf="this.statusID === 4">
	<div class="CEDISorigin">
		{{'oxxoToOxxo.status.CEDISOrigin'|translate}}
	</div>
</div>
<div *ngIf="this.statusID === 5">
	<div class="CEDISdestiny">
		{{'oxxoToOxxo.status.CEDISDestiny'|translate}}
	</div>
</div>
<div *ngIf="this.statusID === 6">
	<div class="inLockerDestiny">
		{{'oxxoToOxxo.status.inLockerDestiny'|translate}}
	</div>
</div>
<div *ngIf="this.statusID ===59">
	<div class="expired">
		{{'oxxoToOxxo.status.'+this.statusID|translate}}
	</div>
</div>
<div *ngIf="this.statusID === 8">
	<div class="collected">
		{{'oxxoToOxxo.status.recolectado'|translate}}
	</div>
</div>
