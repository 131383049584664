<div *ngIf="message">	
	<h1 class="ok">{{'general.succes' | translate}}</h1>
	<p>{{'general.succesfully'| translate}}</p>
</div>
<div *ngIf="!message">	
	<h1 class="err">{{'general.failure' | translate}}: {{ codigo }}</h1>
	<p>{{'general.failure_msg'| translate}}</p>
</div>
<div mat-dialog-actions >  
  <button mat-button class="button-close" mat-dialog-close>{{'general.close'| translate}}</button>
</div>