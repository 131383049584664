import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'button-export',
	templateUrl: './button-export.component.html',
	styleUrls: ['./button-export.component.scss'],
})
export class ButtonExportComponent {
	constructor(private translate: TranslateService) {
		translate.setDefaultLang(
			localStorage.getItem('language').toLowerCase()
		);
	}
}
