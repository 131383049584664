import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { ScheduleTableAdminComponent } from '../schedule-table-admin/schedule-table-admin.component';

@Component({
	selector: 'app-erase-order',
	templateUrl: './erase-order.component.html',
	styleUrls: ['./erase-order.component.scss'],
})
export class EraseOrderComponent implements ICellRendererAngularComp, OnInit {
	icon: string = '';

	scheduledTable: ScheduleTableAdminComponent;
	order;

	@Output() orderDeleted = new EventEmitter();

	constructor(
		private adminService: AdminClientService,
		private toastr: ToastrService
	) {}

	ngOnInit(): void {}

	agInit(params: ICellRendererParams): void {
		this.icon = params.data.actions[0];
		this.order = params.data;
	}

	refresh(params: ICellRendererParams) {
		return false;
	}

	async deletePackageSchedule(element: any) {
		let deleteOrder = {
			language: localStorage.getItem('language').toLowerCase(),
			id_service: element.id_service,
		};

		let res: any = await this.adminService.deleteSchedulePackage(
			deleteOrder
		);

		if (res.mensaje_return.CODE === 200) {
			this.toastr.success(
				'La operación ha sido realizada con éxito.',
				'Título de la notificación',
				{
					toastClass: 'toast-extendida',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
				}
			);

			this.orderDeleted.emit(element.id_service);
		}
	}
}
