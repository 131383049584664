
<!--? HEADER -->
<mat-card-header class="order container-filters mt-3">
    <!--! <mat-form-field appearance="fill" class="range-date">
        <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Start date" #dateRangeStart disabled>
            <input matEndDate placeholder="End date" #dateRangeEnd (dateChange)="addEvent(dateRangeStart, dateRangeEnd)" disabled>
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
    </mat-form-field> -->

    <!--* INPUT SEARCH BY ORDER -->
    <mat-card-title class="title-card me-3">
        <div class="search-box"><mat-icon id="search">search</mat-icon>
            <input (keyup)="applyFilter($event)" 
            placeholder="{{'dashboard.search' | translate}}"
            class="input_search">
        </div>        
    </mat-card-title>

    <!--* DATE PICKERS -->
    <!-- <div class="row"> -->
        <!-- SELECT BY DATE -->
        <!-- <div class="col">
            <span class="p-float-label">
                
                <p-calendar class="selectYear" 
                    [showIcon]="true" 
                    [(ngModel)]="rangeDates" 
                    (ngModelChange)="addEventRangeDates()" 
                    selectionMode="range" 
                    [readonlyInput]="true" 
                    inputId="range" >
                </p-calendar> -->
                <!-- TITLE -->
                <!-- <label>{{'log_page.selection' | translate }}</label>
            </span>
        </div> -->

        <!-- SELECT BY MONTH -->
        <!-- <div class="col">
            <span class="p-float-label">
                <p-calendar placeholder="Selecciona un mes" 
                    class="selectYear" 
                    [showIcon]="true" [(ngModel)]="selectMonth" 
                    (ngModelChange)="selectMonthEvent()" 
                    view="month" 
                    dateFormat="mm/yy" 
                    [yearNavigator]="true" 
                    yearRange="2000:2030" 
                    [readonlyInput]="true" 
                    inputId="monthpicker">
                </p-calendar> -->
                <!-- TITLE -->
                <!-- <label>{{'log_page.month' | translate }}</label>
            </span>
        </div> -->
    <!-- </div> -->

    <!-- DESABILITADO -->
    <input type="file" 
        id="myFile" 
        style="display:none;" 
        accept=".xlsx, .xls, .csv" 
        (change)="onFileChange($event)"/>
    
    <!--* BUTTONS -->
    <div class="row">
        <div class="">
            <!-- UPLOAD PACKAGES -->
            <!-- <button onclick="document.getElementById('myFile').click();"
                class="btn-secondary-gbl">
            
                    {{'buttons.up_order' | translate}}
                
                    <i  class="fas fa-upload"></i>
            </button> -->

            <!-- DOWNLOAD -->
            <button class="btn-secondary-gbl" (click)="downloadExcel()">
                {{'buttons.download' | translate}} 
                <i  class="fas fa-file-excel green-color"></i>
            </button>

            <!-- CREATE A NEW ORDER -->
            <!-- <button (click)="createPackageSchedule()" class="btn-primary-gbl">
    
                {{'buttons.create_orden' | translate}}
      
            </button> -->
            <!-- <app-create-new-order></app-create-new-order> -->
        </div>

    </div>


</mat-card-header>
<mat-card-content>  
    <div class="example-container">  
    <table mat-table matTableExporter [dataSource]="dataSource" #exporter="matTableExporter" matSort (matSortChange)="sortData($event)" class="colortable">
        <ng-container *ngFor="let column of displayedColumns; let i = index" matColumnDef="{{column}}">
            <th mat-header-cell *matHeaderCellDef class="color text-center {{(column=='rate_start') ? 'rate_start' : ''}} {{(column=='actions_order') ? 'actions_order' : ''}}" mat-sort-header="{{column}}" > 
                <span *ngIf="column!='actions_order'">{{'table.'+column | translate}}</span>
                <span *ngIf="column=='actions_order'">...</span> 
            </th> 
            <td 
            mat-cell 
            *matCellDef="let element; let j = index" 
            class="text-center {{column=='status' ? element[column] : ''}} {{(element.isScheduleData && element.id_status_service==40 && column=='status') ? 'finish' : (element.isScheduleData && element.id_status_service==37 && column=='status') ? 'process' : ''}} {{(element.id_status_code_package == 32 && column=='status') ? 'VENCIDO' : ''}}"> 

                <p *ngIf="column!='signature' && column!='evidence' && column!='rate_start' && column!='actions' && column!='pickedUp' && column!='status' && column!='extend' && column!='inLocker' && column!='registrationDateExpired' && column!='registrationDateRefund' && column!='packagePickupDate' && column!='expired' && column!='order' && i!=0">
                    {{ (element[column]!='not_select')  ? element[column] : 'table.'+element[column] | translate}}
                </p> 
                <span  *ngIf="i==0 && column!='order'">                    
                    <span *ngIf="!element.isScheduleData">{{element[column]}}</span>
                    <span *ngIf="element.id_status_service==40">{{element[column]}}</span>
                </span>

                <p *ngIf="column=='status' && !element.isScheduleData">                    
                    <mat-form-field id="expired" (click)="picker.open()">
                        <input [min]="minDate" [max]="maxDate" [(ngModel)]="date[j]" name="{{element[column]}}" matInput [matDatepicker]="picker" readonly>                                               
                        <mat-datepicker (closed)="updateTemp(element, date[j])" touchUi #picker></mat-datepicker>
                    </mat-form-field>

                    <span *ngIf="element.status_package==14" (click)="picker.open()">{{'table.'+element[column].toLowerCase() | translate}}<br></span>
                    <span *ngIf="element.status_package==16">{{'table.'+element[column].toLowerCase() | translate}}</span>

                    <span *ngIf="date[j]!=undefined" (click)="picker.open()">{{'table.until' | translate}} <br></span>
                    <span *ngIf="date[j]" id="new-date" class="{{ok}}">{{date[j] | date: 'dd-MMM-yyy'}}</span>
                    <span *ngIf="date[j]==undefined && (element.id_status_code_package == 17 && element.status_package == 14)"
                    (click)="picker.open()" 
                    class="LINK">
                        {{'table.extend'|translate}}?
                    </span>
                    <span *ngIf="date[j]==undefined && (element.id_status_code_package == 32 && element.status_package == 14)"
                    (click)="picker.open()" 
                    class="LINK">                   
                        {{'table.extend'|translate}}? <br>
                        {{'table.expired_in_locker'|translate}}
                    </span>
                </p>
                <!-- Aparatado para como se mostrara los estatus de Agendados-->
                <p *ngIf="column=='status' && element.isScheduleData">                    
                    {{'table.'+element[column].toLowerCase() | translate}}
                </p>

                <p *ngIf="column=='expired'">
                    {{element[column] | date: 'dd-MMM-yyy'}}
                </p>
                <p *ngIf="column=='inLocker' || column=='registrationDateExpired' || column=='registrationDateRefund' || column=='packagePickupDate'">                    
                    {{element[column] | date: 'HH:mm'}}<br>{{element[column] | date: 'dd-MMM-yyy'}}
                </p>

                <span *ngIf="!element.pickedUp && column=='evidence'">{{ 'table.not_yet' | translate }}</span>

                <span (click)="pickedModal(element.evidence, element.tracking)" class="link" *ngIf="element.pickedUp && element.evidence && column=='evidence'">{{'table.view' | translate}}</span>


                <span *ngIf="column=='actions'">
                    <i *ngIf="!date[j] && element.isScheduleData" (click)="deletePackageSchedule(element)" class="{{element[column][0]}}"></i>
                    <span *ngIf="date[j]" >                        
                        <button class="cancel" mat-raised-button (click)="cancel()">Cancel</button>
                        <button class="apply {{ok}}" mat-raised-button (click)="update()">Apply</button>
                    </span>
                </span>

                <!-- Para mostrar las ordenes agendads y su enlace-->
                 <span *ngIf="column=='tracking' && element.id_status_service == 35 && element.isScheduleData" class="link" (click)="pickedModalQRSchedule(element.qr)" >{{element[column]}}</span>
                <span *ngIf="column=='tracking' && element.id_status_service != 35 && element.id_status_service!=40 && element.isScheduleData">{{element[column]}}</span>


                <span *ngIf="column=='order' && (element.id_status_code_package == 17 && element.status_package == 14 && !element.isScheduleData)" class="link" (click)="pickedModalQR(element.qr_order)" >{{element[column]}}</span>

                <span *ngIf="column=='order' && (element.id_status_code_package == 18 && element.status_package == 16 && !element.isScheduleData)">{{element[column]}}</span>
                
                <span *ngIf="column=='order' && (element.id_status_code_package == 32 && element.status_package == 33 && !element.isScheduleData)">{{element[column]}}</span>

                <span *ngIf="column=='order' && (element.id_status_code_package == 32 && element.status_package == 14 && !element.isScheduleData)" class="link" (click)="pickedModalQR(element.order)">{{element[column]}}</span>

                <span *ngIf="column=='order' && !element.isScheduleData && (!element.id_status_code_package) && (element.status=='DISPONIBLE')" class="link" (click)="pickedModalQR(element.order)">{{element[column]}}</span>

                <span *ngIf="column=='order' && !element.isScheduleData && (!element.id_status_code_package) && (element.status!='DISPONIBLE')">{{element[column]}}</span>

                <span *ngIf="column=='rate_start'  && !element.isScheduleData && element.rate_start != null">
                    <app-star-rating-admin start="{{element.rate_start}}"> </app-star-rating-admin>
                </span>   

                
                
                <!-- BUTTONS --------------------------------------------------------------------------------->
                <span *ngIf="column=='actions_order' 
                        && (element.id_status_code_package 
                        == 18 && element.status_package 
                        == 16 && !element.isScheduleData)">
                    <button mat-icon-button 
                            [matMenuTriggerFor]="menu" 
                            aria-label="Example icon-button with a menu">
                      <mat-icon> more_vert </mat-icon>
                    </button>

                    <mat-menu #menu="matMenu">

                      <button   (click)="pickedModal(element.evidence, element.tracking)" 
                                mat-menu-item
                                class="rounded mx-auto d-block">
                        <mat-icon>picture_in_picture</mat-icon>
                        <span>Evidencia</span>
                      </button>

                      <button (click)="moreInfoPackage(element.dataPackage)" mat-menu-item>
                        <mat-icon>pageview</mat-icon>
                        <span>Mas info</span>
                      </button>

                    </mat-menu>
                </span>        
                            
            </td>

        </ng-container>
       
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    </div>

    <mat-paginator [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</mat-card-content>