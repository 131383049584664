import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-tooltip-collection',
	templateUrl: './tooltip-collection.component.html',
	styleUrls: ['./tooltip-collection.component.scss']
})
export class TooltipCollectionComponent implements OnInit {
	@Input() tooltip;
	@Input() idCard : number = 0;
	textTooltip;
	constructor(){

	}
	ngOnInit() {
		// this.textTooltip = this.tooltip[this.id].toolTip;
	}

}
