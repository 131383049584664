<div class="grid-chart">
    <!-- HEADER -->
    <div class="header">
        <!-- LOCKER USE -->
        <span class="span-card">
            Uso del locker
        </span>
    </div>
    <!--? TOTAL CHART -->

    <!--? CHART -->
    <div class="chart-container">
        <div id="chart">
            <apx-chart
				[series]="chartOptionsDonut.series"
				[chart]="chartOptionsDonut.chart"
				[plotOptions]="chartOptionsDonut.plotOptions"
				[labels]="chartOptionsDonut.labels"
				[legend]="chartOptionsDonut.legend"
				[colors]="chartOptionsDonut.colors"
				[responsive]="chartOptionsDonut.responsive"
				[stroke]="chartOptionsDonut.stroke">
			</apx-chart>
        </div>
    </div>
    <!-- FOOTER -->
    <div class="footer">
        <!-- LEGENDS -->
        <!--? LEGENDS -->
            <!-- DELIVERY -->
            <div class="legend-container">
                <div class="legend-1">
                    <div class="box-1"></div>
                    <span class="span-box">Entregados</span>
                </div>
            </div>
            <!-- RETURN WITHDRWN -->
            <div class="legend-container">
                <div class="legend-1">
                    <div class="box-2"></div>
                    <span class="span-box">Retirados</span>

                    <div class="box-3"></div>
                    <span class="span-box">Devueltos</span>
                </div>
            </div>
    </div>
</div>
