import { Component, OnInit } from '@angular/core';
import { AgPromise, ILoadingCellRendererComp, ILoadingCellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'app-custom-loading-cell-rendered',
	templateUrl: './custom-loading-cell-rendered.component.html',
	styleUrls: ['./custom-loading-cell-rendered.component.scss']
})
export class CustomLoadingCellRenderedComponent implements ILoadingCellRendererComp  {
	eGui!: HTMLElement;
	constructor() { }
	getGui(): HTMLElement {
		throw new Error('Method not implemented.');
	}
	destroy?(): void {
		throw new Error('Method not implemented.');
	}
	init?(params: ILoadingCellRendererParams & { loadingMessage: string }){
		this.eGui = document.createElement('div');
		this.eGui.innerHTML = `
				<div class="ag-custom-loading-cell" style="padding-left: 10px; line-height: 25px;">
					<i class="fas fa-spinner fa-pulse"></i>
					<span>${params.loadingMessage} </span>
				</div>
			`;
	}
}
