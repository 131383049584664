import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'btn-activity',
  templateUrl: './btn-activity.component.html',
  styleUrls: ['./btn-activity.component.scss']
})
export class BtnActivityComponent {
	idUserType:number;

	constructor(private router      :Router){}
	ngOnInit(){
		this.idUserType = parseInt(localStorage.getItem('user_type'));
	}

	navigateToActivity(){
		if(this.idUserType === 7){
			this.router.navigateByUrl('vivipost/activity')
		}
		else if(this.idUserType ===9){
			this.router.navigateByUrl('l-retail/activity')
		}
		else if(this.idUserType === 10){
			this.router.navigateByUrl('carriers/activity')
		}
	}
}
