<div class="container-kpis">

    <!--? KPI TOTAL -->
    <div class="big-card">
        <!-- LOGO -->
        <div class="logo-kpi"></div>
        <!-- FOOTER -->
        <div class="footer-grid">
            <!-- KPI -->
            <span class="span-kpi"> {{this.total}} </span>
            <!-- SPAN -->
            <span class="span-footer"> {{ 'kpi-locker.kpi-footer-total' | translate }} </span>
        </div>

    </div>

    <!--? 3 KPI'S -->
    <app-card
        class="animate__animated animate__fadeIn"
        [gridStyle]="gridOverview"
        [dataCardSm]="dataKpis"
        [tooltip]="tooltip"
        [addComp]="true">

    </app-card>
    <!--? MAP -->
    <div class="container-map animate__animated animate__fadeIn">
        <div class="map-size">
            <div class="header-map">
                {{'Location map' | translate}}
            </div>
            <mat-card-content id="map-content">
                <ng-container *ngIf="arrayMarker.length>0">
					<app-google-maps latitud="19.440100997219627" longitud="-99.09434336459498" [zoom]="17" [coords]="arrayMarker" (eventAddress)="handleMarkerClick($event)"></app-google-maps>
                </ng-container>
            </mat-card-content>
        </div>
    </div>

</div>
