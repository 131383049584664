<div class="general">
	<div class="row">
		<div class="col-sm-12 col-md-12 col-lg-12 col-xl-4">
			<div class="input-container">
				<input
					type="text"
					class="input-gbl"
					(keyup)="onFilterTextChange($event)"
					placeholder="{{'dashboard.search_user' | translate}}">
				<button
					type="submit"
					class="btn-search">
					<div class="icon-search">
					</div>
				</button>
			</div>
		</div>
		<div class="col-sm-12 col-md-12 col-lg-12 col-xl-8">
			<div class="filters">
				<!-- <p-calendar
					class="selectYear"
					[showIcon]="true"
					[(ngModel)]="rangeDates"
					(ngModelChange)="addEventRangeDates()"
					selectionMode="range"
					[readonlyInput]="true"
					placeholder="Date picker"
					inputId="range" >
				</p-calendar> -->
				<app-button-icon *ngIf="this.mode ==='light'"
					[activo]="false"
					btnNombre="Filtros"
					btnType="secondaryMedium"
					icon="icon-funnel"
					(click)="showFilters()">
				</app-button-icon>
				<app-button-icon *ngIf="this.mode ==='dark'"
					[activo]="false"
					btnNombre="Filtros"
					btnType="secondaryMedium"
					icon="icon-funnel-dark"
					(click)="showFilters()">
				</app-button-icon>
				<button-export (click)="onExport()"></button-export>
			</div>

		</div>

		<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
			<ag-grid-angular
				#agGrid
				class="ag-theme-alpine"
				[gridOptions]="this.gridOptions"
				[rowData]="this.rowData"
				suppressScrollOnNewData = true
				[columnDefs]="this.columnDefsGeneralOTO"
				style="width: 100%; height: 714px; border: none; margin-top:24px"

				[overlayLoadingTemplate]="overlayLoadingTemplate"
				(gridReady)="onGridReady($event)">
			</ag-grid-angular>
		</div>
	</div>
</div>


<app-filter-oxxo-to-oxxo [filters]="this.filters">

</app-filter-oxxo-to-oxxo>
