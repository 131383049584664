<!-- TOOLTIP BOTTOM -->
<div [ngClass]="{
    'center-z-index' : position === 'center',
    'end-z-index' : position === 'end',
    'start-z-index' : position === 'start'
    }"></div>
<div [ngClass]="{
    'center' : position === 'center',
    'end' : position === 'end',
    'start' : position === 'start'
    }">
</div>
<!-- TOOLTIP SIZE -->
<span [ngClass]="{
    'center-container' : position === 'center',
    'end-container' : position === 'end',
    'start-container' : position === 'start'
    }">
    {{tooltip | translate }}
</span>