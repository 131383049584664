import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { utils } from 'xlsx';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { StarRatingComponent } from 'src/app/shared/components/star-rating/star-rating.component';
import { Calendar } from 'primeng/calendar';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FilterService } from 'src/app/core/http/FilterDataServiceOxxoToOxxo.service';
import { EstatusTableOrderAdminComponent } from '../../estatus-table-order-admin/estatus-table-order-admin.component';
import { DateTextTablesComponent } from 'src/app/shared/components/date-text-tables/date-text-tables.component';
import { DialogOrderDetailComponent } from '../../dialog-order-detail/dialog-order-detail.component';
import { DialogDeliverPersonallyComponent } from '../../dialog-deliver-personally/dialog-deliver-personally.component';
interface Pagination {
    name: string;
    value: number;
}
@Component({
  selector: 'app-table-pending',
  templateUrl: './table-pending.component.html',
  styleUrls: ['./table-pending.component.scss']
})
export class TablePendingComponent implements OnInit {
	public columnDefs: ColDef[];
	public defaultColDefGeneral: ColDef = {
		sortable: true,
		filter: true,
	};
	public rowDataGeneral: any[];
	private gridApi: AgGridAngular;
	supportLanguages = ['es', 'en'];
	InitialDate = new Date();
	showDate: Date;
	showDateFinal: Date;
	fechasByMonth: string;
	FinalDate = new Date();
	res;
	completedDataCompleted = [];
	countCompleted = 0;
	rangeDates: Date[];
	valueInput;
	display = false;
	minDate: Date;
	maxDate: Date;
	orderSelectToExtendDate: any;
	displayDialogOrder = false;
	filterText: string = '';
	gridOptions: GridOptions;
	rowData: any[];
	dataTemporal = [];
	public filteredData: any[] = [];
	idLocker: string;
	trackingNumber = localStorage.getItem('searchOrderNotification');
	@ViewChild('calendar') calendar: Calendar;
	@ViewChild('input') textToFilter: ElementRef;
	@ViewChild('agGrid') agGrid: AgGridAngular;
	agGridApi:AgGridAngular;
	currentPage:number =1;
	totalP:number;
	sizeData:number;
	filters:boolean=false;
	cities: Pagination[] | undefined;
    selectedCity = 10;
	mode:string;
	constructor(
		private translate		: TranslateService,
		private router			: Router,
		private adminService	: AdminClientService,
		private toastr			: ToastrService,
		private matdialog		: MatDialog,
		private route			: ActivatedRoute,
		private filterService	: FilterService,
		private elementRef		: ElementRef) {
		let today = new Date();
		this.FinalDate = new Date();
		this.InitialDate = new Date(this.FinalDate);
		if((today.getDate()) === 31){
			this.InitialDate.setDate(0);
		}
		else{
			this.InitialDate.setMonth(this.InitialDate.getMonth() -1);
		}
		this.rangeDates = [this.InitialDate, this.FinalDate];
		this.idLocker = localStorage.getItem('id_locker');
	}
	ngOnInit() {
		this.mode = localStorage.getItem('mode');
		if( localStorage.getItem('searchOrderNotification') !== null){
			this.trackingNumber = localStorage.getItem('searchOrderNotification');
			this.filterText = this.trackingNumber;
		}
		this.route.params.subscribe(params => {
			const id = params['id'];
			if(this.trackingNumber !== id){
				this.filterText = id;
			}
		})
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.addEventRangeDates();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			localStorage.setItem('id_locker', this.idLocker);
			this.translate.use(event.lang);
			this.translate.get([
					'table-general.tracking',
					'table-general.lockerID',
					'table-general.email',
					'table-general.employeeName',
					'table-general.employeeID',
					'table-general.doorNumber',
					'table-general.status',
					'table-general.inLocker',
					'table-general.dateCollected',
					'table-general.rateStart',
			]).subscribe((t: any) => {
				this.columnDefs = [
					{
						headerName: t['table-general.tracking'],
						field: 'tracking',
						sortable: true,
						width: 170,
						autoHeight: true,
						cellClass:'generalClassLink',
						cellRenderer: this.centeredCellRenderer,
						headerClass:'borderHeaderGeneral',
						onCellClicked: ($event) =>
							this.openModalUserDetail($event),
					},
					{
						headerName: t['table-general.lockerID'],
						field: 'lockerID',
						sortable: true,
						width: 120,
						autoHeight: true,
						cellClass:'generalClass',
						cellRenderer: this.centeredCellRenderer,
						headerClass:'borderHeaderGeneral'
					},
					{
						headerName: t['table-general.email'],
						field: 'email',
						sortable: true,
						width: 300,
						autoHeight: true,
						cellClass:'generalClass',
						cellRenderer: this.centeredCellRenderer,
						headerClass:'borderHeaderGeneral'
					},
					{
						headerName: t['table-general.employeeID'],
						field: 'employeeId',
						sortable: true,
						width: 150,
						autoHeight: true,
						cellClass:'generalClass',
						cellRenderer: this.centeredCellRenderer,
						headerClass:'borderHeaderGeneral'
					},
					{
						headerName: t['table-general.doorNumber'],
						field: 'doorNumber',
						sortable: true,
						width: 130,
						autoHeight: true,
						cellClass:'generalClass',
						cellRenderer: this.centeredCellRenderer,
						headerClass:'borderHeaderGeneral'
					},
					{
						headerName: t['table-general.status'],
						field: 'status',
						sortable: true,
						width: 150,
						autoHeight: true,
						cellRenderer: EstatusTableOrderAdminComponent,
						headerClass:'borderHeaderGeneral',
						cellClass:'generalClass',
						onCellClicked: ($event) =>
							this.openCalendar($event),
					},
					{
						headerName: t['table-general.inLocker'],
						field: 'inLocker',
						sortable: true,
						width: 150,
						autoHeight: true,
						cellClass:'generalClass',
						cellRenderer: DateTextTablesComponent,
						headerClass:'borderHeaderGeneral'
					},
					{
						headerName: t['table-general.dateCollected'],
						field: 'dateCollected',
						sortable: true,
						width: 150,
						autoHeight: true,
						cellClass:'generalClass',
						cellRenderer: DateTextTablesComponent,
						headerClass:'borderHeaderGeneral'
					},
					{
						headerName: t['table-general.rateStart'],
						field: 'rate_start',
						sortable: true,
						width: 150,
						autoHeight: true,
						cellClass:'generalClass',
						cellRenderer: StarRatingComponent,
						headerClass:'borderHeaderGeneral'
					},
				];
			});
		});
	}
	ngOnChanges(changes: SimpleChanges){
	}
	updateDataLocker(): void {
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant('table-general.tracking'),
					field: 'tracking',
					sortable: true,
					width: 170,
					autoHeight: true,
					cellClass:'generalClassLink',
					headerClass:'borderHeaderGeneral',
					cellRenderer: this.centeredCellRenderer,
					onCellClicked: ($event) => this.openModalUserDetail($event),
				},
				{
					headerName: this.translate.instant('table-general.lockerID'),
					field: 'lockerID',
					sortable: true,
					width: 120,
					autoHeight: true,
					cellClass:'generalClass',
					headerClass:'borderHeaderGeneral',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-general.email'),
					field: 'email',
					sortable: true,
					width: 300,
					autoHeight: true,
					cellClass:'generalClass',
					headerClass:'borderHeaderGeneral',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-general.employeeID'),
					field: 'employeeId',
					sortable: true,
					width: 150,
					autoHeight: true,
					cellClass:'generalClass',
					headerClass:'borderHeaderGeneral',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-general.doorNumber'),
					field: 'doorNumber',
					sortable: true,
					width: 150,
					autoHeight: true,
					cellClass:'generalClass',
					headerClass:'borderHeaderGeneral',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-general.status'),
					field: 'status',
					sortable: true,
					width: 150,
					autoHeight: true,
					cellClass:'generalClass',
					cellRenderer: EstatusTableOrderAdminComponent,
					headerClass:'borderHeaderGeneral',
					onCellClicked: ($event) => this.openCalendar($event),
				},
				{
					headerName: this.translate.instant('table-general.inLocker'),
					field: 'inLocker',
					sortable: true,
					width: 150,
					autoHeight: true,
					cellClass:'generalClass',
					headerClass:'borderHeaderGeneral',
					cellRenderer: DateTextTablesComponent,
				},
				{
					headerName: this.translate.instant('table-general.dateCollected'),
					field: 'dateCollected',
					sortable: true,
					width: 150,
					autoHeight: true,
					cellClass:'generalClass',
					headerClass:'borderHeaderGeneral',
					cellRenderer: DateTextTablesComponent,
				},
				{
					headerName: this.translate.instant('table-general.rateStart'),
					field: 'rate_start',
					sortable: true,
					width: 150,
					autoHeight: true,
					headerClass:'borderHeaderGeneral',
					cellRenderer: StarRatingComponent,
					cellClass:'generalClass',
				}
			],
			animateRows: true,
			pagination: true,
			paginationPageSize: 10,//this.selectedCity,
			paginationAutoPageSize: true,
			headerHeight:59,
			rowHeight:59,
			rowClass:'rowGeneralClass',
			scrollbarWidth:0,
			alwaysShowVerticalScroll:false,
			alwaysShowHorizontalScroll:false,
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`
		}
	}
	onFilterTextFromNotification(item) {
		this.filteredData = this.rowData.filter(
			(data) => data.tracking === item
		);
		this.rowData = [...this.filteredData];
		this.gridOptions.api.setRowData(this.rowData);
	}
	onGridReady(params: any) {
		const { api } = params;
		this.agGridApi = this.agGrid;
		api.sizeColumnsToFit();
		this.gridApi = params;
	}
	onCellClicked(e: CellClickedEvent): void {
		if (e.colDef.field === 'status') {
			if (
				e.data.status_package === 14 &&
				e.data.id_status_code_package === 32
			) {
				this.display = true;
				this.orderSelectToExtendDate = e.data;
			} else {
				this.display = false;
			}
		}
	}
	openCalendar(e: CellClickedEvent) {
		if (
			e.data.status_package === 14 &&
			e.data.id_status_code_package === 32
		) {
			this.display = true;
			this.orderSelectToExtendDate = e.data;
		} else {
			this.display = false;
		}
	}
	addEventRangeDates() {
		this.InitialDate = this.rangeDates[0];
		if (this.rangeDates[1] !== null) {
			this.FinalDate = this.rangeDates[1];
			this.updateDataLocker();
			this.getTableCompleted();
		}
	}
	getTableCompleted() {
		this.rowData = [];
		this.idLocker = localStorage.getItem('id_locker');
		if (this.idLocker === 'null' || this.idLocker === undefined || this.idLocker === null) {
			this.completedDataCompleted = [];
			let lang = localStorage.getItem('language').toLowerCase();
			let company = localStorage.getItem('id_company');
			let data = {
				language: lang,
				id_company: company,
				date_start: this.InitialDate.getTime(),
				date_end: this.FinalDate.getTime(),
			};
			this.adminService.getAllOrders(data).then((res:any) => {
				if(res.mensaje_return.CODE === 200){
					res.return_result.map((f) => {
						if(f.id_status_code_package === 17 && f.status_package === 14 && f.extended_package === 1){
							this.rowData.push({
								order_number: f.order_number,
								tracking: f.tracking_number,
								email: f.email,
								lockerID: f.locker_name,
								phone_used: f.phone_used,
								nameEmploye: f.delivery_employee_name,
								door: f.door_number,
								compartmentSize: f.compartment_size,
								status: f.name_status_package,
								inLocker: f.package_delivery_date,
								dateCollected: f.status_package == 16 ? f.package_pickup_date : null,
								rate_start: f.rate_start,
								id_package: f.id_package,
								id_package_code: f.id_package_code,
								employeeId: f.delivery_company_employee_id,
								carrier: 'N/A',
								doorNumber:	f.door_number + ', ' + f.compartment_name,
								doorSize: f.compartment_name,
								id_status_code_package: f.id_status_code_package,
								status_package: f.status_package,
								qr_order: f.qr_order,
								name_status_notification:f.name_status_notification,
								expiration_date: f.expiration_date,
								extended_package: f.extended_package,
								resultNotification: f.result_notification,
								IMAGE: f.IMAGE,
							});
							this.filterService.getStarsFilter().subscribe(starsFilter => {
								if(	starsFilter.size > 0){
									const filteredData = this.rowData.filter(item => starsFilter.has(item.rate_start));
									this.filteredData = filteredData;
									this.gridApi.api.setRowData(filteredData);
								}
								else{
									this.gridApi.api.setRowData(this.rowData);
								}
							});
						}
					});
					this.rowData = [...this.rowData];
					this.sizeData = this.rowData.length;
					this.totalP = Math.floor(this.rowData.length / this.selectedCity) +1;
				}
				else{
					this.rowData = [];
				}
			})
		}
		else if (this.idLocker !== 'null' ||  this.idLocker !== null) {
			this.completedDataCompleted = [];
			let lang = localStorage.getItem('language').toLowerCase();
			let company = localStorage.getItem('id_company');
			let data = {
				language: lang,
				id_locker: this.idLocker,
				id_company: parseInt(company),
				date_start: this.InitialDate.getTime(),
				date_end: this.FinalDate.getTime(),
			};
			this.adminService.getOrders(data).then((res) => {
					if (res.mensaje_return.CODE === 200) {
						if (res.return_result) {
							res.return_result.map((f) => {
								if(f.id_status_code_package == 17 && f.status_package == 14 && f.extended_package === 1){
									this.rowData.push({
										order_number: f.order_number,
										tracking: f.tracking_number,
										email: f.email,
										lockerID: f.locker_name,
										phone_used: f.phone_used,
										nameEmploye: f.delivery_employee_name,
										door: f.door_number,
										compartmentSize: f.compartment_size,
										status: f.name_status_package,
										inLocker: f.package_delivery_date,
										dateCollected: f.status_package == 16 ? f.package_pickup_date : '',
										rate_start: f.rate_start,
										id_package: f.id_package,
										id_package_code: f.id_package_code,
										employeeId: f.delivery_company_employee_id,
										carrier: 'N/A',
										doorNumber: f.door_number +', '+ f.compartment_name,
										doorSize: f.compartment_name,
										id_status_code_package:	f.id_status_code_package,
										status_package: f.status_package,
										qr_order: f.qr_order,
										name_status_notification: f.name_status_notification,
										expiration_date: f.expiration_date,
										extended_package: f.extended_package,
										resultNotification: f.result_notification,
										IMAGE: f.IMAGE,
									});
									this.filterService.getStarsFilter().subscribe(starsFilter => {
										if(	starsFilter.size > 0){
											const filteredData = this.rowData.filter(item => starsFilter.has(item.rate_start));
											this.gridApi.api.setRowData(filteredData);
											this.filteredData = filteredData;
										}
										else{
											this.gridApi.api.setRowData(this.rowData);
										}
									});
								}
							});
							this.rowData = [...this.rowData];
						}
					}
					else{
						this.rowData= [];
					}
			})
		}
	}
	DownloadExcel() {
		let headerExcel = [
			'tracking',
			'lockerID',
			'email',
			'employeeID',
			'nameEmploye',
			'inLockerDate',
			'inLockerTime',
			'dateCollectedDate',
			'dateCollectedTime',
			'rate_start',
		];
		let dataExcel = [];

		this.completedDataCompleted.forEach((e: any) => {
			let info = {
				tracking: e.tracking,
				lockerID: e.lockerID,
				email: e.email,
				employeeID: e.employeeId,
				nameEmploye: e.nameEmploye,
				inLockerDate: e.inLocker.slice(0, 10),
				inLockerTime: e.inLocker.slice(11, 19),
				dateCollectedDate: e.dateCollected.slice(0, 10),
				dateCollectedTime: e.dateCollected.slice(11, 19),
				rate_start: e.rate_start,
			};
			dataExcel.push(info);
		});
		const worksheet = utils.json_to_sheet(dataExcel, {
			header: headerExcel,
		});
		const workbook = utils.book_new();
		utils.book_append_sheet(workbook, worksheet, 'Data');
	}
	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}
	async extenderFechas(calendar) {
		let dateSelect = new Date(calendar.value);
		let month = dateSelect.getMonth().toString().length == 1 ? '0' + (dateSelect.getMonth() + 1) : dateSelect.getMonth() + 1;
		let day = dateSelect.getDate().toString().length == 1 ? '0' + dateSelect.getDate() : dateSelect.getDate();
		let d = dateSelect.getFullYear() + '-' + month + '-' + day;
		let dataSendUpdate = {
			language: localStorage.getItem('language').toString().toLowerCase(),
			id_company: localStorage.getItem('id_company'),
			id_package_code: this.orderSelectToExtendDate.id_package_code,
			date_update: d,
		};
		let res: any = await this.adminService.updateExpirationDate(dataSendUpdate);
		if (res.mensaje_return) {
			this.display = false;
			this.toastr.success('la entrega santifactoriamente','Se ha extendindo',
				{
					toastClass: 'toast-extendida',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
				}
			);
		}
		this.getTableCompleted();
	}
	openModalUserDetail(event) {
		const dialogRef = this.matdialog.open(DialogOrderDetailComponent, {
			width: '1304px',
			height: '95%',
			data: event.data,
		});

		dialogRef.afterClosed().subscribe((result) => {});
	}
	openModalDeliverPersonally(event) {
		const dialogRef = this.matdialog.open(DialogDeliverPersonallyComponent,
			{
				width: '558px',
				height: '270px',
				data: event.data,
			}
		);
		dialogRef.afterClosed().subscribe((result) => {});
	}
	cerrarExtender() {
		this.display = false;
		this.orderSelectToExtendDate = [];
	}
	onFilterTextChange(event) {
		this.filterText = event.target.value;
		let api = this.gridOptions.api;
		let temporal = [];
		this.filteredData = temporal;
	}
	onExport() {
		const dayInitial = this.InitialDate.getDate() < 10 ? '0' + this.InitialDate.getDate() : this.InitialDate.getDate();
		const monthInitial = this.InitialDate.getMonth() + 1 < 10 ? '0' + (this.InitialDate.getMonth() + 1)	: this.InitialDate.getMonth() + 1;
		const yearInitial = this.InitialDate.getFullYear();
		const dayFinal = this.FinalDate.getDate() < 10 ? '0' + this.FinalDate.getDate() : this.FinalDate.getDate();
		const monthFinal = this.FinalDate.getMonth() + 1 < 10 ? '0' + (this.FinalDate.getMonth() + 1) : this.FinalDate.getMonth() + 1;
		const yearFinal = this.FinalDate.getFullYear();
		const fileName = 'OrdersGeneral'+dayInitial+monthInitial+yearInitial+'_'+dayFinal+monthFinal+yearFinal+'.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'OrdersGeneral',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		if(this.filterText !== null){
			if(this.filterText !== ''){
				const Data = this.rowData.filter(item=> item.tracking===this.filterText) || [];
				const excelData = Data.map((row) => {
					// Crear un nuevo objeto clonando 'row' sin incluir el campo 'lockername'
					const { id_package,id_package_code,id_status_code_package,status_package,qr_order,resultNotification,IMAGE,  ...rowDataWithoutFields} = row;
					return rowDataWithoutFields;
				});
				const wb = XLSX.utils.book_new();
				const ws = XLSX.utils.json_to_sheet(excelData);
				XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
				XLSX.writeFile(wb, fileName);
			}

		}
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				const { lockerID, id_package,id_package_code,id_status_code_package,status_package,qr_order,resultNotification,IMAGE,  ...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			XLSX.writeFile(wb, fileName);
		}
		else if (this.rowData.length > 0 && this.filteredData.length === 0 && (this.trackingNumber === null)) {
			if(this.trackingNumber !== ''){
				const Data = this.rowData || [];
				const excelData = Data.map((row) => {
					// Crear un nuevo objeto clonando 'row' sin incluir el campo 'lockername'
					const { lockerID, id_package,id_package_code,id_status_code_package,status_package,qr_order,resultNotification,IMAGE,  ...rowDataWithoutFields} = row;
					return rowDataWithoutFields;
				});
				const wb = XLSX.utils.book_new();
				const ws = XLSX.utils.json_to_sheet(excelData);
				XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
				XLSX.writeFile(wb, fileName);
			}
		}
	}
	goToFirstPage() {
		this.agGridApi.api.paginationGoToFirstPage();
		this.currentPage = this.agGridApi.api.paginationGetCurrentPage() + 1;
	}
	goToLastPage() {
		this.agGridApi.api.paginationGoToLastPage();
		this.currentPage = this.agGridApi.api.paginationGetCurrentPage() + 1;
	}
	gotoNextPage(){
		this.agGridApi.api.paginationGoToNextPage();
		this.currentPage = this.agGridApi.api.paginationGetCurrentPage() + 1;
	}
	gotoPreviousPage(){
		this.agGridApi.api.paginationGoToPreviousPage();
		this.currentPage = this.agGridApi.api.paginationGetCurrentPage() + 1;
	}

	changeSize(){
		this.getTableCompleted();
		this.currentPage = 1
		this.updateDataLocker();
	}

	showFilters(){
		this.filters = !this.filters;
		if (this.filters) {
			this.registerClickOutsideListener();
		}
		else {
			this.closeFilters();
		}
	}
	private registerClickOutsideListener() {
		document.addEventListener('click', this.clickOutsideHandler.bind(this));
	}
	private clickOutsideHandler(event: Event) {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.closeFilters();
		}
	}

	private closeFilters() {
		this.filters = false;
		document.removeEventListener('click', this.clickOutsideHandler.bind(this));
	}

	// get totalPages() {
	// 	return this.agGridApi.api.paginationGetTotalPages();
	// }
}
