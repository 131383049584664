import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class WebhookService {
	public baseUrl: string = environment.url_server_backend;
	private token: string;
	private options: any;
	public temp: any;
	public data;

	constructor(private http: HttpClient) { }

	setToken(token: string) {
		this.token = token;
	}
	getToken() {
		return this.token ? this.token : localStorage.getItem('token');
	}

	async getQueryEventWebHook(params){
		let options = {
			headers: {
				'Content-Type': 'application/json',
				token: this.getToken()
			},
			params: params
		}
		let url = this.baseUrl +'/server/webhook/query-event-webhooks';
		return await this.http.get(url,options).toPromise();
	}
	async getWebHook(params){
		let options = {
			headers: {
				'Content-Type': 'application/json',
				token: this.getToken()
			},
			params: params
		}
		let url = this.baseUrl +'/server/webhook/query-webhooks';
		return await this.http.get(url,options).toPromise();
	}
	async getApiKey(params){
		let options = {
			headers: {
				'Content-Type': 'application/json',
				token: this.getToken()
			},
			params: params
		}
		let url = this.baseUrl +'/server/integration/query-api-key';
		return await this.http.get(url,options).toPromise();
	}
	async getQueryServiceWebHook(params){
		let options ={
			headers:{
				'Content-Type': 'application/json',
				token: this.getToken()
			},
			params: params
		}
		let url = this.baseUrl +'/server/webhook/query-service-webhooks';
		return await this.http.get(url,options).toPromise();
	}

	async updateWebhookService(params){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			token: this.getToken()
		});
		let url =  this.baseUrl + '/server/webhook/update-webhooks-service';
		const body = JSON.stringify(params);
		const request = new HttpRequest('PUT', url, body, { headers });
		const response = await this.http.request(request).toPromise();
		return response;
	}
}
