  <!-- ? CONTAINER -->
  <div class="row container-table justify-content-center ">
    <div class="col container-rows-table">
        <!-- TABLE -->
        <div class=" table-responsive">
            <!-- COLUMN -->
            <div class="col">
                <form class="d-flex my-3" role="search" id="search">
                    <mat-icon id="search">search</mat-icon>
                    <input  
                        class="form-control rounded-pill"
                        type="search"
                        (keyup)="applyFilter($event)"
                        placeholder="{{'dashboard.filter' | translate}}"
                        aria-label="Search"
                        #txtSearch>
                </form>
            </div>

            <!--* NEW TABLE -->
            <div class="table-responsive">
                <table  class="table table-hover table-striped text-center table-hover"
                        mat-table [dataSource]="dataSource" class="colortable">
                <ng-container *ngFor="let column of displayedColumns; let i = index" matColumnDef="{{column}}">
                    <!--? TABLE HEADER -->
                    <th mat-header-cell *matHeaderCellDef class="table-head-txt">
                        <span *ngIf="i!=2">
                            {{'table.'+column | translate}} 
                        </span>
                        <span *ngIf="i==2">
                            {{'table.'+column | translate}} ({{'table.month' | translate}}) 
                        </span>
                    </th>

                    <td mat-cell 
                    *matCellDef="let element; let j=index"
                    class="table-data-txt">                 
                        <span  *ngIf="i==0 && link=='true'" (click)="seeLocker(element, j)">
                            {{element[column]}}
                        </span>
                        <span  *ngIf="i==1 && element.link_address" (click)="seeGrid(element, j)">
                            {{'table.'+element[column] | translate}}
                        </span>
                            <span  *ngIf="i==1 && !element.link_address && link=='true'" (click)="viewInMap(element, j)">
                            {{'table.'+element[column] | translate}}
                        </span>
        
                        <span  *ngIf="i==1 && link=='false'">
                            {{element[column]}}
                        </span>
        
                        <span *ngIf="column!='status' && i!=0 && i!=1">
                            {{element[column]}}
                        </span>
                        <span *ngIf="column=='status'" style="text-align: -webkit-center;">
                            <!-- {{'table.'+element[column].toLowerCase() |  translate}}    -->
                            <div [class]="element[column].toLowerCase() ==='active' ? 'pickup' : element[column].toLowerCase() ==='desactive' ? 'recolect' : 'delivery'" >
                                {{'table.'+element[column].toLowerCase() |  translate}}
                            </div>
        
                        </span>
        
        
        
        
                        <span *ngIf="column=='totalDeliveries'">
                            <span *ngIf="delivery[j]>=0" [ngStyle]="{'color': '#9EC57C'}">({{delivery[j]}}%)</span>
                            <span *ngIf="delivery[j]<0" [ngStyle]="{'color': 'red'}">({{delivery[j]}}%)</span>
                        </span>
                        <span *ngIf="column=='totalUnclaimed'">
                            <span *ngIf="unclaimed[j]>=0" [ngStyle]="{'color': '#9EC57C'}">({{unclaimed[j]}}%)</span>
                            <span *ngIf="unclaimed[j]<0" [ngStyle]="{'color': 'red'}">({{unclaimed[j]}}%)</span>
                        </span>
        
                        <span *ngIf="column=='select'">
                            <mat-radio-button value="element" (change)="radioChange($event, element)"></mat-radio-button>
                        </span>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr class="table-row {{coords?coords.lat==element.lat && coords.lng==element.lng?'focus':'unfocus':'unfocus'}}" mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
            </table>
                </div>
                <div class="float-end">
                    <!-- PAGINATION -->
                <pagination-controls (pageChange)="page = $event"
                class="my-pagination"
                nextLabel="Next"
                responsive="true">
                </pagination-controls> 
                </div>
          </div>
          
          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <!-- <img [src]="metricas.IMAGE.url_view_imagen_signature" [alt]=""> -->
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary">Save changes</button>
                </div>
              </div>
            </div>
          </div>

    </div>
  </div>

  