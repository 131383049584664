import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions } from 'ag-grid-community';
import { utils } from 'xlsx';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { Calendar } from 'primeng/calendar';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DateTextTablesComponent } from 'src/app/shared/components/date-text-tables/date-text-tables.component';
import { FilterService } from 'src/app/core/http/FilterDataServiceOxxoToOxxo.service';
import { AdminGuard } from 'src/app/core/guards/admin.guard';import { EstatusTableOrderAdminComponent } from '../../estatus-table-order-admin/estatus-table-order-admin.component';
import { DateTextComponent } from '../../date-text/date-text.component';
import { DialogOrderDetailComponent } from '../../dialog-order-detail/dialog-order-detail.component';
import { StarRatingComponent } from 'src/app/shared/components/star-rating/star-rating.component';
import { DialogDeliverPersonallyComponent } from '../../dialog-deliver-personally/dialog-deliver-personally.component';
interface Pagination {
    name: string;
    value: number;
}
@Component({
  selector: 'app-table-completed',
  templateUrl: './table-completed.component.html',
  styleUrls: ['./table-completed.component.scss']
})
export class TableCompletedComponent implements OnInit {
	public columnDefsCompleted: ColDef[];
	public defaultColDefExpired: ColDef = {
		sortable: true,
		filter: true,
		flex: 1
	};
	private gridApi;
	public rowDataExpired: any[];
	@ViewChild(AgGridAngular) agGrid!: AgGridAngular;
	@ViewChild('datepicker') datepicker: MatDatepicker<Date>;
	@ViewChild(EstatusTableOrderAdminComponent)
	estatusOrderAdminComponent: EstatusTableOrderAdminComponent;
	@ViewChild('calendar') calendar: Calendar;
	supportLanguages = ['es', 'en'];
	InitialDate = new Date();
	FinalDate = new Date();
	res;
	completedDataCompleted = [];
	countCompleted = 0;
	display = false;
	minDate: Date;
	maxDate: Date;
	orderSelectToExtendDate: any;
	filterText: string = '';
	gridOptions: GridOptions;
	dataTemporal = [];
	idLocker: string;
	filters:boolean=false;
	mode:string;
	idUserType = parseInt(localStorage.getItem('user_type'));
	id_user_type = parseInt(localStorage.getItem('user_type'));
	rowDataFilter;
	@Input() rowData;
	@Input() filteredData;
	@Input() rangeDates: Date[];
	@Output() rangeDatesChange = new EventEmitter<Date[]>();
	constructor(
		private translate: TranslateService,
		private adminService: AdminClientService,
		private toastr: ToastrService,
		private filterService	: FilterService,
		private elementRef		: ElementRef,
		private matdialog: MatDialog)
		{

	}
	obtenerIdUserType(){
		return parseInt(localStorage.getItem('user_type'));
	}
	ngOnInit() {
		this.mode = localStorage.getItem('mode');
		this.translate.use(localStorage.getItem('language').toLowerCase());
		this.updateTable();
		// this.addEventRangeDates();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.get([
				'table-general.order',
				'table-general.tracking',
				'table-general.lockerID',
				'table-general.email',
				'table-general.employeeName',
				'table-general.employeeID',
				'table-general.doorNumber',
				'table-general.status',
				'table-general.inLocker',
				'table-general.dateCollected',
				'table-general.rateStart',
			]).subscribe((t: any) => {
				const id_user_type = this.obtenerIdUserType();
				this.columnDefsCompleted = [
					{
						headerName: t['table-general.tracking'],
						field: 'tracking',
						sortable: true,
						width: 170,
						autoHeight: true,
						cellClass: (this.id_user_type !== 10) ? 'generalClassLink' : 'generalClass',
						cellRenderer: this.centeredCellRenderer,
						headerClass:'borderHeaderGeneral',
						onCellClicked: ($event) =>{
							if(this.id_user_type !== 10){
								this.openModalUserDetail($event);
							}
						}
					},
					{
						headerName: t['table-general.order'],
						field: 'order_number',
						sortable: true,
						width: 130,
						autoHeight: true,
						cellClass: 'generalClass',
						cellRenderer: this.centeredCellRenderer,
						headerClass:'borderHeaderGeneral',
						hide: id_user_type === 10 ? true : false,
					},
					{
						headerName: t['table-general.email'],
						field: 'email',
						sortable: true,
						width: 230,
						autoHeight: true,
						cellClass:'generalClass',
						cellRenderer: (params) => {
							const maxLength = 20; // Número máximo de caracteres antes de mostrar los puntos suspensivos
							const value = params.value;
							if (value.length > maxLength) {
								return value.slice(0, maxLength) + '...';
							}
							return value;
						},
						headerClass:'borderHeaderGeneral'
					},
					{
						headerName: t['table-general.doorNumber'],
						field: 'doorNumber',
						sortable: true,
						width: 130,
						autoHeight: true,
						cellClass:'generalClass',
						cellRenderer: this.centeredCellRenderer,
						headerClass:'borderHeaderGeneral'
					},
					{
						headerName: t['table-general.status'],
						field: 'status',
						sortable: true,
						width: 250,
						autoHeight: true,
						cellRenderer: EstatusTableOrderAdminComponent,
						headerClass:'borderHeaderGeneral',
						cellClass:'generalClass',
						onCellClicked: ($event) =>
							this.openCalendar($event),
					},
					{
						headerName: t['table-general.inLocker'],
						field: 'inLocker',
						sortable: true,
						width: 150,
						autoHeight: true,
						cellClass:'generalClass',
						cellRenderer: DateTextTablesComponent,
						headerClass:'borderHeaderGeneral'
					},
					{
						headerName: t['table-general.dateCollected'],
						field: 'dateCollected',
						sortable: true,
						width: 150,
						autoHeight: true,
						cellClass:'generalClass',
						cellRenderer: DateTextTablesComponent,
						headerClass:'borderHeaderGeneral'
					},
					{
						headerName: t['table-general.rateStart'],
						field: 'rate_start',
						sortable: true,
						width: 150,
						autoHeight: true,
						cellClass:'generalClass',
						cellRenderer: StarRatingComponent,
						headerClass:'borderHeaderGeneral'
					},
				];
			});
		});
		this.minDate = new Date();
		this.minDate.setDate(this.minDate.getDate() + 1); // día mínimo es mañana
		this.maxDate = new Date();
		this.maxDate.setDate(this.maxDate.getDate() + 2);




		let today = new Date();
		this.FinalDate = new Date();
		this.InitialDate = new Date(this.FinalDate);
		if((today.getDate()) === 31){
			this.InitialDate.setDate(0);
		}
		else{
			this.InitialDate.setMonth(this.InitialDate.getMonth() -1);
		}
		this.rangeDates = [this.InitialDate, this.FinalDate];
		this.idLocker = localStorage.getItem('id_locker');
	}
	ngOnChanges(changes: SimpleChanges): void {
		this.rowDataExpired = this.rowData.filter((item)=>{
			return (
				item.id_status_code_package === 18 &&
				item.status_package === 16
			);
		})
	}
	updateTable() {
		const id_user_type = this.obtenerIdUserType();
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant('table-general.tracking'),
					field: 'tracking',
					sortable: true,
					width: 170,
					autoHeight: true,
					cellClass: (this.id_user_type !== 10) ? 'generalClassLink' : 'generalClass',
					cellRenderer: this.centeredCellRenderer,
					headerClass:'borderHeaderGeneral',
					onCellClicked: ($event) =>{
						if(this.id_user_type !== 10){
							this.openModalUserDetail($event);
						}
					}
				},
				{
					headerName: this.translate.instant('table-general.order'),
					field: 'order_number',
					sortable: true,
					width: 130,
					autoHeight: true,
					cellClass: 'generalClass',
					cellRenderer: this.centeredCellRenderer,
					headerClass:'borderHeaderGeneral',
					hide: id_user_type === 10 ? true : false
				},
				{
					headerName: this.translate.instant('table-general.email'),
					field: 'email',
					sortable: true,
					width: 230,
					autoHeight: true,
					cellClass:'generalClass',
					headerClass:'borderHeaderGeneral',
					cellRenderer: (params) => {
						const maxLength = 20; // Número máximo de caracteres antes de mostrar los puntos suspensivos
						const value = params.value;
						if (value.length > maxLength) {
							return value.slice(0, maxLength) + '...';
						}
						return value;
					}
				},
				{
					headerName: this.translate.instant('table-general.doorNumber'),
					field: 'doorNumber',
					sortable: true,
					width: 130,
					autoHeight: true,
					cellClass:'generalClass',
					headerClass:'borderHeaderGeneral',
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-general.status'),
					field: 'status',
					sortable: true,
					width: 250,
					autoHeight: true,
					cellClass:'generalClass',
					cellRenderer: EstatusTableOrderAdminComponent,
					headerClass:'borderHeaderGeneral',
					onCellClicked: ($event) => this.openCalendar($event),
				},
				{
					headerName: this.translate.instant('table-general.inLocker'),
					field: 'inLocker',
					sortable: true,
					width: 150,
					autoHeight: true,
					cellClass:'generalClass',
					headerClass:'borderHeaderGeneral',
					cellRenderer: DateTextTablesComponent,
				},
				{
					headerName: this.translate.instant('table-general.dateCollected'),
					field: 'dateCollected',
					sortable: true,
					width: 150,
					autoHeight: true,
					cellClass:'generalClass',
					headerClass:'borderHeaderGeneral',
					cellRenderer: DateTextTablesComponent,
				},
				{
					headerName: this.translate.instant('table-general.rateStart'),
					field: 'rate_start',
					sortable: true,
					width: 150,
					autoHeight: true,
					headerClass:'borderHeaderGeneral',
					cellRenderer: StarRatingComponent,
					cellClass:'generalClass',
				}
			],
			animateRows: true,
			defaultColDef: {
				flex: 1,
			},
			pagination: true,
			paginationPageSize: 10,//this.selectedCity,
			paginationAutoPageSize: true,
			headerHeight:59,
			rowHeight:59,
			rowClass:'rowGeneralClass',
			scrollbarWidth:0,
			alwaysShowVerticalScroll:false,
			alwaysShowHorizontalScroll:false,
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`
		}
	}
	onGridReady(params: any) {
		this.gridApi = params;
	}
	onCellClicked(e: CellClickedEvent): void {
		if(AdminGuard){
			if (e.colDef.field === 'status') {
				this.display = true;
				this.orderSelectToExtendDate = e.data;
			}
		}
		else{
			this.display =false;
		}

	}
	addEventRangeDates() {
		this.rangeDatesChange.emit(this.rangeDates);
	}
	getTableCompleted() {

	}
	showDate: Date;
	showDateFinal: Date;
	fechasByMonth: string;

	DownloadExcel() {
		let headerExcel = [
			'package',
			'tracking',
			'lockerID',
			'email',
			'employeeID',
			'employeeName',
			'door',
			'doorSize',
			'status',
			'inLockerDate',
			'inLockerTime',
			'registrationDateExpired',
			'registrationTimeExpired',
		];
		let dataExcel = [];
		this.completedDataCompleted.forEach((e: any) => {
			let info = {
				package: e.order,
				tracking: e.tracking,
				lockerID: e.lockerID,
				email: e.email,
				employeeID: e.employeId,
				employeeName: e.nameEmploye,
				door: e.doors,
				doorSize: e.compartment,
				status: e.status,
				inLockerDate: e.inLocker.slice(0, 10),
				inLockerTime: e.inLocker.slice(11, 19),
				registrationDateExpired: e.registrationDateExpired.slice(0, 10),
				registrationTimeExpired: e.registrationDateExpired.slice(11,19),
			};
			dataExcel.push(info);
		});
		const worksheet = utils.json_to_sheet(dataExcel, {
			header: headerExcel,
		});
		const workbook = utils.book_new();
		utils.book_append_sheet(workbook, worksheet, 'Data');
	}

	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}
	openModalDeliverPersonally(event) {
		const dialogRef = this.matdialog.open(DialogDeliverPersonallyComponent,
			{
				width: '558px',
				height: '270px',
				data: event.data,
			}
		);
		dialogRef.afterClosed().subscribe((result) => {});
	}

	openModalUserDetail(event) {
		const dialogRef = this.matdialog.open(DialogOrderDetailComponent, {
			width: '1304px',
			height: '95%',
			data: event.data,
		});
		dialogRef.afterClosed().subscribe((result) => {});
	}

	async extenderFechas(calendar) {
		let dateSelect = new Date(calendar.value);
		let month =	dateSelect.getMonth().toString().length == 1 ? '0' + (dateSelect.getMonth() + 1) : dateSelect.getMonth() + 1;
		let day = dateSelect.getDate().toString().length == 1 ? '0' + dateSelect.getDate() : dateSelect.getDate();
		let d = dateSelect.getFullYear() + '-' + month + '-' + day;
		let dataSendUpdate = {
			language: localStorage.getItem('language').toString().toLowerCase(),
			id_company: parseInt(localStorage.getItem('id_company')),
			id_package_code: this.orderSelectToExtendDate.id_package_code,
			date_update: d,
		};
		let res: any = await this.adminService.updateExpirationDate(dataSendUpdate);
		if (res.mensaje_return) {
			this.display = false;
			this.toastr.success('la entrega santifactoriamente','Se ha extendindo',
				{
					toastClass: 'toast-extendida',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
				}
			);
		}
		this.getTableCompleted();
	}
	cerrarExtender() {
		this.display = false;
		this.orderSelectToExtendDate = [];
	}
	onFilterTextChange(event) {
		this.filterText = event.target.value;
		this.gridOptions.api.setQuickFilter(this.filterText);
		let api = this.gridOptions.api;
		let temporal = [];
		api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}
	onExport() {
		const dayInitial = this.InitialDate.getDate() < 10 ? '0' + this.InitialDate.getDate() : this.InitialDate.getDate();
		const monthInitial = this.InitialDate.getMonth() + 1 < 10 ? '0' + (this.InitialDate.getMonth() + 1)	: this.InitialDate.getMonth() + 1;
		const yearInitial = this.InitialDate.getFullYear();
		const dayFinal = this.FinalDate.getDate() < 10 ? '0' + this.FinalDate.getDate() : this.FinalDate.getDate();
		const monthFinal = this.FinalDate.getMonth() + 1 < 10 ? '0' + (this.FinalDate.getMonth() + 1) : this.FinalDate.getMonth() + 1;
		const yearFinal = this.FinalDate.getFullYear();
		const fileName = 'Expired'+dayInitial+monthInitial+yearInitial+'_'+dayFinal+monthFinal+yearFinal+'.xlsx';
		// Configuración para exportar en formato Excel
		const excelParams = {
			fileName: fileName,
			sheetName: 'Expired',
			columnWidths: [30, 20, 20, 20], // Ancho de las columnas en caracteres
		};
		// Obtener los datos de la tabla
		if (this.filteredData.length > 0) {
			const Data = this.filteredData || [];
			const excelData = Data.map((row) => {
				const { lockerID, id_package,id_package_code,id_status_code_package,status_package,qr_order,resultNotification,IMAGE,  ...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			// Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		}
		else if (this.rowData.length > 0 && this.filteredData.length === 0) {
			const Data = this.rowData || [];
			const excelData = Data.map((row) => {
				const { lockerID, id_package,id_package_code,id_status_code_package,status_package,qr_order,resultNotification,IMAGE,  ...rowDataWithoutFields} = row;
				return rowDataWithoutFields;
			});
			//   // Crear el libro de Excel y agregar la hoja con los datos
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(excelData);
			XLSX.utils.book_append_sheet(wb, ws, excelParams.sheetName);
			//   // Descargar el archivo de Excel
			XLSX.writeFile(wb, fileName);
		}
	}
	openCalendar(e: CellClickedEvent) {
		if (e.data.status_package === 14 &&	e.data.id_status_code_package === 32) {
			this.display = true;
			this.orderSelectToExtendDate = e.data;
		}
		else {
			this.display = false;
		}
	}
	showFilters(){
		this.filters = !this.filters;
		if (this.filters) {
			this.registerClickOutsideListener();
		}
		else {
			this.closeFilters();
		}
	}
	private registerClickOutsideListener() {
		document.addEventListener('click', this.clickOutsideHandler.bind(this));
	}
	private clickOutsideHandler(event: Event) {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.closeFilters();
		}
	}

	private closeFilters() {
		this.filters = false;
		document.removeEventListener('click', this.clickOutsideHandler.bind(this));
	}
}
