import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ReturnDataOrder } from 'src/app/core/models/carriers/module-carrier/carrier-mod.interface';

@Component({
	selector: 'app-estatus-table-carrier-order',
	templateUrl: './estatus-table-carrier-order.component.html',
	styleUrls: ['./estatus-table-carrier-order.component.scss']
})
export class EstatusTableCarrierOrderComponent implements OnInit, ICellRendererAngularComp {
	status_package: number;
	id_status_code_package: number;
	data:ReturnDataOrder;

	constructor() { }
	agInit(params: ICellRendererParams) {
		this.data = params.data;
		this.status_package = this.data.status_package;
		this.id_status_code_package = this.data.id_status_code_package;
	}
	refresh(params: ICellRendererParams) {
		return false;
	}

	ngOnInit(): void {
	}

}
