import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ApexNonAxisChartSeries, ApexPlotOptions, ApexChart, ApexLegend, ApexResponsive, ChartComponent, ApexStroke } from 'ng-apexcharts';
@Component({
	selector: 'app-headquarters',
	templateUrl: './headquarters.component.html',
	styleUrls: ['./headquarters.component.scss'],
})
export class HeadquartersComponent {
	@ViewChild('chartDonut') chart: ChartComponent;
	public chartOptions: Partial<ChartOptionsDonut>;
	totalSeries: number;
	tooltipHeadquarters: string;
	@Input() periodBase: string = '';
	@Input() periodComp: string = '';
	@Input() changeStyles: string = 'dates-default';
	@Input() dataBase = [];
	@Input() dataLabels = [];
	@Input() dataLegends = [];

	seriesData = [76, 67, 61, 100, 23];
	total = this.seriesData.reduce((acc, value) => acc + value, 0);
	seriesWithTotal = [...this.seriesData, this.total];

	constructor(private translate: TranslateService) {
	}

	ngOnInit() {
		this.loadDataChart();
		this.translate.setDefaultLang(localStorage.getItem('language').toLowerCase());
		this.tooltipHeadquarters = this.translate.instant('tooltip.sedes');
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
			this.tooltipHeadquarters = this.translate.instant('tooltip.sedes');
		})
	}

	ngOnChanges(changes: SimpleChanges) {
		this.loadDataChart();
	}

	private loadDataChart() {
		this.chartOptions = {
			chart: {
				height: 250,
				width:'100%',
				type: 'donut',
				toolbar: {
					show: true,
					tools:{
						download:`
						<div class="downloadClassFrequencyUse">
							<label class="textExport">
								${this.translate.instant('buttons.export')}
							</label>
							<div class="icon">
							</div>
						</div>
						`,
						pan:false,
						reset:false,
						zoom:false,
						zoomin:false,
						zoomout:false,
						selection:true
					},
				},
			},
			series: this.dataBase,
			plotOptions: {
				pie: {
					startAngle: -90,
					endAngle: 90,
					offsetY: 20,
					dataLabels: {
						offset: 9999999999,
					},
					donut: {
						labels: {
							show: true,
							total: {
								show: true,
								color: 'var(--color-primary)',
								fontSize: '14px',
								fontWeight: '500',
								fontFamily: 'Inter',
								showAlways: true,
								formatter: (w: any) =>
									w.globals.seriesTotals.reduce(
										(a: number, b: number) => a + b,
										0
									),
							},
							value: {
								show: true,
								color: 'var(--color-primary)',
								fontSize: '14px',
								fontWeight: '500',
								fontFamily: 'Inter',
								offsetY: -5,
							},
						},
					},
				},
			},
			colors: ['#8ac6e7', '#54abdc', '#0082cb', '#016299', '#003755'],
			labels: this.dataLabels.map((label) => 'OP-' + label.substr(9, 5)),
			legend: {
				show: false,
				floating: true,
				fontSize: 'var(--font-c3)',
				width: 200,
				position: 'bottom',
				fontFamily: 'var(--primary-font)',
				offsetY: -10,
				horizontalAlign: 'center',
				labels: {
					useSeriesColors: true,
				},
				itemMargin: {
					horizontal: 3,
				},
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						height: 150,
						width:'100%',
						type: 'donut',
						toolbar: {
							show: true,
							tools:{
								download:`
									<div class="downloadClassHeadquarters">
										<label class="textExport">
											Exportar
										</label>
										<div class="icon">
										</div>
									</div>
								`,
								pan:false,
								reset:false,
								zoom:false,
								zoomin:false,
								zoomout:false,
								selection:true
							},
						},
					},
				},
			],
			stroke: {
				show: true,
				width: 2,
				colors: ['var(--color-container)'],
			},
		};
		this.totalSeries = this.chartOptions.series.reduce((a, b) => a + b, 0);
	}
}

export type ChartOptionsDonut = {
	series: ApexNonAxisChartSeries;
	chart: ApexChart;
	labels: string[];
	colors: string[];
	legend: ApexLegend;
	plotOptions: ApexPlotOptions;
	responsive: ApexResponsive | ApexResponsive[];
	stroke: ApexStroke;
};
