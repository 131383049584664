import { Component, OnInit, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { EventSelectionService } from 'src/app/shared/services/event-selection.service';

@Component({
  selector: 'app-checkbox-selection-event-webhook',
  templateUrl: './checkbox-selection-event-webhook.component.html',
  styleUrls: ['./checkbox-selection-event-webhook.component.scss']
})
export class CheckboxSelectionEventWebhookComponent implements OnInit, ICellRendererAngularComp {
	checkbox;
	eventWebhookSelected;
	arrayFinal=[];
	selectedEvents: any[] = [];
	id_status_company_webhook_service:number;
	constructor(private eventSelectionService: EventSelectionService) { }

	agInit(params: ICellRendererParams): void {
		this.eventWebhookSelected = params;
		this.id_status_company_webhook_service = (params.value === 2 ? 0 : 1);
	}

	refresh(params: ICellRendererParams): boolean {
		return false;
	}

	ngOnInit(): void {
		this.eventSelectionService.selectedEvents$.subscribe((selectedEvents) => {
			this.selectedEvents = selectedEvents;
		});
	}

	showEventSelected(checkbox) {
		if(checkbox === 1){
			let obj={
				id_company_webhook_service: this.eventWebhookSelected.data.id_company_webhook_service,
				id_status_company_webhook_service: 1
			}
			this.selectedEvents.push(obj);
		}
		else if( checkbox === 0){
			let obj={
				id_company_webhook_service: this.eventWebhookSelected.data.id_company_webhook_service,
				id_status_company_webhook_service: 2
			}
			this.selectedEvents.push(obj);
		}
	}

}
