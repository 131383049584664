import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
	selector: 'app-star-rating-admin',
	templateUrl: './star-rating-admin.component.html',
	styleUrls: ['./star-rating-admin.component.scss'],
})
export class StarRatingAdminComponent implements OnInit {
	@Input() start;
	array_start: any = ['empty', 'empty', 'empty', 'empty', 'empty'];

	constructor() {}

	ngOnInit(): void {
		for (var a = 0; a < 5; a++) {
			if (a <= this.start - 1) this.array_start[a] = 'start.png';
			else this.array_start[a] = 'empty_start.png';
		}
	}
}
