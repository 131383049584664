<div class="row">
    <div class="col-6">
		<div class="input-container">
			<input
				[(ngModel)]="filterText"
				type="text"
				class="input-gbl"
				(keyup)="onFilterTextChange($event)"
				placeholder="{{'dashboard.search_user' | translate}}"/>
			<button
				type="submit"
				class="btn-search">
				<div class="icon-search"></div>
			</button>
		</div>
    </div>
    <div class="col-6 buttons-and-ranges">
		<p-calendar class="selectYear"
                    [showIcon]="true"
                    [(ngModel)]="rangeDates"
                    (ngModelChange)="addEventRangeDates()"
                    selectionMode="range"
                    [readonlyInput]="true"
                    inputId="range" >
        </p-calendar>
		<app-button-icon
			*ngIf="this.mode ==='light'"
			[activo]="false"
			btnNombre="Filtros"
			btnType="secondaryMedium"
			icon="icon-funnel"
			(click)="showFilters()">
		</app-button-icon>
		<app-button-icon
			*ngIf="this.mode ==='dark'"
			[activo]="false"
			btnNombre="Filtros"
			btnType="secondaryMedium"
			icon="icon-funnel-dark"
			(click)="showFilters()">
		</app-button-icon>
        <button-export (click)="onExport()"></button-export>
    </div>

	<div class="col-12">
		<ag-grid-angular
			#agGrid
			class="ag-theme-alpine"
			[gridOptions]="this.gridOptions"
			[rowData]="this.filteredData"
			[columnDefs]="this.columnDefs"
			[quickFilterText]="this.filterText"
			style="width: 100%; height: 740px; border: none; margin-top:24px;"
			(gridReady)="onGridReady($event)">
		</ag-grid-angular>
	</div>
</div>
<!-- !CALENDARIO DE EXTENDER FECHAS -->
<div *ngIf="display" class="extendCard">
    <div class="row">
        <div class="col-12">
            <p-calendar
				#calendar
				[inline]="true"
				[minDate]="minDate"
				[maxDate]="maxDate">
			</p-calendar>
        </div>
        <div class="col-6" style="text-align: start;">
			<app-button
				btnNombre="general.cancel"
				btnType="secondaryMedium"
				(click)="cerrarExtender()">
			</app-button>
        </div>
        <div class="col-6" style="text-align: end;">
			<app-button
				*ngIf="calendar.value === undefined"
				[activo]="true"
				btnNombre="table.extend"
				btnType="primaryMedium"
				(click)="extenderFechas(calendar)">
			</app-button>
			<app-button
				*ngIf="calendar.value !== undefined"
				[activo]="false"
				btnNombre="table.extend"
				btnType="primaryMedium"
				(click)="extenderFechas(calendar)">
			</app-button>
        </div>
    </div>
</div>



<app-filter-orders [filters]="this.filters" [status]="true" [rate]="true">

</app-filter-orders>
