import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-dialog-image-locker',
	templateUrl: './dialog-image-locker.component.html',
	styleUrls: ['./dialog-image-locker.component.scss']
})
export class DialogImageLockerComponent implements OnInit,OnDestroy {
	img_src:string;
	lockerName= localStorage.getItem('locker_name');
	url_view_image=true;

	constructor(
		private dialogRef: MatDialogRef<DialogImageLockerComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialog: MatDialog
	){
		this.img_src = data.image;
	}

	ngOnInit(): void {
	}
	ngOnDestroy(): void {
		this.dialogRef.close();
	}

	closeModal() {
		this.dialogRef.close();
	}

}
