<div class="row container-graphic">
    <!-- HEADER -->
    <div class="row header-graphic">
        <div class="col-9">
            <div class="header-txt">
                Paquetes recolectados y devueltos por día
            </div>
        </div>

        <!-- SELECT -->
        <div class="col float-end">
            <select class="select-custome float-end" aria-label=".form-select-lg example">
                <option selected  class="option-custome text-center">2023</option>
                <option value="11" class="option-custome text-center">2022</option>
              </select>
        </div>
    </div>

    <!-- CHART -->
    <div id="chart">
        <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [dataLabels]="chartOptions.dataLabels"
            [plotOptions]="chartOptions.plotOptions"
            [yaxis]="chartOptions.yaxis"
            [legend]="chartOptions.legend"
            [fill]="chartOptions.fill"
            [stroke]="chartOptions.stroke"
            [tooltip]="chartOptions.tooltip"
            [xaxis]="chartOptions.xaxis">
        </apx-chart>
    </div>

    <!-- FOOTER -->
    <div class="col text-center mt-custome" >     
        <!--? COLLECT -->
        <div class="d-inlane-table container-foot square-1"></div>
        <!-- TXT -->
        <p class="d-inlane-table container-foot">
            Recolectados
        </p>  

        <!--? RETURN -->
        <div class="d-inlane-table container-foot square-2"></div>
        <!-- TXT -->
        <p class="d-inlane-table container-foot">
            Devueltos
        </p>  
    </div>
</div>