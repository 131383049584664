import { Component, Input, OnInit } from '@angular/core';
import {
	ApexChart,
	ApexDataLabels,
	ApexFill,
	ApexNonAxisChartSeries,
	ApexPlotOptions,
	ApexStroke,
} from 'ng-apexcharts';

export type ChartOptions = {
	series: ApexNonAxisChartSeries;
	chart: ApexChart;
	labels: string[];
	plotOptions: ApexPlotOptions;
	dataLabels: ApexDataLabels;
	fill: ApexFill;
	stroke: ApexStroke;
};

@Component({
	selector: 'app-historical-cards-admin',
	templateUrl: './historical-cards-admin.component.html',
	styleUrls: ['./historical-cards-admin.component.scss'],
})
export class HistoricalCardsAdminComponent implements OnInit {
	@Input() metrics!: any[];
	@Input() percent_up!: any[];
	@Input() series!: any[];
	@Input() chart!: any[];
	@Input() plotOptions!: any[];
	@Input() labels!: any[];
	@Input() stroke!: any[];
	@Input() fill!: any[];

	ngOnInit() {}
}
