<!--? KPI'S -->
<div class="row">
    
    <!--? KPI 1 -->
    <div class="col-2 container-card-door">
        <div class="row">
            <!--HEADER -->
            <div class="col-12 height-card-header">
                <p class="font-header-card-success float-end">
                    Disponibles 
                </p>
            </div>
            <!-- BODY -->
            <div class="col-12 height-card text-start">
                <!-- KPI -->
                <strong class="font-card">
                    3 <!--! Falta KPI -->
                </strong>
            </div>
            <!-- FOOTER -->
            <div class="col-12 height-info-card text-start">
                <small class="font-footer-card"> {{ 'Puertas chicas' | translate }} </small>
            </div>
        </div>
    </div>

    <!--? KPI 2 -->
    <div class="col-2 container-card-door">
        <div class="row">
            <!--HEADER -->
            <div class="col-12 height-card-header">
                <p class="font-header-card-success float-end">
                    Disponibles 
                </p>
            </div>
            <!-- BODY -->
            <div class="col-12 height-card text-start">
                <!-- KPI -->
                <strong class="font-card">
                    8 <!--! Falta KPI -->
                </strong>
            </div>
            <!-- FOOTER -->
            <div class="col-12 height-info-card text-start">
                <small class="font-footer-card"> {{ 'Puertas medianas' | translate }} </small>
            </div>
        </div>
    </div>

    <!--? KPI 3 -->
    <div class="col-2 container-card-door">
        <div class="row">
            <!--HEADER -->
            <div class="col-12 height-card-header">
                <p class="font-header-card-success float-end">
                    Disponibles 
                </p>
            </div>
            <!-- BODY -->
            <div class="col-12 height-card text-start">
                <!-- KPI -->
                <strong class="font-card">
                    4 <!--! Falta KPI -->
                </strong>
            </div>
            <!-- FOOTER -->
            <div class="col-12 height-info-card text-start">
                <small class="font-footer-card"> {{ 'Puertas grandes' | translate }} </small>
            </div>
        </div>
    </div>

    <!--? KPI 4 -->
    <div class="col-2 container-card-door">
        <div class="row">
            <!--HEADER -->
            <div class="col-12 height-card-header">
                <p class="font-header-card-success float-end">
                    Disponibles 
                </p>
            </div>
            <!-- BODY -->
            <div class="col-12 height-card text-start">
                <!-- KPI -->
                <strong class="font-card">
                    2 <!--! Falta KPI -->
                </strong>
            </div>
            <!-- FOOTER -->
            <div class="col-12 height-info-card text-start">
                <small class="font-footer-card"> {{ 'Puertas extra grandes' | translate }} </small>
            </div>
        </div>
    </div>

    <!--? KPI 5 -->
    <div class="col-2 container-card-door-xlg">
        <div class="row">
            <!--HEADER -->
            <div class="col-12 height-card-header-xl">
                <p class="span-card-door-1 text-end">
                    Registro histórico   
                </p>
            </div>
            <!-- BODY -->
            <div class="col-12 height-card text-start">
                <!-- KPI -->
                <strong class="font-card">
                    1:33 min <!--! Falta KPI -->
                </strong>
            </div>
            <!-- FOOTER -->
            <div class="col-12 height-info-card text-start">
                <small class="font-footer-card"> {{ 'Puertas extra grandes' | translate }} </small>
            </div>
        </div>
    </div>

    <!--? KPI 6 -->
    <div class="col-2 container-card-door-lg position-relative">
        <div class="row d-flex justify-content-center">
            <div class="row">
                <!--HEADER -->
                <div class="col height-card-header-charter">
                    <p class="span-card-door-2 float-start">
                        Uso del locker
                    </p>
                    <select class="select-custome float-end" aria-label=".form-select-lg example">
                        <option selected  class="option-custome text-center">1 mes</option>
                    </select>
                </div>
            </div>
            <!-- TOTAL CHART -->
            <div class="text-center total">
                <span class="txt-use-locker" >Total</span>
                <h4 class="txt-use-locker" > {{totalSeries}} </h4>
            </div>

            <!-- CHART -->
            <div id="chart">
                <apx-chart
                    [series]="chartOptions.series"
                    [chart]="chartOptions.chart"
                    [plotOptions]="chartOptions.plotOptions"
                    [labels]="chartOptions.labels"
                    [legend]="chartOptions.legend"
                    [colors]="chartOptions.colors"
                    [responsive]="chartOptions.responsive">
                </apx-chart>
            </div>                  

             <!-- FOOTER -->
            <div class="col text-center mt-custome" >     
                <!--? DELIVERED -->
                <div class="d-inlane-table container-foot square-1"></div>
                <!-- TXT -->
                <p class="d-inlane-table container-foot">
                    Entregados
                </p>  

                <!--? COLLECTED -->
                <div class="d-inlane-table container-foot square-2"></div>
                <!-- TXT -->
                <p class="d-inlane-table container-foot">
                    Retirados
                </p>  

                <!--? RETURNED -->
                <div class="d-inlane-table container-foot square-3"></div>
                <!-- TXT -->
                <p class="d-inlane-table container-foot">
                    Devueltos
                </p>  
            </div>

        </div>

    </div>

</div>