<div class="row container-graphic">

    <div class="row header-graphic">
        <!-- HEADER -->
        <div class="col-9">
            <div class="header-txt">
                Histórico de paquetes
            </div>
        </div>

        <!-- SELECT -->
        <div class="col">
            <select class="select-custome float-end" aria-label=".form-select-lg example">
                <option selected  class="option-custome text-center">2023</option>
                <option value="11" class="option-custome text-center">2022</option>
            </select>
        </div>
    </div>

    <!-- CHART -->
    <div class="col" id="chart">
        <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [xaxis]="chartOptions.xaxis"
          [stroke]="chartOptions.stroke"
          [colors]="chartOptions.colors"
          [dataLabels]="chartOptions.dataLabels"
          [legend]="chartOptions.legend"
          [markers]="chartOptions.markers"
          [grid]="chartOptions.grid"
          [yaxis]="chartOptions.yaxis"
        ></apx-chart>
    </div>

    
    <!-- FOOTER -->
    <div class="col text-center mt-custome" >     
        <!--? PUEBLA LINE -->
        <div class="d-inlane-table container-foot square-1"></div>
        <!-- TXT -->
        <p class="d-inlane-table container-foot">
            Entregados
        </p>  

        <!--? QUERETARO LINE -->
        <div class="d-inlane-table container-foot square-2"></div>
        <!-- TXT -->
        <p class="d-inlane-table container-foot">
            Retirados
        </p>  

        <!--? QUERETARO LINE -->
        <div class="d-inlane-table container-foot square-3"></div>
        <!-- TXT -->
        <p class="d-inlane-table container-foot">
            Devueltos
        </p>  
    </div>
</div>