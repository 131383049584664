<div class="container-main">
	<!-- GRID HEADER -->
	<div class="grid-header">
		<!-- TITLE CONTAINER -->
		<div class="span-txt">
			<!-- TITLE -->
			<span class="txt-title">
				{{'overview.cardsOverview.useLockersByOperation' | translate}}
			</span>
			<!-- ICON INFO -->
			<div class="icon-info">
				<!-- TOOLTIP -->
				<app-tooltip-center-sm class="tooltip-span" [tooltip]="tooltipUseLocker">
				</app-tooltip-center-sm>
			</div>

		</div>
		<!-- BTN EXPORTAR -->
		<!-- <dropdown-export></dropdown-export> -->
	</div>

	<div class="chart" id="chart">
		<apx-chart
			[series]="dataChartUseLockerOperation.series"
			[chart]="dataChartUseLockerOperation.chart"
			[dataLabels]="dataChartUseLockerOperation.dataLabels"
			[plotOptions]="dataChartUseLockerOperation.plotOptions"
			[responsive]="dataChartUseLockerOperation.responsive"
			[xaxis]="dataChartUseLockerOperation.xaxis"
			[legend]="dataChartUseLockerOperation.legend"
			[fill]="dataChartUseLockerOperation.fill"
			[stroke]="dataChartUseLockerOperation.stroke">
		</apx-chart>

		<div class="dates-change">
			<!-- BASE DATE -->
			<div class="span-date" *ngIf="periodBase.length === 2">
				{{periodBase[0] | date:'MMMM dd, yyyy' }} - {{periodBase[1] | date:'MMMM dd, yyyy' }}
			</div>
			<!-- COMPARE DATE -->
			<div class="span-date" *ngIf="periodComp.length === 2">
				{{periodComp[0] | date:'MMMM dd, yyyy'}} - {{periodComp[1] | date:'MMMM dd, yyyy'}}
			</div>
		</div>
	</div>

	<!-- LEGEND -->
	<div class="container-legend">
		<div class="align-text">
			<span><div class="circle-1"></div> {{'overview.orderDelivered' | translate}}</span>
			<span><div class="circle-2"></div> {{'overview.orderRecolected' | translate}}</span>
		</div>
	</div>

</div>
