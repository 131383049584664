import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AdminClientService } from 'src/app/core/http/admin-client.service';

@Component({
	selector: 'app-client-profile-admin',
	templateUrl: './client-profile-admin.component.html',
	styleUrls: ['./client-profile-admin.component.scss'],
})
export class ClientProfileAdminComponent implements OnInit {
	password = true;
	type = 'password';
	fname;
	sname;
	email;
	company;
	pass;
	cPass;
	address;
	lang: string;
	data;
	userType;
	id_company;
	id_user;
	msg;
	selected_leng;
	class_err = '';
	constructor(
		private translate: TranslateService,
		private adminService: AdminClientService,
		private toastr: ToastrService
	) {}

	ngOnInit() {
		this.data = JSON.parse(localStorage.getItem('data'));
		this.lang = this.data.result.acronym_language;
		this.email = this.data.data_company.email;
		this.company = this.data.data_company.company_name;
		this.id_user = this.data.data_company.id_user;
		this.id_company = this.data.data_company.id_company;
		this.fname = this.data.data_company.first_name;
		this.sname = this.data.data_company.surname;
		this.userType = this.data.data_company.user_type;
		this.address = this.data.data_company.address;
	}

	hidePassword() {
		this.password = !this.password;
		this.type = this.password ? 'password' : 'text';
	}

	submit() {
		this.update_user();
	}

	update_user() {
		let data = {
			id_language: this.lang == 'ES' ? 1 : 2,
			first_name: this.fname,
			surname: this.sname,
			password: this.pass,
			company: this.id_company,
			id_user: this.id_user,
			userType: this.userType,
		};
		this.adminService.update_update_data_user(data).subscribe((res) => {
			if (!res.mensaje_return.ERROR) {
				this.class_err = 'exito';
				this.data.data_company.id_language = this.lang == 'ES' ? 1 : 2;
				this.data.result.id_language = this.lang == 'ES' ? 1 : 2;
				this.data.result.acronym_language = this.lang;
				this.data.result.first_name = this.fname;
				this.data.result.surname = this.sname;
				localStorage.setItem('language', this.lang);
				localStorage.setItem('data', JSON.stringify(this.data));
				this.translate.setDefaultLang(this.lang.toLowerCase());
				this.toastr.success(
					'La operación ha sido realizada con éxito.',
					'Perfil Actualizado',
					{
						toastClass: 'toast-exitosa',
						timeOut: 5000,
						positionClass: 'toast-bottom-center',
					}
				);
			} else {
				this.class_err = 'fail';
				this.msg = {
					type_msg: 'error',
					text_msg: res.mensaje_return.ERROR_MENSAGGE,
				};
				this.toastr.success(
					'La operación ha sido realizada con éxito.',
					'Perfil Actualizado',
					{
						toastClass: 'toast-erronea',
						timeOut: 5000,
						positionClass: 'toast-bottom-center',
					}
				);
			}
		});
	}
}
