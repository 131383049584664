<!-- CARDS ITERATION -->
<div class="{{gridStyle}}">

    <!--? CARDS OVERVIEW ------------------>
    <div *ngFor="let kpi of dataCardSm">
        <div class="card-style">
            <div class="card-grid">
                <!--  HEADER -->
                <div class="card-header-kpi">
                    <span class="span-header">{{kpi.header | translate}}</span>
                    <div class="icon-info" *ngIf="this.showTooltip === true">
                        <app-tooltip-center-sm
                            *ngIf="this.tooltip === 'sm-center'"
                            class="tooltip-span"
                            [tooltip]="kpi">
                        </app-tooltip-center-sm>
                        <app-tooltip-end-sm
                            *ngIf="this.tooltip === 'sm-end'"
                            class="tooltip-span"
                            [tooltip]="kpi.toolTip">
                        </app-tooltip-end-sm>
                    </div>
                </div>
                <!-- BODY -->
                <div class="card-body-kpi">
                    <span class="span-body">{{kpi.data}}</span>
                    <!-- FOOTER -->
                    <div class="card-footer-kpi" style="height: 24px;" *ngIf="this.addComp === true">
                        <div class="icon-success" [style]="kpi.disable" *ngIf="kpi.percent > 0"></div>
						<div class="icon-danger" [style]="kpi.disable" *ngIf="kpi.percent < 0"></div>
                        <span class="span-success" [style]="kpi.disable" *ngIf="kpi.percent > 0"> {{kpi.percent}} % </span>
						<span class="span-danger" [style]="kpi.disable" *ngIf="kpi.percent < 0"> {{kpi.percent}} % </span>
                        <span class="span-footer">
							{{kpi.footer | translate}}
							{{kpi.footerData}}
						</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--? CARDS LOCKER ID ------------------>
    <div *ngFor="let kpi of dataCardSummary; let i = index">
        <div class="card-style">
            <div class="card-grid">
                <!--  HEADER -->
                <div class="card-header-kpi">
                    <span class="span-header">{{kpi.size}}</span>
                    <div class="icon-info" (mouseover)="activateTooltip(i)" (mouseleave)="desactivateToolTip(i)">
                        <div class="bottom-toggle"></div>
						<app-tooltip-collection [idCard]="idCard" [tooltip]="dataCardSummary[idCard]" *ngIf="showTooltip === true && idCard === i">
						</app-tooltip-collection>
						<!-- <app-tooltip-center-sm *ngIf="this.tooltip === 'sm-center'" class="tooltip-span" [tooltip]="kpi.toolTip">
						</app-tooltip-center-sm> -->
                    </div>
                </div>
                <!-- BODY -->
                <div class="card-body-kpi">
                    <span class="span-body">{{kpi.data}}</span>
                    <!-- FOOTER -->
                    <div class="card-footer-kpi">
                        <span class="span-success">{{'Available'}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

