import { Component, Input, OnInit } from '@angular/core';
import { AdminClientService } from '../../../../core/http/admin-client.service';

@Component({
	selector: 'app-monthly-admin',
	templateUrl: './monthly-admin.component.html',
	styleUrls: ['./monthly-admin.component.scss'],
})
export class MonthlyAdminComponent implements OnInit {
	@Input() dataForMonthly!: any[];
	metrics: any;
	dataChart;
	dataMonth = [];
	lang;
	months;
	month = [];
	monthsArrayEN: any = [
		'January',
		'February',
		'Marzo',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'Diciembre',
	];
	monthsArrayES: any = [
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre',
	];
	constructor(private adminService: AdminClientService) {}
	ngOnInit(): void {
		this.getMetrics();
	}
	async getMetrics() {
		this.metrics = await this.adminService.getMetrics(this.dataForMonthly);
		this.metrics[2][1].forEach((e: any) => {
			this.dataMonth.push(e.cant);
			if (this.lang == 'EN') {
				this.month.push(this.monthsArrayEN[e.IdMes - 1]);
			} else {
				this.month.push(this.monthsArrayES[e.IdMes - 1]);
			}
		});
		this.dataChart = JSON.stringify(this.dataMonth);
		this.months = this.month.join(',');
	}
}
