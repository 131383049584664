import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

	@Input() btnNombre : string;
	@Input() btnType   : string;
	@Input() activo    : string;

	constructor(private translate: TranslateService) {

	}

	ngOnInit(): void {
	}

}
