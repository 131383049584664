<div class="grid-cards">
    <div class="card-1">
        <div class="header-span">
            <span>Promedio de recolección</span>
            <div class="icon-info">
                <!-- TOOLTIP -->
                <app-tooltip
                    class="tooltip-span"
                    [tooltip]="tooltipDelivery">
                </app-tooltip>
            </div>
        </div>
        <div class="footer-span">
            <span class="hour">{{registroHistorico}}min</span>
            <span class="txt-footer">Registro historido</span>
        </div>
    </div>

    <div class="card-2">
        <!-- CHART CONTAINER -->
        <div class="chart-position">
            <!--? HEADER -->
            <div class="header-span">
                <!-- TITLE -->
                <span>Uso total</span>
                <!-- ICON INFO -->
                <div class="icon-info">
                    <!-- TOOLTIP -->
                    <app-tooltip
                        class="tooltip-span"
                        [tooltip]="tooltipChart">
                    </app-tooltip>

                </div>

            </div>

            <!--? TOTAL CHART -->
            <div class="total">
                <span class="txt-use-locker" >Total</span>
                <h4 class="txt-use-locker" > {{totalSeries}} </h4>
            </div>

            <!--? CHART -->
            <div class="chart-container">
                <div id="chart">
                    <apx-chart
                        [series]="chartOptions.series"
                        [chart]="chartOptions.chart"
                        [plotOptions]="chartOptions.plotOptions"
                        [labels]="chartOptions.labels"
                        [legend]="chartOptions.legend"
                        [colors]="chartOptions.colors"
                        [responsive]="chartOptions.responsive">
                    </apx-chart>
                </div>
            </div>


            <!--? LEGENDS -->
            <!-- DELIVERY -->
            <div class="legend-container">
                <div class="legend-1">
                    <div class="box-1"></div>
                    <span class="span-box">Entregados</span>
                </div>
            </div>
            <!-- RETURN WITHDRWN -->
            <div class="legend-container">
                <div class="legend-1">
                    <div class="box-2"></div>
                    <span class="span-box">Retirados</span>

                    <div class="box-3"></div>
                    <span class="span-box">Devueltos</span>
                </div>
            </div>

        </div>

    </div>

</div>
