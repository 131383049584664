<div *ngIf="this.status_package === 14 && this.id_status_code_package === 17">
	<div class="inlocker">
		{{'estatus.LOCKER'|translate}}
	</div>
</div>

<div *ngIf="this.status_package === 14 && this.id_status_code_package === 32">
	<div class="expired">
		{{'estatus.EXTENDIDO'|translate}}
	</div>
</div>

<div *ngIf="this.status_package === 33 && this.id_status_code_package === 32 ">
	<div class="refund">
		{{'estatus.RECOLECTADO'|translate}}
	</div>
</div>
<div *ngIf="this.status_package === 16 && this.id_status_code_package === 18">
		<div class="collected">
			{{'estatus.ENTREGADO'|translate}}
		</div>
</div>
<div *ngIf="this.status_package === undefined && this.id_status_code_package ===undefined">
	<div class="scheduled">
		{{'estatus.SCHEDULED'|translate}}
	</div>
</div>
