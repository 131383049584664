<div class="scheduled">
	<div class="row">
		<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="display: flex;justify-content: space-between;">
			<div class="input-container">
				<input
				[(ngModel)]="filterText"
				type="text"
				class="input-gbl"
				(keyup)="onFilterTextChange($event)"
				placeholder="{{'dashboard.search_user' | translate}}"/>
				<button type="submit" class="btn-search">
					<div class="icon-search">
					</div>
				</button>
			</div>
			<button-export (click)="onExport()"></button-export>
		</div>
		<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
			<ag-grid-angular
				#agGrid
				class="ag-theme-alpine"
				[gridOptions]="gridOptions"
				[rowData]="rowData"
				suppressScrollOnNewData = true
				[columnDefs]="this.columnDefsGeneralOTO"
				style="width: 100%; height: 714px; border: none; margin-top:24px"
				[quickFilterText]="this.filterText"
				(gridReady)="onGridReady($event)">
			</ag-grid-angular>
		</div>
	</div>
</div>

