import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemService {
	public baseUrl: string = environment.url_server_backend;
	private token: string;
	private options: any;
	public temp: any;
	public data;

	constructor(private http: HttpClient) { }

	setToken(token: string) {
		this.token = token;
	}
	getToken() {
		return this.token ? this.token : localStorage.getItem('token');
	}

	async queryCourierApiDocumentation(params){
		let options = {
			headers: {
				'Content-Type': 'application/json',
				token: this.getToken()
			},
			params: params
		}
		let url = this.baseUrl +'/server/system/query-courier-api-documentation';
		return await this.http.get(url,options).toPromise();
	}
}
