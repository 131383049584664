import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
	name: 'dateOriginal',
})
export class DateOriginalPipe implements PipeTransform {
	constructor(private datePipe: DatePipe) {}

	transform(value: any, args?: any): any {
		// Formato deseado: dd/MMM/yyyy
		return this.datePipe.transform(value, 'dd/MMM/yyyy');
	}
}
