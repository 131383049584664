
<div *ngIf="this.status_package === 14 && this.id_status_code_package === 32">
    <div class="expired">
      Expirado
    </div>
</div>

<div *ngIf="this.estatus === 'ACTIVE'">
  <div class="active">
    {{'estatus.ACTIVE'|translate}}
  </div>
</div>

<div *ngIf=" this.estatus === 'DESACTIVE'">
  <div class="desactive">
    {{'estatus.DESACTIVE'|translate}}
  </div>
</div>

<div *ngIf="this.status_package === 14 && this.id_status_code_package === 17 && this.estatus === 'DISPONIBLE' && this.extend_package ===0">
  <div class="inlocker">
    {{'estatus.LOCKER'|translate}}
  </div>
</div>

<div *ngIf="status_package === 14 && id_status_code_package === 17 && this.estatus === 'DISPONIBLE' && extend_package ===1">
	<div class="expired">
		{{'estatus.EXTENDED'|translate}}
	</div>
</div>

<div *ngIf="this.status_package === 33 && this.id_status_code_package === 32 && this.estatus === 'RECOLECTADO'">
  <div class="refund">
    {{'estatus.RECOLECTADO'|translate}}
  </div>
</div>

<div *ngIf="this.status_package === 16 && this.id_status_code_package === 18 && this.estatus === 'ENTREGADO'">
  <div class="collected">
    {{'estatus.ENTREGADO'|translate}}
  </div>
</div>

<div *ngIf="this.status_package === undefined && this.id_status_code_package ===undefined && this.estatus === 'SCHEDULED'">
  <div class="scheduled">
    {{'estatus.SCHEDULED'|translate}}
  </div>
</div>

<div *ngIf="(status_package === 10 && id_status_code_package === 10) || (status_package === 20 && id_status_code_package === 18) || (status_package === 30 && id_status_code_package === 17) || (status_package === 20 && id_status_code_package === 17)">
	<div class="canceled">
		{{'estatus.CANCELED'|translate}}
	</div>
</div>
