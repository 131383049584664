<!--? CONTAINER MAIN  -->
<div class="container-main">
    <input type="checkbox" class="collapse_expand_calendar" >
    <!-- INPUT DATE -->
    <input type="text" class="input-gbl" placeholder="{{currentDate | date:'MM/dd/yyyy'}}">

    <!--? BTN CALENDAR -->
    <button type="submit" class="btn-calendar">
        <!--? ICON CALENDAR -->
        <div class="icon-calendar">
        <!-- INPUT CHECKBOX -->

            <div class="calendar">
                <div class="header-calendar">
                    <div class="icon icon-chevron-left" (click)="prevMonth()"></div>

                    <span class="month">
                        <span>{{currentMonth}}</span>

                        <span>{{currentYear}}</span>

                    </span>

                    <div class="icon icon-chevron-right" (click)="nextMonth()"></div>

                </div>

                <!--? DAYS OF WEEK -->
                <ol>
                    <li *ngFor="let day of daysWeek" class="day-week">
                        {{day.slice(0,3)}}
                    </li>

                </ol>

                <!--? DAYS OF MONTH -->
                <ol>
                    <li class="day"
                        [style.grid-column-start]="i === 0 ? firstDay + 1: ''"
                        *ngFor="let day of daysInMonth; let i = index"
                        (click)="selectDay(day)">
                        {{ day | date:'d' }}
                    </li>

                </ol>

            </div>

        </div>

    </button>

</div>

<!-- SM -->
<button type="submit" class="btn-calendar-sm">
    <div class="icon-calendar-sm"></div>


</button>
