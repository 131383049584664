import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexChart,
	ApexXAxis,
	ApexDataLabels,
	ApexStroke,
	ApexMarkers,
	ApexYAxis,
	ApexGrid,
	ApexTitleSubtitle,
	ApexLegend,
	ApexFill,
	ApexOptions,
	ApexResponsive,
} from 'ng-apexcharts';

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	stroke: ApexStroke;
	dataLabels: ApexDataLabels;
	markers: ApexMarkers;
	tooltip: any; // ApexTooltip;
	yaxis: ApexYAxis;
	grid: ApexGrid;
	legend: ApexLegend;
	title: ApexTitleSubtitle;
	fill: ApexFill;
	export: ApexOptions;
	responsive: ApexResponsive[];
};

@Component({
	selector: 'app-historical-orders',
	templateUrl: './historical-orders.component.html',
	styleUrls: ['./historical-orders.component.scss'],
})
export class HistoricalOrdersComponent implements OnInit, OnChanges{
	@ViewChild('chart') chart: ChartComponent;
	@Input() dataDeliver: number[] = [];
	@Input() dataRecolected: number[] = [];
	@Input() dataExpired: number[] = [];
	public chartOptions: Partial<ChartOptions>;

	tooltipHistorical: string;

	constructor(private translate: TranslateService) {

	}

	ngOnInit() {
		this.loadDataChart();
		this.translate.setDefaultLang(localStorage.getItem('language').toLowerCase());
		this.tooltipHistorical = this.translate.instant('tooltip.historical');
		this.translate.onLangChange.subscribe((event: LangChangeEvent)=>{
			this.translate.use(event.lang);
			this.loadDataChart();
			this.tooltipHistorical = this.translate.instant('tooltip.historical');
		})
	}

	ngOnChanges() {
		this.loadDataChart();
	}

	private loadDataChart() {
		this.chartOptions = {
			series: [
				{
					name: this.translate.instant('overview.orderDelivered'),
					data: this.dataDeliver,
				},
				{
					name: this.translate.instant('overview.orderRecolected'),
					data: this.dataRecolected,
				},
				{
					name: this.translate.instant('overview.orderExpired'),
					data: this.dataExpired,
				},
			],
			chart: {
				dropShadow: {
					enabled: false,
					enabledOnSeries: undefined,
					top: 0,
					left: 0,
					blur: 3,
					color: '#000',
					opacity: 0.35,
				},
				height: 180,
				type: 'line',
				stacked: true,
				toolbar: {
					show: true,
					tools:{
						download:`
							<div class="downloadClassHistoricalOrders">
								<label class="textExport">
									${this.translate.instant('buttons.export')}
								</label>
								<div class="icon">
								</div>
							</div>
						`,
						pan:false,
						reset:false,
						zoom:false,
						zoomin:false,
						zoomout:false,
						selection:true
					},
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				width: 2,
				colors: ['#41b5f5', '#0082CB', '#18345D'],
				curve: 'straight',
				dashArray: [0, 0, 0],
			},
			markers: {
				size: 0,
				hover: {
					sizeOffset: 6,
				},
				colors: ['#41b5f5', '#0082CB', '#18345D'],
			},
			xaxis: {
				labels: {
					trim: false,
				},
				categories: [
					this.translate.instant('month.Ene'),
					this.translate.instant('month.Feb'),
					this.translate.instant('month.Mar'),
					this.translate.instant('month.Abr'),
					this.translate.instant('month.May'),
					this.translate.instant('month.Jun'),
					this.translate.instant('month.Jul'),
					this.translate.instant('month.Ago'),
					this.translate.instant('month.Sep'),
					this.translate.instant('month.Oct'),
					this.translate.instant('month.Nov'),
					this.translate.instant('month.Dic'),
				],
			},
			tooltip: {
				y: [
					{
						title: {
							formatter: function (val) {
								return val + ' (mins)';
							},
						},
					},
				],
				marker: {
					fillColors: ['#41b5f5', '#0082CB', '#18345D'],
				},
			},
			grid: {
				borderColor: '#f1f1f1',
			},
			fill: {
				opacity: 1,
				colors: ['#41b5f5', '#0082CB', '#18345D'],
			},
			legend: {
				show: false,
				position: 'bottom',
				fontFamily: 'Inter',
				offsetY: 0,
				markers: {
					fillColors: ['#41b5f5', '#0082CB', '#18345D'], // Colores personalizados para los marcadores de la leyenda
				},
			},
			responsive: [
				{
					breakpoint: 620,
					options:{
						chart: {
							dropShadow: {
								enabled: false,
								enabledOnSeries: undefined,
								top: 0,
								left: 0,
								blur: 3,
								color: '#000',
								opacity: 0.35,
							},
							height: 150,
							type: 'line',
							stacked: true,
							toolbar: {
								show: true,
								tools:{
									download:`
										<div class="downloadClassHistoricalOrders">
											<label class="textExport">
												Exportar
											</label>
											<div class="icon">
											</div>
										</div>
									`,
									pan:false,
									reset:false,
									zoom:false,
									zoomin:false,
									zoomout:false,
									selection:true
								},
							},
						},
					}
				},
			],
		};
	}

	descargarSvg() {}
}
