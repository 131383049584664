import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-location-admin',
	templateUrl: './location-admin.component.html',
	styleUrls: ['./location-admin.component.scss'],
})
export class LocationAdminComponent implements OnInit {
	address: string = '';
	id_locker: number;

	arrayMarker: any = [];
	zoom: number = 17;
	longitud: number = 0;
	latitud: number = 0;

	ngOnInit() {}

	constructor() {
		this.address = localStorage.getItem('address');
		this.longitud = Number(localStorage.getItem('lng'));
		this.latitud = Number(localStorage.getItem('lat'));
		this.id_locker = Number(localStorage.getItem('id_locker'));

		if (this.latitud && this.longitud) {
			this.arrayMarker = [
				{
					draggable: true,
					id_locker: this.id_locker,
					label: 'I',
					lat: this.latitud,
					lng: this.longitud,
					locker_name: 'VIVITEST',
				},
			];
			this.arrayMarker = JSON.stringify(this.arrayMarker);
		}
	}
}
