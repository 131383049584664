<div class="card text-center" style="height: auto; width: 100%">
    <div class="card-body">
        <div class="row">
            <div class="col-sm-5 col-md-5 col-lg-5 col-xl-5">
                <h5>
                    {{ 'lockers.average_refund' | translate }}
					<br>
                </h5>
                <p>
                    {{ 'lockers.average_refund2' | translate }}
                </p>
            </div>
            <div class="col-sm-7 col-md-7 col-lg-7 col-xl-7">
                <p class="bighour">
                    <span class="big">
                        {{ avg_time_refund }}
                    </span>
                </p>
                <div style="width: 25px; margin-top: 8px;">
                    <i class="fas fa-info-circle" matTooltip="{{'info.lockers.historical.avg_collection_time' | translate }}" matTooltipPosition="above"></i>
                </div>
            </div>
        </div>
    </div>
</div>