import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { CardData, Data } from 'src/app/core/models';
import { DataService } from 'src/app/core/services/data.service';

@Component({
	selector: 'app-grid1',
	templateUrl: './grid1.component.html',
	styleUrls: ['./grid1.component.scss'],
})
export class Grid1Component implements OnInit {
	alias_username: string;

	//? CALENDAR INPUTS -------------------------------------->
	// INPUT PLACEHOLDER
	inputPeriodo: string = 'Periodo';
	//? CHARTS ----------------------------------------->
	// SPAN PERIOD
	periodBase = '10 - 17 de Marzo 2023';
	periodComp = '';

	// SELECTED DATES STYLES
	dateChange = 'dates-default';
	//! STORED DATES
	changeBase: string = '17 - 21 de Marzo 2023';
	changeComp: string = '24 - 28 de Marzo 2023';
	// USE LOCKERS OPERATION
	//CALENDAR VARIABLES
	rangeDates: Date[];
	InitialDate: Date = new Date();
	FinalDate: Date = new Date();
	diferenciaDiasInicial;

	rangeDatesComparacion: Date[];
	MaxFinalDateComparacion: Date = new Date();
	InitialDateComparacion: Date = new Date();
	FinalDateComparacion: Date = new Date();
	diferenciaDiasComparacion;
	showSecondCalendar: Boolean = false;
	//LOKCER
	locks: [];
	completedDataCompleted = [];

	placeholderSelectLocker: string;
	placeholderBasePeriod: string;
	placeholderComparisionPeriod: string;

	// FUNCTION
	cambioPeriodo() {
		// SPAN CHANGES
		this.periodBase = this.changeBase;
		this.periodComp = this.changeComp;
		// STYLE CHANGES
		this.dateChange = 'dates-change';
	}
	addEventRangeDates() {
		this.InitialDate = this.rangeDates[0];
		this.MaxFinalDateComparacion.setDate(this.InitialDate.getDate() - 1);
		if (this.rangeDates[1] !== null) {
			this.FinalDate = this.rangeDates[1];
			this.diferenciaDiasInicial =
				this.FinalDate.getDate() - this.InitialDate.getDate();
			this.showSecondCalendar = true;
		}
	}
	addEventRangeDates2() {
		this.InitialDateComparacion = this.rangeDatesComparacion[0];
		if (this.rangeDatesComparacion[1] !== null) {
			this.FinalDateComparacion = this.rangeDatesComparacion[1];
			this.diferenciaDiasComparacion =
				this.FinalDateComparacion.getDate() -
				this.InitialDateComparacion.getDate();

			if (this.diferenciaDiasComparacion === this.diferenciaDiasInicial) {
			} else {
				this.rangeDatesComparacion = [];
				this.toastr.success(
					'Debe seleccionar la misma cantidad de dias',
					'Error en seleccion de fechas',
					{
						toastClass: 'toast-erronea',
						timeOut: 5000,
						positionClass: 'toast-bottom-center',
					}
				);
			}
		}
	}
	async getLockers() {
		let month = new Date().getMonth();
		let year = new Date().getFullYear();
		let data = {
			language: localStorage.getItem('language'),
			month_actuality: month + 1,
			last_month: month,
			year: year,
		};
		// this.locks = await this.adminService.getLockersRetail(data);
		// await this.adminService.getLockersRetail(data).then((res:any)=>{
		// 	res.return_result[0][1].forEach((item:any)=>{
		// 		this.completedDataCompleted.push({
		// 			lockerID			: item.locker_name,
		// 			totalDeliveries		: item.PACKAGE[0].DELIVERY[0].package_month_actuality_delivery +'('+item.PACKAGE[0].DELIVERY[0].difference_percentage_delivery.toFixed(2) +'%)',
		// 			total				: item.PACKAGE[0].BY_COLLECT[0].result_delivery_to_collect,
		// 			doors				: item.door_total,
		// 			available			: item.compartments_available,
		// 			status				: item.name_status,
		// 			serial				: item.serial_locker,
		// 			status_access_locker: item.ping_nombre_status == 'INACTIVE' ? 'DOWN' : 'UP',
		// 			MODULE				: item.MODULE,
		// 			id					: item.id_locker,
		// 			lat					: item.latitud,
		// 			lng					: item.longitud,
		// 			address				: item.locker_address,
		// 		});
		// 	})
		// })
		// this.locks.map((item: any) => {

		// });
	}
	ngOnInit() {
		this.placeholderSelectLocker = this.translate.instant(
			'overview.selectLocker'
		);
		this.placeholderBasePeriod = this.translate.instant(
			'overview.basePeriod'
		);
		this.placeholderComparisionPeriod = this.translate.instant(
			'overview.comparisonPeriod'
		);

		this.getLockers();
	}
	constructor(
		private adminService: AdminClientService,
		private toastr: ToastrService,
		private translate: TranslateService,
		public ds: DataService
	) {
		this.alias_username = this.ds.alias_username;

		translate.setDefaultLang(
			localStorage.getItem('language').toLowerCase()
		);

		this.placeholderSelectLocker = this.translate.instant(
			'overview.selectLocker'
		);
		this.placeholderBasePeriod = this.translate.instant(
			'overview.basePeriod'
		);
		this.placeholderComparisionPeriod = this.translate.instant(
			'overview.comparisonPeriod'
		);
	}
	//? KPIS SETTINGS ---------------------->
	// GRID STYLE
	gridOverview: string = 'grid-card-1';
	//KPIS DATA
	dataCardSm: CardData[] = [
		// CARD 1
		{
			header: this.translate.instant('overview.cardsOverview.totalUse'),
			data: 9336,
			percent: 8,
			footer: this.translate.instant(
				'overview.cardsOverview.versusComparision'
			),
			footerData: 7299,
			toolTip: this.translate.instant('tooltip.useTotal'),
		},
		// CARD 2
		{
			header: this.translate.instant(
				'overview.cardsOverview.averageHourlyUse'
			),
			data: 9210,
			percent: 9,
			footer: this.translate.instant(
				'overview.cardsOverview.versusComparision'
			),
			footerData: 705,
			toolTip: this.translate.instant('tooltip.averageByHour'),
		},
		// CARD 3
		{
			header: this.translate.instant(
				'overview.cardsOverview.timeHighestUse'
			),
			data: 1400,
			footer: this.translate.instant('overview.comparison'),
			footerData: 1600,
			disable: 'display:none;',
			toolTip: this.translate.instant('tooltip.highestUsageTime'),
		},
		// CARD 4
		{
			header: this.translate.instant(
				'overview.cardsOverview.dayHighestUse'
			),
			data: this.translate.instant('day.Jueves'),
			percent: 2.11,
			footer: this.translate.instant('overview.comparison'),
			footerData: this.translate.instant('day.Martes'),
			disable: 'display:none;',
			toolTip: this.translate.instant('tooltip.highestUsageDay'),
		},
		// CARD 5
		{
			header: this.translate.instant(
				'overview.cardsOverview.uniqueClients'
			),
			data: 4368,
			percent: 2.11,
			footer: this.translate.instant(
				'overview.cardsOverview.versusComparision'
			),
			footerData: 3876,
			toolTip: this.translate.instant('tooltip.uniqueClients'),
		},
		// CARD 6
		{
			header: this.translate.instant(
				'overview.cardsOverview.recurringClients'
			),
			data: 1978,
			percent: 8,
			footer: this.translate.instant(
				'overview.cardsOverview.versusComparision'
			),
			footerData: 1100,
			toolTip: this.translate.instant('tooltip.recurringClients'),
		},
	];
}
