import { Component, Inject, OnInit, Output, EventEmitter, ViewChild, OnDestroy} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatStepper, MatStepperIntl } from '@angular/material/stepper';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AdminClientService } from '../../../../../core/http/admin-client.service';
import { LockerCompartment, NewOrder, LockersInfo, LockersSize, LockersDimension } from '../../../../../core/models/admin-client.interfaces';
import { LockersClientGridAdminComponent } from '../../lockers-client-grid-admin/lockers-client-grid-admin.component';
import { SelectLockerComponent } from '../../select-locker/select-locker.component';
import { ToastrService } from 'ngx-toastr';


@Component({
	selector: 'app-order-create',
	templateUrl: './order-create.component.html',
	styleUrls: ['./order-create.component.scss'],
})
export class OrderCreateComponent implements OnInit,OnDestroy {
	@ViewChild('stepper', { static: false }) stepper: MatStepper;
	@ViewChild(LockersClientGridAdminComponent)
	LockerClientGrid: LockersClientGridAdminComponent;
	@ViewChild(SelectLockerComponent)
	SelectLockerComponent: SelectLockerComponent;
	@Output() update = new EventEmitter<string>();
	title: string;
	currentStep: number = 0;
	edit_package: boolean = false;
	language: string;
	id_locker;
	text_button: string;
	tracking: string = '';
	phone: string = '';
	email: string = '';
	typeMsgPhone: string = '';
	typeMsgEmail: string = '';
	styles_input_email: string = '';
	styles_input_phone: string = '';
	typeMsg: string = '';
	id_service_update: number;
	search: string;
	size: number;
	list_compartment;
	disabled_next_contact: string = 'disabled';
	select_locker_value: string = 'false';
	emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
	view_grid: boolean = false;
	id_locker_select;
	gridLocalhost: string;
	lockerName: string;
	lockerSize: string;
	lockerDimension: string;
	compartment_select: number = parseInt(
		localStorage.getItem('comparment_selected')
	);
	select_compartment: boolean = true;
	id_locker_dimension: number;
	dataGrid;
	prefix_tracking: string = '';
	lockerSelect;
	locker_code;
	lockerHash;
	comparment = [
		{ compartment_name: 'XX', id_compartment_dimension: 1, checked: false },
		{ compartment_name: 'XX', id_compartment_dimension: 2, checked: false },
		{ compartment_name: 'XX', id_compartment_dimension: 3, checked: false },
		{ compartment_name: 'XX', id_compartment_dimension: 4, checked: false },
		{ compartment_name: 'XX', id_compartment_dimension: 5, checked: false },
		{ compartment_name: 'XX', id_compartment_dimension: 6, checked: false },
	];
	isDisabled: boolean = true;
	locker = [];
	firstFormGroup = this._formBuilder.group({
		tracking: [
			null,
			[
				Validators.required,
				Validators.minLength(13),
				Validators.maxLength(13),
				Validators.pattern('^[0-9S]*$'),
			],
		],
		phone: [
			null,
			[
				Validators.required,
				Validators.minLength(12)
			],
		],
		email: [
			null,
			[
				Validators.required,
				Validators.pattern(
					"[a-zA-Z0-9!#$%&'*_+-]([.]?[a-zA-Z0-9!#$%&'*_+-])+@[a-zA-Z0-9]([^@&%$/()=?¿!.,:;]|d)+[a-zA-Z0-9][.][a-zA-Z]{2,4}([.][a-zA-Z]{2})?"
				),
			],
		],
	});
	get trackingValue() {
		return this.firstFormGroup.get('tracking').value;
	}
	get phoneValue() {
		return this.firstFormGroup.get('phone').value;
	}
	get emailValue() {
		return this.firstFormGroup.get('email').value;
	}
	data_locker;
	longitud: number = 0;
	latitud: number = 0;
	zoom: number = 17;
	arrayMarker: any = [];
	coords;
	lockers;
	status = '';
	columns = ['select', 'locker_name', 'available'];
	hoverCoords = '';
	disabled_next_locker: string = '';
	s: number = null;
	m: number = null;
	g: number = null;
	eg: number = null;
	l: number = null;
	xl: number = null;
	xxl: number = null;
	xxxl: number = null;
	ml: number = null;
	cStatus;
	modulo;
	public respError: boolean;
	ngOnInit() {
		localStorage.removeItem('comparment_selected');
		this.language = localStorage.getItem('language').toUpperCase();
		if (localStorage.getItem('id_locker') && !this.data.edit_package) {
			this.id_locker = Number(localStorage.getItem('id_locker'));
		} else {
			this.id_locker = null;
		}
		this.edit_package = this.data.edit_package;
		this.title = this.data.title;
		this.text_button = this.data.text_button;
		if (this.data.edit_package) {
			this.tracking = this.data.data_package.tracking;
			this.phone = this.data.data_package.phone;
			this.email = this.data.data_package.email;
			this.id_service_update = this.data.data_package.id_service;
			if ( this.data.data_package.lockerID && this.data.data_package.lockerID != 'not_select') {
				this.search = this.data.data_package.lockerID;
				this.selectCompartment(this.data.data_package.id_compartment_dimension);
			}
		}
		this.translateOpcional();
		this.getCompartment();
		this.title = this.data.title;
	}
	constructor( private toastr: ToastrService, private _formBuilder: FormBuilder, public dialogRef: MatDialogRef<OrderCreateComponent>, @Inject(MAT_DIALOG_DATA) public data: NewOrder, private adminService: AdminClientService, private dialog: MatDialog, private _matStepperIntl: MatStepperIntl, private translate: TranslateService) {
	}

	ngOnDestroy(): void {
		this.dialogRef.close();
	}
	isValidField(field:string):boolean {
		return this.firstFormGroup.controls[field].errors && this.firstFormGroup.controls[field].touched
	}
	onStepChanged(event) {
		if (event.selectedIndex === 0) {
			localStorage.removeItem('LockerID');
			localStorage.removeItem('LockerDimension');
			localStorage.removeItem('LockerSize');
			localStorage.removeItem('select_locker_create_order');
			localStorage.removeItem('lockerName');
			localStorage.removeItem('grid');
			localStorage.removeItem('id_locker');
			localStorage.removeItem('address_locker');
			localStorage.removeItem('locker_hash');
			localStorage.removeItem('locker_code');
			localStorage.removeItem('compartment_select');
		}
		if (event.selectedIndex === 1) {
			localStorage.removeItem('lockerName');
			localStorage.removeItem('grid');
			localStorage.removeItem('id_locker');
			localStorage.removeItem('address_locker');
			localStorage.removeItem('locker_hash');
			localStorage.removeItem('locker_code');
			localStorage.removeItem('compartment_select');
			this.SelectLockerComponent.selectedLocker = null;
			this.SelectLockerComponent.onLockerChange(null);
			this.currentStep = 1;
		}
		if (event.selectedIndex === 2) {
			localStorage.removeItem('compartment_select');
			this.currentStep = 2;
		}
		if (event.selectedIndex === 3) {
			this.currentStep = 3;
		}
	}
	selectCompartment(id_compartment: number) {
		this.size = id_compartment;
	}
	async translateOpcional() {
		const optional = await this.translate.get(['register_package.info']).toPromise();
		this._matStepperIntl.optionalLabel = optional['register_package.info'];
		this._matStepperIntl.changes.next();
	}
	async getCompartment() {
		let res: any = await this.adminService.getCompartment(localStorage.getItem('language').toLowerCase());
		if (!res.mensaje_return.ERROR) {
			if (res.result_comparment.length > 0) {
				this.list_compartment = JSON.stringify(res.result_comparment);
			} else {
				this.toastr.warning(
					'No hay Compartimiento Disponibles',
					'Alerta no hay Compartimiento Disponible',
					{
						toastClass:'toast-warn',
						timeOut:5000,
						positionClass: 'toast-bottom-center'
					}
				)
			}
		} else {
			this.toastr.error(
				'Listar compartimiento',
				'Hubo un Error',
				{
					toastClass:'toast-warn',
					timeOut:5000,
					positionClass: 'toast-bottom-center'
				}
			)
		}
	}
	closeAll() {
		this.dialog.closeAll();
	}
	notReadyDataContat() {
		this.select_locker_value = localStorage.getItem('select_locker_create_order');
	}
	readyDataContact() {
		this.currentStep = 1;
		let tracking: any = this.firstFormGroup.get('tracking')	? this.firstFormGroup.get('tracking').value	: '0';
		let email: any = this.firstFormGroup.get('email') ? this.firstFormGroup.get('email').value : '0';
		if (tracking != null && tracking.length == 9 && email != null && email.length > 8) {
			if (this.emailRegex.test(this.firstFormGroup.get('email').value)) {
				this.tracking = this.firstFormGroup.get('tracking').value;
				this.phone = this.phoneValue;
				this.email = this.firstFormGroup.get('email').value;
			}
			else {
				this.styles_input_email = 'border-input-error';
				this.typeMsgEmail = 'error-email';
			}
		}
		if (this.firstFormGroup.status === 'VALID') {
			this.stepper.next();
			localStorage.setItem('select_locker_create_order', 'false');
		}
	}
	modelChanged($event) {
		let tracking: any = this.firstFormGroup.get('tracking') ? this.firstFormGroup.get('tracking').value	: '0';
		let email: any = this.firstFormGroup.get('email') ? this.firstFormGroup.get('email').value : '0';
		let phone: string = this.firstFormGroup.get('phone') ? this.firstFormGroup.get('phone').value : '0';
		if (tracking != null &&	tracking.length == 9 &&	email != null && email.length > 8) {
			if (Number(this.phoneValue)) {
			}
			else {
				this.styles_input_phone = 'border-input-error';
				this.typeMsgPhone = 'error-phone';
			}
			if (this.emailRegex.test(this.firstFormGroup.get('email').value)) {
				this.tracking = this.firstFormGroup.get('tracking').value;
				this.phone = this.phoneValue;
				this.email = this.firstFormGroup.get('email').value;
			}
			else {
				this.styles_input_email = 'border-input-error';
				this.typeMsgEmail = 'error-email';
			}
		}
		this.styles_input_email = '';
		this.typeMsgPhone = '';
		this.styles_input_phone = '';
		this.typeMsgEmail = '';
		this.typeMsg = '';
		if(this.firstFormGroup.valid === true){
			this.currentStep = 2;
		}
	}
	readyLocker() {
		this.select_locker_value = localStorage.getItem('select_locker_create_order');
		if (localStorage.getItem('select_locker_create_order') === 'true') {
			this.view_grid = true;
			this.id_locker_select = localStorage.getItem('id_locker');
			this.gridLocalhost = localStorage.getItem('grid');
			this.stepper.next();
		}
	}
	updateStepCurrent(step: number) {
		this.currentStep = step;
	}
	onChildItemSelected(item: any) {
	}
	eraseGrid() {
		localStorage.removeItem('grid');
		localStorage.removeItem('id_locker');
		this.LockerClientGrid.grid.pop();
		this.LockerClientGrid.module = [];
		this.LockerClientGrid.count_door = 1;
	}
	getComparmentSelected() {
		this.lockerName = localStorage.getItem('lockerName');
		this.lockerDimension = localStorage.getItem('LockerSize');
		this.lockerSize = localStorage.getItem('LockerDimension');
		this.compartment_select = parseInt(localStorage.getItem('compartment_select'));
		this.currentStep = 4;
		this.stepper.next();
	}
	select_compartment_available(compartment: any) {
        this.size = compartment.id_compartment_dimension;
        localStorage.setItem('compartment_select', compartment.text);
        this.currentStep = 3;
    }
	selectLocker(lockerSelect: any) {
		this.size = null;
		this.isDisabled = false;
		this.search = lockerSelect.locker_name;
		this.lockerSelect = lockerSelect.id_locker;
		this.comparment = lockerSelect.COMPARTMENT;
		this.locker_code = lockerSelect.locker_code;
		this.locker.forEach((l: any) => {
			if (l.id_locker == lockerSelect.id_locker) {
				document.getElementById('item' + l.id_locker).classList.add('lockerSeleted');
			}
			else {
				document.getElementById('item' + l.id_locker).classList.remove('lockerSeleted');
			}
		});
	}
	eraseCompartment() {
		localStorage.removeItem('compartment_select');
	}
	async creteOrder() {
		this.dialog.closeAll();
		let date = new Date();
		let day = date.getDate();
		let month = date.getMonth() + 1;
		let year = date.getFullYear();
		let data = {
			language: 'en',
			date_schedule: year + '-0' + month + '-' + day,
			data_schedule_package: [
				{
					id_locker: localStorage.getItem('id_locker'),
					code_tracking: this.trackingValue,
					main_tlf: '+52'+this.phoneValue,
					email_consumer: this.emailValue,
					locker_code: localStorage.getItem('lockerCode'),
					locker_hash: localStorage.getItem('lockerHash'),
					id_locker_dimension: this.size
				},
			],
		};
		let res: any = await this.adminService.createOrder(data);
		if (res.mensaje_return.ERROR) {
			this.toastr.success(
				'revisa bien los detalles',
				'Oh, hubo un error',
				{
					toastClass: 'toast-erronea',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
				}
			);
			this.update.emit('false');
		}
		else {
			this.toastr.success(
				'santifactoriamente',
				'La Orden se ha guardado',
				{
					toastClass: 'toast-extendida',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
				}
			);
			this.update.emit('true');
		}
	}
	async getLockerCompartment() {
		this.coords = [];
		this.lockers = [];
		const res: LockerCompartment = await this.adminService.getLockerCompartment(localStorage.getItem('language').toLowerCase());
		if (!res.mensaje_return.ERROR) {
			this.status = 'success';
			res.return_result.forEach((l: LockersInfo) => {
				this.latitud = Number(l.latitud);
				this.longitud = Number(l.longitud);
				this.arrayMarker.push({
					lat: this.latitud,
					lng: this.longitud,
				});
				this.coords.push({
					lat: Number(l.latitud),
					lng: Number(l.longitud),
					locker_name: l.locker_name,
					lockerID: l.id_locker,
				});
				this.lockers.push({
					id_locker: l.id_locker,
					locker_name: l.locker_name,
					locker_hash: l.locker_hash,
					locker_code: l.locker_code,
					doors: l.total_door,
					available: l.total_available,
					MODULE: l.data_module,
					COMPARTMENT: l.COMPARTMENT,
					link_address: false,
				});
				this.locker.push(l);
			});
			this.lockers.forEach((l: LockersInfo) => {
				l.COMPARTMENT.forEach((size: LockersSize) => {
					const columnName = JSON.parse(size.description)[0].EN.replaceAll(/ /g, '_');
					this.columns.push(columnName);
					l[columnName] = size.cantidad_avalible;
				});
			});
			this.data_locker = JSON.stringify(this.lockers);
			this.locker.forEach((l: LockersInfo) => {
				if (this.search == l.locker_name) {
					l.checked = true;
					this.comparment = l.COMPARTMENT;
					this.lockerSelect = l.id_locker;
					this.locker_code = l.locker_code;
					this.comparment.forEach((c: LockersDimension) => {
						c.checked = this.size == c.id_compartment_dimension;
					});
					this.isDisabled = this.comparment.some(
						(c: LockersDimension) => c.checked
					);
				} else {
					l.checked = false;
				}
			});
		}
		else {
			this.status = 'err';
		}
	}
	hoverMarker(e) {
		this.hoverCoords = JSON.stringify(e.coords);
	}
	outMarker() {
		this.hoverCoords = JSON.stringify({ lat: '', lng: '' });
	}
	async onSelectLocker(locker: any) {
		this.lockerHash = locker.locker_hash;
		this.locker_code = locker.locker_code;
		this.disabled_next_locker = 'disabled';
		if (localStorage.getItem('grid')) {
			localStorage.removeItem('grid');
		}
		const grid = locker.MODULE.map((e: any) => ({
			name_locker_module: e.name_locker_module,
			template_module: e.template_module,
		}));
		localStorage.setItem('grid', JSON.stringify(grid));
		localStorage.setItem('id_locker', locker.id_locker);
		const data = {
			language: localStorage.getItem('language')?.toLowerCase(),
			id_locker: locker.id_locker,
		};
		this.lockerSelect = locker.id_locker;
		this.dataGrid = await this.adminService.getLockersGrid(data);
		const quantityCompartment = this.dataGrid.quantity_compartment;
		const compartmentStatus = quantityCompartment.compartment_status;
		quantityCompartment.compartment.forEach((compartment: any) => {
			const { compartment_name, quantity } = compartment;
			this[compartment_name.toLowerCase()] = quantity || null;
		});
		this.modulo = "";
		this.modulo = JSON.stringify(this.dataGrid.modulo_status);
		this.cStatus = JSON.stringify(compartmentStatus);
		this.dataGrid.modulo_status.forEach((modulo: any) => {
			modulo.DOOR.forEach((door: any) => {
				const { compartment_name } = door;
				const compartment = this[compartment_name.toLowerCase()];
				if (compartment) {
					this[compartment_name.toLowerCase()] = compartment - 1;
				}
			});
		});
		this.disabled_next_locker = '';
		this.view_grid = true;
	}
}
