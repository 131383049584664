//ANGULAR
import { Component, ViewChild } from '@angular/core';
//APEXCHARTS
import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexChart,
	ApexXAxis,
	ApexDataLabels,
	ApexStroke,
	ApexMarkers,
	ApexYAxis,
	ApexGrid,
	ApexTitleSubtitle,
	ApexLegend,
} from 'ng-apexcharts';

//Tipos de ApexCharts, que son utilizados para definir opciones de gráficos.
export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	stroke: ApexStroke;
	dataLabels: ApexDataLabels;
	markers: ApexMarkers;
	colors: string[];
	yaxis: ApexYAxis;
	grid: ApexGrid;
	legend: ApexLegend;
	title: ApexTitleSubtitle;
};

@Component({
	selector: 'app-historical-packages',
	templateUrl: './historical-packages.component.html',
	styleUrls: ['./historical-packages.component.scss'],
})
export class HistoricalPackagesComponent {
	@ViewChild('chart') chart: ChartComponent;
	public chartOptions: Partial<ChartOptions>;

	constructor() {
		this.chartOptions = {
			series: [
				{
					name: 'Entregado',
					data: [
						300, 250, 260, 230, 245, 310, 330, 290, 280, 300, 310,
						320,
					],
					color: 'var(--color-chart-300)',
				},
				{
					name: 'Recolectados',
					data: [
						100, 120, 130, 150, 160, 110, 90, 80, 60, 110, 100, 120,
					],
					color: 'var(--color-chart-200)',
				},
				{
					name: 'Devueltos',
					data: [
						120, 110, 140, 180, 170, 130, 130, 140, 150, 100, 120,
						130,
					],
					color: 'var(--color-chart-100)',
				},
			],
			chart: {
				width: 699,
				height: 289,
				type: 'line',
				dropShadow: {
					enabled: false,
				},
				toolbar: {
					show: false,
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: 'straight',
				width: 2,
			},
			grid: {
				borderColor: 'var(--color-primary-200)',
				row: {
					colors: ['var(--color-container)'], // takes an array which will be repeated on columns
				},
			},
			markers: {
				size: 0,
			},
			xaxis: {
				categories: [
					'Jan.',
					'Feb.',
					'Mar.',
					'Apr.',
					'May.',
					'Jun.',
					'Jul.',
					'Aug.',
					'Sep.',
					'Oct.',
					'Nov.',
					'Dec.',
				],
				labels: {
					style: {
						colors: [
							'var(--color-primary-300)',
							'var(--color-primary-300)',
							'var(--color-primary-300)',
							'var(--color-primary-300)',
							'var(--color-primary-300)',
							'var(--color-primary-300)',
							'var(--color-primary-300)',
							'var(--color-primary-300)',
							'var(--color-primary-300)',
							'var(--color-primary-300)',
							'var(--color-primary-300)',
							'var(--color-primary-300)',
						],
						fontSize: 'var(--font-c2)',
						fontFamily: 'var(--primary-font)',
					},
				},
			},
			yaxis: {
				min: 50,
				max: 400,
				labels: {
					style: {
						colors: ['var(--color-primary-300)'],
						fontSize: 'var(--font-c2)',
						fontFamily: 'var(--primary-font)',
					},
				},

				tickAmount: (400 - 50) / 50, // Este cálculo muestra divisiones cada 50 unidades
			},
			legend: {
				position: 'top',
				horizontalAlign: 'right',
				floating: true,
				offsetY: -25,
				offsetX: -5,
			},
		};
	}
}
