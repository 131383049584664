import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-average-pickup',
	templateUrl: './average-pickup.component.html',
	styleUrls: ['./average-pickup.component.scss'],
})
export class AveragePickupComponent {
	@Input() metrics!: any[];
	avg_time_refund;

	ngOnInit(): void {
		this.getMetrics();
	}

	async getMetrics() {
		let [hours, minutes, seconds] =
			this.metrics[0][1][0].avg_time.split(':');
		this.metrics[0][1][0].avg_time_refund == null
			? (this.metrics[0][1][0].avg_time_refund = '--:--')
			: null;
		let [hours_refund, minutes_refund, seconds_refund] =
			this.metrics[0][1][0].avg_time_refund.split(':');
		this.avg_time_refund = hours_refund + ':' + minutes_refund;
	}
}
