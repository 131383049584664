<div class="container-fluid">
	<div class="row">
		<div class="col-10">
			<label class="header-locker-image">
				Locker {{lockerName}}
			</label>
		</div>
		<div class="col-2" style="text-align: end; cursor: pointer;" (click)="closeModal()">
			<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" class="bi bi-x-lg" viewBox="0 0 16 16">
				<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
			</svg>
		</div>
		<div class="col-12" style="padding-right:0px; padding-left:0px; text-align: center;">
			<img [src]="img_src" (error)="url_view_image=false" *ngIf="url_view_image" alt="imagen-locker" width="70%" height="auto">
			<ng-container *ngIf="!url_view_image">
				<div class="container-image">
					<div class="container-image-panding">
						<h2>{{'message.title_err_img_404' | translate }}</h2>
						<p>{{'message.err_img_404' | translate }}</p>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>

