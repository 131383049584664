<div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card-activity">
            <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6" style="align-items: center; justify-content: end; display: flex;">
                    <div class="text-start" style="width: 1075px; ">
                        <input (keyup)="onFilterTextChange($event)" placeholder="{{'dashboard.search_user' | translate}}" class="input-gbl input-table-locker">
                        <svg xmlns="http://www.w3.org/2000/svg" style="margin-left: -2rem;" width="20" height="20" fill="#212121" class="bi bi-search search" viewBox="0 0 16 16">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                        </svg>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6" style="align-items: center; justify-content: end; display: flex;">
                    <div class="text-end" style="display: flex;">
                        <p-calendar class="selectYear"
                                    [showIcon]="true"
                                    [(ngModel)]="rangeDates"
                                    (ngModelChange)="addEventRangeDates()"
                                    selectionMode="range"
                                    [readonlyInput]="true"
                                    inputId="range" >
                        </p-calendar>
						<div class="text-end" style="width:167px; padding-left:0px">
							<button-export (click)="onExport()"></button-export>
						</div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top:17px">
                    <ag-grid-angular 	class="ag-theme-alpine"
										[gridOptions]="gridOptions"
										[columnDefs]="this.columnDefsActivity"
										[quickFilterText]="this.filterText"
										[rowData]="rowData"
										[animateRows]="true"
										style="width: 100%; height: auto;"
										(gridReady)="onGridReady($event)">
                    </ag-grid-angular>
                </div>
            </div>
        </div>
    </div>
</div>

