<div class="row container-graphic justify-content-center">
    <!-- HEADER -->
    <div class="row header-graphic">
        <div class="col">
            <div class="header-txt">
                Usage state
            </div>
        </div>

    </div>

    <!-- CHART -->
    <div class="row chart-pie" id="chart">
        <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [plotOptions]="chartOptions.plotOptions"
            [labels]="chartOptions.labels"
            [legend]="chartOptions.legend"
            [colors]="chartOptions.colors"
            [responsive]="chartOptions.responsive">
        </apx-chart>
    </div>

     
    <!-- FOOTER -->
    <div class="col text-center mt-custome" >     
        <!--? PUEBLA LINE -->
        <div class="d-inlane-table container-foot square-1"></div>
        <!-- TXT -->
        <p class="d-inlane-table container-foot">
            Puebla
        </p>  

        <!--? QUERETARO LINE -->
        <div class="d-inlane-table container-foot square-2"></div>
        <!-- TXT -->
        <p class="d-inlane-table container-foot">
            Querétaro
        </p>  

        <!--? QUERETARO LINE -->
        <div class="d-inlane-table container-foot square-3"></div>
        <!-- TXT -->
        <p class="d-inlane-table container-foot">
            Baja California
        </p>  
    </div>

    

</div>