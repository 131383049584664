
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions } from 'ag-grid-community';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import {
	LockerCompartment,
	LockersInfo,
	Compartment,
} from '../../../../core/models/admin-client.interfaces'; //'/core/models/admin-client.interfaces';
import { SelectComponent } from '../select/select.component';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Component, Output, ViewChild, EventEmitter, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

interface GridConfig {
	columnDefs: ColDef[];
	rowData: any[];
}
@Component({
	selector: 'app-select-locker',
	templateUrl: './select-locker.component.html',
	styleUrls: ['./select-locker.component.scss'],
})
export class SelectLockerComponent implements OnInit {
	@ViewChild(SelectComponent) SelectComponent: SelectComponent;
	@Output() onItemSelected = new EventEmitter();
	list_compartment: Compartment[] = [];
	data_locker;
	coords;
	lockers;
	//MAPS
	longitud: number = 0;
	latitud: number = 0;
	zoom: number = 17;
	arrayMarker: any = [];
	//AG GRID ANGULAR
	@ViewChild('agGrid') agGrid: AgGridAngular;
	rowData: any[] = [];
	dataOfTable = [];
	// public gridOptions: GridConfig;
	public defaultColDef: ColDef = {
		sortable: true,
	};
	public columnDefs: ColDef[];
	gridOptions: GridOptions;
	//PRIME NG;
	selectedLocker;
	compartment;
	filterText: string = '';
	public filteredData: any[] = [];

	constructor(private adminService: AdminClientService, private translate: TranslateService, private toast : ToastrService) {
		translate.use(localStorage.getItem('language').toLowerCase());
		// const columnDefs: ColDef[] = [
		// 	{
		// 		headerName: 'Selecciona',
		// 		field: 'selecciona',
		// 		cellRenderer: SelectComponent,
		// 	},
		// 	{
		// 		headerName: 'LOCKER ID',
		// 		field: 'locker_name',
		// 	},
		// 	{
		// 		headerName: 'PUERTAS DISPONIBLES',
		// 		field: 'available',
		// 	},
		// ];
		// this.gridOptions = {
		// 	columnDefs: columnDefs,
		// 	rowData: this.lockers,
		// };
	}

	ngOnInit() {
		localStorage.removeItem('LockerDimension');
		localStorage.removeItem('LockerSize');

		// "tableSelectLocker":{
			// "select":"Select",
			// "locker":"Locker ID",
			// "door":"Available Doors"
		// },
		this.updateDataSelectLocker();
		this.translate.onLangChange.subscribe((event:LangChangeEvent)=>{
			this.translate.use(event.lang);
			this.translate.get([
				'tableSelectLocker.select',
				'tableSelectLocker.locker',
				'tableSelectLocker.door'
			]).subscribe((t:any)=>{
				this.columnDefs = [
					{
						headerName: t['tableSelectLocker.select'],
						field: 'selecciona',
						sortable: true,
						autoHeight: true,
						cellClass:'SelectLockerClass',
						headerClass:'borderHeaderSelectLocker',
						cellRenderer: SelectComponent,
					},
					{
						headerName: t['tableSelectLocker.locker'],
						field: 'locker_name',
						sortable: true,
						autoHeight: true,
						cellClass:'SelectLockerClass',
						headerClass:'borderHeaderSelectLocker',
					},
					{
						headerName: t['tableSelectLocker.door'],
						field: 'available',
						sortable: true,
						autoHeight: true,
						cellClass:'SelectLockerClass',
						headerClass:'borderHeaderSelectLocker',
					},
				]
			})
		})
	}

	updateDataSelectLocker():void{
		this.gridOptions = {
			columnDefs: [
				{
					headerName: this.translate.instant('tableSelectLocker.locker'),
					field: 'selecciona',
					sortable: true,
					autoHeight: true,
					cellClass:'SelectLockerClass',
					headerClass:'borderHeaderSelectLocker',
					cellRenderer: SelectComponent
				},
				{
					headerName: this.translate.instant('tableSelectLocker.locker'),
					field: 'locker_name',
					sortable: true,
					autoHeight: true,
					cellClass:'SelectLockerClass',
					headerClass:'borderHeaderSelectLocker',
				},
				{
					headerName: this.translate.instant('tableSelectLocker.door'),
					field: 'available',
					sortable: true,
					autoHeight: true,
					cellClass:'SelectLockerClass',
					headerClass:'borderHeaderSelectLocker',
				}
			],
			animateRows: true,
			defaultColDef: {
				flex: 1,
			},
			rowClass:'rowSelectLockerClass',
			pagination: true,
			paginationPageSize: 5,
			paginationAutoPageSize: true,
			rowHeight:59,
			headerHeight:59,
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Por favor, selecciona el tamaño del compartimiento <br>
					que necesitas en la parte superior derecha
				</div>
			</div>`
		}
	}

	comparment = [];
	async getCompartment() {
		this.list_compartment = [];
		let res: any = await this.adminService.getCompartment('en');
		if (!res.mensaje_return.ERROR) {
			if (res.result_comparment.length > 0) {
				this.list_compartment = res.result_comparment;
			} else {
				this.toast.error('no hay compartimientos disponibles', 'Oh, hubo un error', {
					toastClass: 'toast-erronea',
					timeOut: 5000,
					positionClass: 'toast-bottom-center',
				});
			}
		} else {
			this.toast.success('al listar el compartimiento', 'Oh, hubo un error', {
				toastClass: 'toast-erronea',
				timeOut: 5000,
				positionClass: 'toast-bottom-center',
			});
		}

		this.adminService.getCompartment(localStorage.getItem('language').toLowerCase()).then((res) => {
				res.result_comparment.forEach((item) => {
					this.comparment.push({
						cantidad_compartimientos: item.cantidad_compartimientos,
						compartment_name: item.compartment_name,
						compartment_size: item.compartment_size,
						id_compartment_dimension: item.id_compartment_dimension,
						label:'Tamaño: ' + item.compartment_name + ', Dimensiones: ' + item.compartment_size,
					});
				});
			})
	}

	async getLockerCompartment() {
		this.coords = [];
		this.lockers = [];
		let res: LockerCompartment =
			await this.adminService.getLockerCompartment(
				localStorage.getItem('language').toLowerCase()
			);

		if (!res.mensaje_return.ERROR) {
			res.return_result.forEach((l: LockersInfo) => {
				this.latitud = Number(l.latitud);
				this.longitud = Number(l.longitud);
				this.arrayMarker.push({
					lat: this.latitud,
					lng: this.longitud,
				});
				this.coords.push({
					lat: Number(l.latitud),
					lng: Number(l.longitud),
					locker_name: l.locker_name,
					lockerID: l.id_locker,
				});
				this.lockers.push({
					id_locker: l.id_locker,
					locker_name: l.locker_name,
					locker_hash: l.locker_hash,
					locker_code: l.locker_code,
					address: l.locker_address,
					doors: l.total_door,
					available: l.total_available,
					MODULE: l.data_module,
					COMPARTMENT: l.COMPARTMENT,
					link_address: false,
				});
			});
			this.gridOptions.rowData = this.lockers;
			this.llenarTabla();
		}
	}

	async ngAfterViewInit() {
		this.getCompartment();
		this.llenarTabla();
	}

	async llenarTabla(): Promise<void> {
		this.agGrid.api.setRowData(this.gridOptions.rowData);
	}

	onGridReady(params: any) {
		params.api.setRowData(this.lockers);
	}

	refreshGrid() {
		const api = this.agGrid.api;
		api.refreshCells();
	}
	selectedLockerDisplay: string;

	async queryLockerAvailable(id_compartment_dimension: any) {
		let data = {
			leng: localStorage.getItem('language').toLowerCase(),
			id_compartment_dimension: id_compartment_dimension,
		};

		let res: any = await this.adminService.listLcokerAvailableComparment(
			data
		);
		if (!res.mensaje_return.ERROR) {
			this.data_locker = JSON.stringify(res.result_locker);
		} else {
			console.error('No sepudo cargar el locker');
		}
	}

	async onLockerChange(event) {
		if (event !== null) {
			localStorage.setItem('LockerID', event.id_compartment_dimension);
			localStorage.setItem('LockerDimension', event.compartment_name);
			localStorage.setItem('LockerSize', event.compartment_size);
			localStorage.setItem('select_locker_create_order', 'true');
			localStorage.setItem('idCompartmentDimension',event.id_compartment_dimension)
			this.lockers = [];
			let id_compartment_dimension = event.id_compartment_dimension;
			let data = {
				language: localStorage.getItem('language').toLowerCase(),
				id_compartment_dimension: event.id_compartment_dimension,
			};
			let res: any = await this.adminService.getLockerCompartment(data);
			res.return_result.map((item) => {
				this.lockers.push({
					id_locker: item.id_locker,
					locker_name: item.locker_name,
					locker_hash: item.locker_hash,
					locker_code: item.locker_code,
					address: item.locker_address,
					available: item.total_available,
					MODULE: item.data_module,
				});
			});

			this.agGrid.api.setRowData(this.lockers);
		} else {
			this.lockers = [];
			localStorage.setItem('select_locker_create_order', 'false');
			localStorage.removeItem('LockerID');
			localStorage.removeItem('LockerDimension');
			localStorage.removeItem('LockerSize');
			localStorage.removeItem('lockerName');

			localStorage.removeItem('grid');
			localStorage.removeItem('id_locker');
			localStorage.removeItem('address_locker');
			localStorage.removeItem('locker_hash');
			localStorage.removeItem('locker_code');
			localStorage.removeItem('compartment_select');

			this.agGrid.api.setRowData(this.lockers);
		}
	}

	onFilterTextChange(event) {
		this.filterText = event.target.value;
		this.gridOptions.api.setQuickFilter(this.filterText);
		let api = this.gridOptions.api;
		let temporal = [];
		api.forEachNodeAfterFilter((node) => {
			temporal.push(node.data);
		});
		this.filteredData = temporal;
	}
}
