import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'hourOriginal',
})
export class HourOriginalPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		if (!value) {
			return '';
		}
		const date = new Date(value);
		const hours = date.getHours();
		const minutes = date.getMinutes();
		return `${hours < 10 ? '0' + hours : hours}:${
			minutes < 10 ? '0' + minutes : minutes
		}`;
	}
}
