<div class="filtersBoxOrdersSA" *ngIf="this.filters === true && this.urlActual === 'orders' && this.urlUser === 'superadmin'">
	<div class="row">
		<div class="col-12 col-md-12 col-lg-12 col-xl-12" style="display: flex; justify-content: space-between;">
			<span class="filtersBoxTitle">
				Filters
			</span>
			<div class="icon-close" (click)="closeModal()">
			</div>

		</div>
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<div class="boxValues">
				<div class="row">
					<div class="col-12 col-md-12 col-lg-12 col-xl-12">

						<div class="selectStatus" (click)="openStatusDiv()" *ngIf="status === true">
							<div class="icon-right" *ngIf="openStatus === false">
							</div>
							<div class="icon-down" *ngIf="openStatus === true">
							</div>
							<span class="textDropDown">
								Status
							</span>
						</div>


								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxReturn"
										(change)="validarCasillasSeleccionadas()"
										/>
									<span class="textDropDown">
										Return
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxCompleted"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										Completed
									</span>
								</div>

								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxInLocker"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										En Locker
									</span>
								</div>

								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxExtended"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										Extendido
									</span>
								</div>

								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxExpired"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										Expired
									</span>
								</div>



						<div class="selectStatus" (click)="openRateDiv()" *ngIf="rate === true">
							<div class="icon-right" *ngIf="openRate === false">
							</div>
							<div class="icon-down" *ngIf="openRate === true">
							</div>
							<span class="textDropDown">
								Calificacion
							</span>
						</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxOne"
									(change)="validarStars()"/>
								<span class="textDropDown">
									1
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxTwo"
									(change)="validarStars()"/>
								<span class="textDropDown">
									2
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxThree"
									(change)="validarStars()"/>
								<span class="textDropDown">
									3
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxFour"
									(change)="validarStars()"/>
								<span class="textDropDown">
									4
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxFive"
									(change)="validarStars()"/>
								<span class="textDropDown">
									5
								</span>
							</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="filtersBoxSA" *ngIf="this.filters === true && this.urlActual === 'locker' && this.urlUser === 'superadmin'">
	<div class="row">
		<div class="col-12 col-md-12 col-lg-12 col-xl-12" style="display: flex; justify-content: space-between;">
			<span class="filtersBoxTitle">
				Filters
			</span>
			<div class="icon-close" (click)="closeModal()">
			</div>
		</div>
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<div class="boxValues">
				<div class="row">
					<div class="col-12 col-md-12 col-lg-12 col-xl-12">

						<div class="selectStatus" (click)="openStatusDiv()" *ngIf="status === true">
							<div class="icon-right" *ngIf="openStatus === false">
							</div>
							<div class="icon-down" *ngIf="openStatus === true">
							</div>
							<span class="textDropDown">
								Status
							</span>
						</div>


								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxReturn"
										(change)="validarCasillasSeleccionadas()"
										/>
									<span class="textDropDown">
										Return
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxCompleted"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										Completed
									</span>
								</div>

								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxInLocker"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										En Locker
									</span>
								</div>

								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxExtended"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										Extendido
									</span>
								</div>

								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxExpired"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										Expired
									</span>
								</div>




						<div class="selectStatus" (click)="openRateDiv()" *ngIf="rate === true">
							<div class="icon-right" *ngIf="openRate === false">
							</div>
							<div class="icon-down" *ngIf="openRate === true">
							</div>
							<span class="textDropDown">
								Calificacion
							</span>
						</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxOne"
									(change)="validarStars()"/>
								<span class="textDropDown">
									1
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxTwo"
									(change)="validarStars()"/>
								<span class="textDropDown">
									2
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxThree"
									(change)="validarStars()"/>
								<span class="textDropDown">
									3
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxFour"
									(change)="validarStars()"/>
								<span class="textDropDown">
									4
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxFive"
									(change)="validarStars()"/>
								<span class="textDropDown">
									5
								</span>
							</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="filtersBoxOrdersA" *ngIf="this.filters === true && this.urlActual === 'orders' && this.urlUser === 'admin'">
	<div class="row">
		<div class="col-12 col-md-12 col-lg-12 col-xl-12" style="display: flex; justify-content: space-between;">
			<span class="filtersBoxTitle">
				Filters
			</span>
			<div class="icon-close" (click)="closeModal()">
			</div>

		</div>
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<div class="boxValues">
				<div class="row">
					<div class="col-12 col-md-12 col-lg-12 col-xl-12">

						<div class="selectStatus" (click)="openStatusDiv()" *ngIf="status === true">
							<div class="icon-right" *ngIf="openStatus === false">
							</div>
							<div class="icon-down" *ngIf="openStatus === true">
							</div>
							<span class="textDropDown">
								Status
							</span>
						</div>


								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxReturn"
										(change)="validarCasillasSeleccionadas()"
										/>
									<span class="textDropDown">
										Return
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxCompleted"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										Completed
									</span>
								</div>

								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxInLocker"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										En Locker
									</span>
								</div>

								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxExtended"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										Extendido
									</span>
								</div>

								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxExpired"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										Expired
									</span>
								</div>



						<div class="selectStatus" (click)="openRateDiv()" *ngIf="rate === true">
							<div class="icon-right" *ngIf="openRate === false">
							</div>
							<div class="icon-down" *ngIf="openRate === true">
							</div>
							<span class="textDropDown">
								Calificacion
							</span>
						</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxOne"
									(change)="validarStars()"/>
								<span class="textDropDown">
									1
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxTwo"
									(change)="validarStars()"/>
								<span class="textDropDown">
									2
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxThree"
									(change)="validarStars()"/>
								<span class="textDropDown">
									3
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxFour"
									(change)="validarStars()"/>
								<span class="textDropDown">
									4
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxFive"
									(change)="validarStars()"/>
								<span class="textDropDown">
									5
								</span>
							</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="filtersBoxA" *ngIf="this.filters === true && this.urlActual === 'locker' && this.urlUser === 'admin'">
	<div class="row">
		<div class="col-12 col-md-12 col-lg-12 col-xl-12" style="display: flex; justify-content: space-between;">
			<span class="filtersBoxTitle">
				Filters
			</span>
			<div class="icon-close" (click)="closeModal()">
			</div>
		</div>
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<div class="boxValues">
				<div class="row">
					<div class="col-12 col-md-12 col-lg-12 col-xl-12">

						<div class="selectStatus" (click)="openStatusDiv()" *ngIf="status === true">
							<div class="icon-right" *ngIf="openStatus === false">
							</div>
							<div class="icon-down" *ngIf="openStatus === true">
							</div>
							<span class="textDropDown">
								Status
							</span>
						</div>


								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxReturn"
										(change)="validarCasillasSeleccionadas()"
										/>
									<span class="textDropDown">
										Return
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxCompleted"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										Completed
									</span>
								</div>

								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxInLocker"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										En Locker
									</span>
								</div>

								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxExtended"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										Extendido
									</span>
								</div>

								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxExpired"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										Expired
									</span>
								</div>




						<div class="selectStatus" (click)="openRateDiv()" *ngIf="rate === true">
							<div class="icon-right" *ngIf="openRate === false">
							</div>
							<div class="icon-down" *ngIf="openRate === true">
							</div>
							<span class="textDropDown">
								Calificacion
							</span>
						</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxOne"
									(change)="validarStars()"/>
								<span class="textDropDown">
									1
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxTwo"
									(change)="validarStars()"/>
								<span class="textDropDown">
									2
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxThree"
									(change)="validarStars()"/>
								<span class="textDropDown">
									3
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxFour"
									(change)="validarStars()"/>
								<span class="textDropDown">
									4
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxFive"
									(change)="validarStars()"/>
								<span class="textDropDown">
									5
								</span>
							</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="filtersBoxOrdersCCC" *ngIf="this.filters === true && this.urlActual === 'orders' && this.urlUser === 'ccc'">
	<div class="row">
		<div class="col-12 col-md-12 col-lg-12 col-xl-12" style="display: flex; justify-content: space-between;">
			<span class="filtersBoxTitle">
				Filters
			</span>
			<div class="icon-close" (click)="closeModal()">
			</div>

		</div>
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<div class="boxValues">
				<div class="row">
					<div class="col-12 col-md-12 col-lg-12 col-xl-12">

						<div class="selectStatus" (click)="openStatusDiv()" *ngIf="status === true">
							<div class="icon-right" *ngIf="openStatus === false">
							</div>
							<div class="icon-down" *ngIf="openStatus === true">
							</div>
							<span class="textDropDown">
								Status
							</span>
						</div>


								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxReturn"
										(change)="validarCasillasSeleccionadas()"
										/>
									<span class="textDropDown">
										Return
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxCompleted"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										Completed
									</span>
								</div>

								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxInLocker"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										En Locker
									</span>
								</div>

								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxExtended"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										Extendido
									</span>
								</div>

								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxExpired"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										Expired
									</span>
								</div>



						<div class="selectStatus" (click)="openRateDiv()" *ngIf="rate === true">
							<div class="icon-right" *ngIf="openRate === false">
							</div>
							<div class="icon-down" *ngIf="openRate === true">
							</div>
							<span class="textDropDown">
								Calificacion
							</span>
						</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxOne"
									(change)="validarStars()"/>
								<span class="textDropDown">
									1
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxTwo"
									(change)="validarStars()"/>
								<span class="textDropDown">
									2
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxThree"
									(change)="validarStars()"/>
								<span class="textDropDown">
									3
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxFour"
									(change)="validarStars()"/>
								<span class="textDropDown">
									4
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxFive"
									(change)="validarStars()"/>
								<span class="textDropDown">
									5
								</span>
							</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="filtersBoxOrdersER" *ngIf="this.filters === true && this.urlActual === 'orders' && this.urlUser === 'er'">
	<div class="row">
		<div class="col-12 col-md-12 col-lg-12 col-xl-12" style="display: flex; justify-content: space-between;">
			<span class="filtersBoxTitle">
				Filters
			</span>
			<div class="icon-close" (click)="closeModal()">
			</div>

		</div>
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<div class="boxValues">
				<div class="row">
					<div class="col-12 col-md-12 col-lg-12 col-xl-12">

						<div class="selectStatus" (click)="openStatusDiv()" *ngIf="status === true">
							<div class="icon-right" *ngIf="openStatus === false">
							</div>
							<div class="icon-down" *ngIf="openStatus === true">
							</div>
							<span class="textDropDown">
								Status
							</span>
						</div>


								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxReturn"
										(change)="validarCasillasSeleccionadas()"
										/>
									<span class="textDropDown">
										Return
									</span>
								</div>
								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxCompleted"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										Completed
									</span>
								</div>

								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxInLocker"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										En Locker
									</span>
								</div>

								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxExtended"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										Extendido
									</span>
								</div>

								<div class="statusInLocker"  *ngIf="openStatus === true && status === true">
									<input
										type="checkbox"
										class="checkboxInDestinyLocker"
										id="cbox1"
										value="first_checkbox"
										[(ngModel)]="checkboxExpired"
										(change)="validarCasillasSeleccionadas()" />
									<span class="textDropDown">
										Expired
									</span>
								</div>



						<div class="selectStatus" (click)="openRateDiv()" *ngIf="rate === true">
							<div class="icon-right" *ngIf="openRate === false">
							</div>
							<div class="icon-down" *ngIf="openRate === true">
							</div>
							<span class="textDropDown">
								Calificacion
							</span>
						</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxOne"
									(change)="validarStars()"/>
								<span class="textDropDown">
									1
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxTwo"
									(change)="validarStars()"/>
								<span class="textDropDown">
									2
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxThree"
									(change)="validarStars()"/>
								<span class="textDropDown">
									3
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxFour"
									(change)="validarStars()"/>
								<span class="textDropDown">
									4
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true && rate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxFive"
									(change)="validarStars()"/>
								<span class="textDropDown">
									5
								</span>
							</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
