import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppClientService {
	public baseUrl: string = environment.url_server_backend;
	private token: string;
	private options: any;
	public temp: any;
	public data;
	constructor(private http: HttpClient) { }
	setToken(token: string) {
		this.token = token;
	}
	getToken() {
		return this.token ? this.token : localStorage.getItem('token');
	}

	async getQuerySchedulePackage(params:any){
		let options = {
			headers: new HttpHeaders({
				'Content-Type':"application/json",
				token: this.getToken()
			}),
			params: params
		}
		let url = this.baseUrl + '/server/package/query-schedule-package';
		return this.http.get(url,options).toPromise();
	}

	async getQueryGeneralPackage(params:any){
		let options = {
			headers: new HttpHeaders({
				'Content-Type':"application/json",
				token: this.getToken()
			}),
			params: params
		}
		let url = this.baseUrl + '/server/package/query-package-oxxo-to-oxxo';
		return this.http.get(url,options).toPromise();
	}
}
