import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng/api';
import { AdminClientService } from 'src/app/core/http/admin-client.service';

@Component({
  selector: 'app-dialog-update-user',
  templateUrl: './dialog-update-user.component.html',
  styleUrls: ['./dialog-update-user.component.scss']
})
export class DialogUpdateUserComponent implements OnInit, OnDestroy {
	updateUserForm: FormGroup;
	types: SelectItem[];
	isCompanyEmployeeIdDisabled: boolean = false; // Inicialmente habilitado
	public respError: boolean;
	userType;
	constructor(
		@Inject(MAT_DIALOG_DATA) private data: any,
		private formBuilder: FormBuilder,
		private adminService: AdminClientService,
		private dialogRef: MatDialogRef<DialogUpdateUserComponent>,
		private dialog: MatDialog,
		private toastr: ToastrService
	) {
		this.updateUserForm = this.formBuilder.group({
			userType: [{value:(this.data.type === 9 ? 'Admin' : (this.data.type === 11 ? 'E&R': ( this.data.type ===10 ? 'CCC' : 'NONE')) ),disabled: true},Validators.required],
			full_name: [{value:this.data.data.first_name, disabled:false},Validators.required],
			email : [{value: this.data.data.email ,disabled:true},Validators.required],
			password : [{value:'',disabled: false},Validators.required],
			confirm_password : [{value:'',disabled: false},Validators.required],
		});
	}

	isValidField(field:string):boolean {
		return this.updateUserForm.controls[field].errors && this.updateUserForm.controls[field].touched
	}

	get userTypeValue() {
		return this.updateUserForm.get('userType').value;
	}
	get fullNameValue() {
		return this.updateUserForm.get('full_name').value;
	}
	get emailValue() {
		return this.updateUserForm.get('email').value;
	}
	get passwordValue() {
		return this.updateUserForm.get('password').value;
	}
	get confirmPasswordValue() {
		return this.updateUserForm.get('confirm_password').value;
	}

	ngOnInit() {

	}
	ngOnDestroy(): void {
		this.dialogRef.close();
	}

	onSubmit() {
		if (this.updateUserForm.valid === true) {
			let obj ={
				language 			: localStorage.getItem('language'),
				data_employee: [ {
					password			: this.passwordValue,
					id_type_employee	: this.data.type,
					full_name 			: this.fullNameValue,
					email 				: this.emailValue
				}]
			}
			this.adminService.udpateEmployee(obj).then((res:any)=>{
				if(res.mensaje_return.CODE === 200){
					this.toastr.success(
						'Se deshabilitó el usuario correctamente',
						'Se ha actualizado',
						{
							toastClass: 'toast-extendida',
							timeOut: 5000,
							positionClass: 'toast-bottom-center',
						}
					);
					this.dialog.closeAll();
				}
				else{
					this.toastr.success(res.mensaje_return.ERROR_MENSAGGE, 'Oh, hubo un error', {
						toastClass: 'toast-erronea',
						timeOut: 5000,
						positionClass: 'toast-bottom-center',
					});
				}
			})
		}
	}

	getRandomChar() {
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const randomIndex = Math.floor(Math.random() * characters.length);
		return characters.charAt(randomIndex);
	}

	generatePassword(){
		let password = '';
		for (let i = 0; i < 10; i++) {
			password += this.getRandomChar();
		}
		this.updateUserForm.get('password').setValue(password);
		this.updateUserForm.get('confirm_password').setValue(password);
	}



	closeAll() {
	this.dialog.closeAll();
	}

}
