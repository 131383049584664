<div class="row">
    <!-- HEADER -->
    <div class="row header-graphic">
        <div class="col">
            <div class="header-txt">
                Packages delivery sizes
            </div>
        </div>
         <!-- SELECT -->
         <div class="col float-end">
            <select class="select-custome float-end" aria-label=".form-select-lg example">
                <option selected  class="option-custome text-center">2023</option>
                <option value="11" class="option-custome text-center">2022</option>
              </select>
        </div>

    </div>

    <!-- CHART -->
    <div id="chart" class="chart-custome">
        <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [dataLabels]="chartOptions.dataLabels"
            [plotOptions]="chartOptions.plotOptions"
            [yaxis]="chartOptions.yaxis"
            [legend]="chartOptions.legend"
            [fill]="chartOptions.fill"
            [stroke]="chartOptions.stroke"
            [tooltip]="chartOptions.tooltip"
            [xaxis]="chartOptions.xaxis">
        </apx-chart>
    </div>

</div>