import { Component, ViewChild } from '@angular/core';

import {
	ApexNonAxisChartSeries,
	ApexPlotOptions,
	ApexChart,
	ApexLegend,
	ApexResponsive,
	ChartComponent,
} from 'ng-apexcharts';

export type ChartOptions = {
	series: ApexNonAxisChartSeries;
	chart: ApexChart;
	labels: string[];
	colors: string[];
	legend: ApexLegend;
	plotOptions: ApexPlotOptions;
	responsive: ApexResponsive | ApexResponsive[];
};

@Component({
	selector: 'app-usage-state',
	templateUrl: './usage-state.component.html',
	styleUrls: ['./usage-state.component.scss'],
})
export class UsageStateComponent {
	@ViewChild('chart') chart: ChartComponent;
	public chartOptions: Partial<ChartOptions>;

	constructor() {
		this.chartOptions = {
			chart: {
				height: 290,
				type: 'donut',
			},
			series: [76, 67, 61],
			plotOptions: {
				pie: {
					startAngle: -90,
					endAngle: 90,
					offsetY: 20,
					dataLabels: {
						minAngleToShowLabel: 9999, // Establece un valor muy alto para que ninguna etiqueta se muestre
					},
				},
			},
			colors: [
				'var(--color-chart-300)',
				'var(--color-chart-200)',
				'var(--color-chart-100)',
			],
			labels: ['Puebla', 'Queretaro', 'Baja'],
			legend: {
				show: false,
				floating: false,
				fontSize: '16px',
				position: 'bottom',
				offsetX: 50,
				offsetY: 10,
				labels: {
					useSeriesColors: true,
				},
				formatter: function (seriesName, opts) {
					return (
						seriesName +
						':  ' +
						opts.w.globals.series[opts.seriesIndex]
					);
				},
				itemMargin: {
					horizontal: 3,
				},
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						legend: {
							show: false,
						},
					},
				},
			],
		};
	}
}
