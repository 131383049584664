<div class="container-graphic">
	<div class="header-graphic">
		<div class="row noMargins">
			<div class="col-12 col-md-12 col-lg-12 col-xl-12">
				<div class="header-txt">
					{{'clients.metricsClients.title' | translate}}
				</div>
			</div>
			<div class="col-12 col-md-12 col-lg-12 col-xl-12" id="chart">
				<apx-chart
					[series]="chartOptions.series"
					[chart]="chartOptions.chart"
					[xaxis]="chartOptions.xaxis"
					[stroke]="chartOptions.stroke"
					[tooltip]="chartOptions.tooltip"
					[dataLabels]="chartOptions.dataLabels"
					[legend]="chartOptions.legend"
					[markers]="chartOptions.markers"
					[grid]="chartOptions.grid"
					[yaxis]="chartOptions.yaxis"
					[title]="chartOptions.title"
					[responsive]="chartOptions.responsive"
				></apx-chart>
			</div>
		</div>
	</div>
</div>
