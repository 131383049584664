<div class="container">
	<div class="row noMargin">
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<div class="headerBox">
				<div class="headerTitle">
					Detalles de la URL
				</div>
				<div class="iconClose" (click)="closeModal()">

				</div>
			</div>
		</div>
		<div class="col-12">
			<hr style="margin:0px 0px 12px 0px">
		</div>
		<div class="col-12 col-md-12 col-lg-12 col-xl-12 noPaddings noMargin">
			<div class="detailBox">
				<div class="row noMargin" style="width:100%; display: flex;gap: 4px;">
					<div class="col-12 col-md-12 col-lg-12 col-xl-12 noPaddings noMargin">
						<div class="urlDetail" >
							<div class="titleBox1">
								<div class="title">
									URL
								</div>
							</div>
							<div class="titleBox2" >
								<div class="title">
									{{this.url}}
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-12 col-lg-12 col-xl-12 noPaddings noMargin">
						<div class="urlDetail">
							<div class="titleBox3">
								<div class="title">
									Tipo de Evento
								</div>
							</div>
							<div class="titleBox4">
								<div class="title">
									{{this.service}}
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-12 col-lg-12 col-xl-12 noPaddings noMargin">
						<div class="urlDetail">
							<div class="titleBox5">
								<div class="title">
									Json:
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row noPaddings noMargin">
		<div class="col-12 col-md-12 col-lg-12 col-xl-12 noPaddings noMargin">
			<div class="cardCode">
				<app-code-with-line-numbers [codeContent]="event_sent |json" ></app-code-with-line-numbers>
			</div>
		</div>
	</div>
</div>
