<!--? DROPDOWN CONTAINER  -->
<div class="dropdown-container">
    <!-- BLOCK 1 | CIRCLE PROFILE -->
    <div class="block-1" routerLink="account-settings/account">
        <!-- ICON ACCOUNT -->
        <div class="icon-profile">
            {{icon_profile | uppercase }}
        </div>

        <!-- ROLE | ACCOUNT -->
        <div class="role-profile-container">
            <!-- ROLE -->
            <a> {{'header-admin.role' | translate}} </a>

            <!-- PROFILE -->
            <a> {{'navbar.account' | translate}} </a>

        </div>

    </div>

    <!--? HR -->
    <div class="hr"></div>
    
    <!-- BLOCK 2 |  MENU 1 -->
    <div class="block-2" *ngFor="let item of dropMenu">
        <!--? SETTINGS -->
        <!-- ICON LINE -->
        <div class="icon-line" routerLink="settings/config-webhook">
            <!-- ICON SETTINGS -->
            <div class="icon-settings"></div>

            <!-- MENU LINK -->
            <a class="menu-dropdown"> {{'Webhook'}} </a>

        </div>

        <!--? TRANSLATE -->
        <div class="icon-line-translate">
            <!-- CONTAINER ICON -->
            <div class="container-icon-line">
                <!-- ICON TRANSLATE -->
                <div class="icon-translate" ></div>

                <!-- TXT -->
                <a class="menu-dropdown"> {{'header-admin.translator' | translate}} </a>

            </div>

            <!-- ICON CHEVRON -->
            <div class="icon-chevron-dropdown"></div>
            <!-- DROPDOWN TRANSLATE -->
            <div class="dropdown-translate">

                <!-- SPANISH -->
                <div class="container-txt-icon" [ngClass]=" {'language-selected-es' : langSelected === 'es' }"  (click)="selectLang('es')">
                    <!-- ICON ES -->
                    <div class="icon-es"></div>
                    
                    <!-- TXT DROPDOWN -->
                    <span class="txt-theme-dropdown">
						{{'header-admin.spanish' | translate}}
					</span>

                </div>

                <!-- ENGLISH -->
                <div class="container-txt-icon" [ngClass]=" {'language-selected-en' : langSelected === 'en' }" (click)="selectLang('en')">
                    <!-- ICON EN -->
                    <div class="icon-en"></div>

                    <!-- TXT -->
                    <span class="txt-theme-dropdown">
						{{'header-admin.english' | translate}}
					</span>

                </div>

            </div>

        </div>

        <!-- THEME -->
        <div class="icon-line-theme">
            <!-- ICON TXT -->
            <div class="container-icon-line">
                <!-- ICON THEME -->
                <div class="icon-theme" ></div>

                <!-- TXT -->
                <a class="menu-dropdown">{{'header-admin.appearance' | translate}} </a>

            </div>

            <div class="icon-chevron-dropdown"></div>
            <!-- DROPDOWN THEME -->
            <div class="dropdown-theme">
                <!-- LIGHT -->
                <div class="container-txt-icon-light" (click)="changeMode('light')">
                    <!-- ICON SUN -->
                    <div class="icon-sun"></div>

                    <!-- TXT -->
                    <span class="txt-theme-dropdown">{{'header-admin.light' | translate}}</span>
                </div>

                <!-- DARK -->
                <div class="container-txt-icon-dark" (click)="changeMode('dark')">
                    <!-- ICON MOON -->
                    <div class="icon-moon"></div>

                    <!-- TXT -->
                    <span class="txt-theme-dropdown">{{'header-admin.dark' | translate}}</span>

                </div>
            </div>
        </div>

         <!--? HR -->
        <div class="hr"></div>

        <!--! ITERATIONS -->
        <div class="icon-line" [routerLink]="item.route">
            <!-- MENU -->
            <div [class]="item.icon"></div>

            <a class="menu-dropdown"> {{ item.txt | translate }} </a>

        </div>

        <!-- VERSION -->
        <div class="icon-line" routerLink="version">
            <!-- ICON VERSION -->
            <div class="icon-version"></div>

            <a class="menu-dropdown">
				{{'header-admin.version' | translate}}
			</a>

        </div>
    </div>

     <!--? HR -->
    <div class="hr"></div>
    
    <!-- BLOCK 3 | LOGOUT -->
    <div class="block-3">
        
        <div class="icon-line" (click)="logout()">
            <!-- ICON LOGOUT -->
            <div class="icon-logout"></div>
            
            <a class="menu-dropdown">
				{{'header-admin.logout' | translate}}
            </a>

        </div>
    </div>

</div>
