<div class="w-100">
    <div class="card" style="height: 564px; width: 559px">
        <div class="card-body">
            <div class="card-title">
                <!-- HEADER -->
                <h5 class="card-title fw-semibold" style="text-align: left">
                    {{ "lockers.location" | translate | uppercase }}<br />
                </h5>

                <mat-card-content id="map-content">
                    <ng-container *ngIf="arrayMarker.length>0">
                      <!-- GOOGLE MAP -->
                      <app-google-maps latitud="{{latitud}}" longitud="{{longitud}}" [zoom]="zoom" [coords]="arrayMarker" ></app-google-maps>
                    </ng-container>
                </mat-card-content> 
            </div>
        </div>
    </div>
</div>