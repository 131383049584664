import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { tableHeader } from 'src/app/core/models';

@Component({
	selector: 'new-table',
	templateUrl: './table-new.component.html',
	styleUrls: ['./table-new.component.scss'],
})
export class TableNewComponent {
	//!IMPORTANT: Table does't work without pageLenght | tableHeader | TableData
	//* DISPLAY COMPONENTS ---------------------------------------------->
	/*
		if you want to use any of these components
		just add the parameter to your parent component
  */
	// MAIN FILTER
	@Input() mainFilter: boolean = false;
	// PAGINATION
	@Input() pagination: boolean = false;
	// CALENDAR COMPONENT
	@Input() calendar: boolean = false;
	// FILTERFUNNEL COMPONENT
	@Input() filterFunnel: boolean = false;
	// BTN EXPORT COMPONENT
	@Input() btnExport: boolean = false;
	// Show Arrows btn filters
	@Input() sortBtn: boolean = false;
	tooltipPosition:string = 'center'


	//* TABLE DATA ------------------------------------------------------->
	/*
		Send table header <th></th>
		and table data <td></td>
	*/
	// TABLE HEADER
	@Input() tableHeader: tableHeader[] = [];
	// TABLE DATA
	@Input() tableData: any[] = [];

	//* PROPERTIES OF TABLE ---------------------------------------------->
	// Set page lenght
	@Input() pageLenght: number;
	// Set pagination
	public page: number = 0;
	// Number pagination
	public groupCount: number = 1;
	// Value filter
	public searchTxt: string | number;

	//* PROPERTIES COLUMN LENGHT ---------------------------------------------->
	// Column lenght properties
	columnlength: number;
	// Get column lenght
	get columnLenght() {
		return this.tableHeader.length;
	}

	tableLenght: number;

	constructor(private router:Router){

	}

	// OnInit
	ngOnInit() {
		// Initializing the length of the columns
		this.columnlength = this.columnLenght;
		this.tableLenght = this.tableData.length
	}

	//? STARS ---------------------------------------------------------------------->
	stars: number[] = [1, 2, 3, 4, 5];
	rating: number;

	//? TOOLTIPS ---------------------------------------------------------------------->
	positionTooltip:string = 'table tooltip';

	//? ORDER ARRAY --------------------------------------------------------------------------->
	// Control variable for ascending / descending order
	public ascending: boolean = true;
	// METHOD SORT
	sortArray(filtro: string) {
		this.ascending = !this.ascending;

		// Create a copy of the original array
		const sortedData = [...this.tableData];
		sortedData.sort((a, b) => {
			let comparison = 0;
			if (a[filtro] < b[filtro]) {
				comparison = -1;
			} else if (a[filtro] > b[filtro]) {
				comparison = 1;
			}

			if (!this.ascending) {
				comparison *= -1;
			}
			return comparison;
		});

		// Update original array with the order data
		this.tableData = sortedData;
	}

	//? PAGINATION ------------------------------------>
	// NEXT PAGE
	nextPage() {
		if (this.tableData.length - this.pageLenght > this.page) {
			this.page += this.pageLenght;
			this.groupCount++;
		}
	}

	// PREV PAGE
	prevPage() {
		if (this.page > 0) {
			this.page -= this.pageLenght;
			this.groupCount--;
		}
	}

	truncateWords(text: string, limit: number): string[] {
		const words = text.split(',');

		if (words.length > limit) {
		  words.splice(limit);
		}

		return words;
	}
	redirectPage(event){
		window.open(event);
	}
}
