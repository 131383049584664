<div *ngFor="let item of arrayAlert"
    class="{{item.type}}">
    <div class="container-message">
        <div class="bg-icon">
            <div class="icon-message"></div>
        </div>
        <div class="spans">
            <span class="title-txt">{{item.field | titlecase }} {{item.title | translate}} </span>
            <span class="description-txt"> {{item.description | translate }} </span>
        </div>
    </div>
</div>