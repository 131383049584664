import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-tooltip-end-sm',
	templateUrl: './tooltip-end-sm.component.html',
	styleUrls: ['./tooltip-end-sm.component.scss'],
})
export class TooltipEndSmComponent {
	@Input() tooltip: string;
}
