import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Calendar } from 'primeng/calendar';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { CalendarComponent } from 'src/app/shared/components/calendar/calendar.component';
import { Dialog } from 'primeng/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogDeliverPersonallyComponent } from '../dialog-deliver-personally/dialog-deliver-personally.component';

@Component({
	selector: 'app-estatus-table-order-admin',
	templateUrl: './estatus-table-order-admin.component.html',
	styleUrls: ['./estatus-table-order-admin.component.scss'],
})
export class EstatusTableOrderAdminComponent implements ICellRendererAngularComp, OnInit, AfterViewInit{
	estatus: string = '';
	status_package: number;
	id_status_code_package: number;
	extend_package: number;
	dataSendUpdate;
	parametros;
	data;
	id_status_auditoria:number;
	description_status_auditoria:string;
	topic_name:string;
	activity_topic;
	@ViewChild('calendar') calendar: Calendar;
	@ViewChild(Dialog) dialog: Dialog;
	public display: boolean = false;
	idUserType = parseInt(localStorage.getItem('user_type'));

	ngOnInit(): void {}
	constructor( private matdialog: MatDialog, private adminService: AdminClientService, private translate: TranslateService) {
	}

	agInit(params: ICellRendererParams): void {
		this.activity_topic = params.data.id_topic_audit;
		this.data = params.data;
		this.parametros = params;
		this.status_package = params.data.status_package ;
		this.id_status_code_package = params.data.id_status_code_package;
		this.estatus = params.data.status || params.data.name_status;
		this.extend_package = params.data.extended_package;

		this.id_status_auditoria = params.data.id_status
		this.description_status_auditoria = params.data.nombre_status;

		this.topic_name = params.data.topic_name;
	}

	refresh(params: ICellRendererParams) {
		return false;
	}

	abrirModalFecha() {
		const dialogRef = this.matdialog.open(CalendarComponent, {
			width: '366px',
			height: '382px',
		});
	}

	openDialogDelivery(){
		const dialogRef = this.matdialog.open(DialogDeliverPersonallyComponent, {
			width: '361px',
			height: '262px',
			data: this.data
		});
	}

	ngAfterViewInit() {}

	openDialog() {
		this.display = true;
	}
	SaberQueEstatusEs(estatus) {}
}
