<div class="filtersBox" *ngIf="this.filters === true">
	<div class="row">
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<span class="filtersBoxTitle">
				Filters
			</span>
		</div>
		<div class="col-12 col-md-12 col-lg-12 col-xl-12">
			<div class="boxValues">
				<div class="row">
					<div class="col-12 col-md-12 col-lg-12 col-xl-12">
						<div class="selectStatus" (click)="openRateDiv()">
							<div class="icon-right" *ngIf="openRate === false">
							</div>
							<div class="icon-down" *ngIf="openRate === true">
							</div>
							<span class="textDropDown">
								Calificacion
							</span>
						</div>
							<div class="rates"  *ngIf="openRate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxOne"
									(change)="validarStars()"/>
								<span class="textDropDown">
									1
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxTwo"
									(change)="validarStars()"/>
								<span class="textDropDown">
									2
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxThree"
									(change)="validarStars()"/>
								<span class="textDropDown">
									3
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxFour"
									(change)="validarStars()"/>
								<span class="textDropDown">
									4
								</span>
							</div>
							<div class="rates"  *ngIf="openRate === true">
								<input
									type="checkbox"
									class="checkboxInDestinyLocker"
									id="cbox1"
									value="first_checkbox"
									[(ngModel)]="checkboxFive"
									(change)="validarStars()"/>
								<span class="textDropDown">
									5
								</span>
							</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

