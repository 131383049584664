import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-average-collection',
	templateUrl: './average-collection.component.html',
	styleUrls: ['./average-collection.component.scss'],
})
export class AverageCollectionComponent implements OnInit {
	@Input() metrics!: any[];
	avg_time;

	ngOnInit(): void {
		this.getMetrics();
	}

	async getMetrics() {
		let [hours, minutes, seconds] =
			this.metrics[0][1][0].avg_time.split(':');
		this.avg_time = hours + ':' + minutes;
		this.metrics[0][1][0].avg_time_refund == null
			? (this.metrics[0][1][0].avg_time_refund = '--:--')
			: null;
	}
}
