<!--* CONTAINER KPIS -->
<div class="row justify-content-around">
  
  <!--? KPI 1 -->
  <div class="col-2 container-kpi">
    <div class="row">
      <!--HEADER -->
      <div class="col-12 height-kpi-header">
        <p class="font-header-kpi float-end">
          <img src="\assets\images\HomeDepot\arrow.svg" style="height: 8px;">
          + {{this.datos[0].percent_deliveries_all}}%
        </p>
      </div>
      <!-- BODY -->
      <div class="col-12 height-kpi">
        <!-- KPI -->
        <strong class="font-kpi">
          {{consumerMetrics[0].package_delivery}}
        </strong>
      </div>
      <!-- FOOTER -->
      <div class="col-12 height-info-kpi">
        <small class="font-footer-kpi"> {{ 'kpi.kpi-footer-1' | translate }} </small>
      </div>
    </div>


  </div>
  
  <!--? KPI 2 -->
  <div class="col-2 container-kpi">
    <div class="row">
      <!--HEADER -->
      <div class="col-12 height-kpi-header">
        <p class="font-header-kpi float-end">
          <img src="\assets\images\HomeDepot\arrow.svg" style="height: 8px;">
          +{{this.datos[0].percent_return_all}}%
        </p>
      </div>
      <!-- BODY -->
      <div class="col-12 height-kpi">
        <!-- KPI -->
        <strong class="font-kpi">
          {{consumerMetrics[0].package_recollected}} 
        </strong>
      </div>
      <!-- FOOTER -->
      <div class="col-12 height-info-kpi">
        <small class="font-footer-kpi"> {{ 'kpi.kpi-footer-2' | translate }} </small>
      </div>
    </div>

  </div>
  
  <!--? KPI 3 -->
  <div class="col-2 container-kpi">
    <div class="row">
      <!--HEADER -->
      <div class="col-12 height-kpi-header">
        <p class="font-header-kpi float-end">
          <img src="\assets\images\HomeDepot\arrow.svg" style="height: 8px;">+
          {{this.datos[0].percent_deliveries_today}}%
        </p>
      </div>
      <!-- BODY -->
      <div class="col-12 height-kpi">
        <!-- KPI -->
        <strong class="font-kpi">
          {{consumerMetrics[0].package_today}}
        </strong>
      </div>
      <!-- FOOTER -->
      <div class="col-12 height-info-kpi">
        <small class="font-footer-kpi"> {{ 'kpi.kpi-footer-3' | translate }} </small>
      </div>
    </div>

  </div>
  
  <!--? KPI 4 -->
  <div class="col-2 container-kpi">
    <div class="row">
      <!--HEADER -->
      <div class="col-12 height-kpi-header">
        <p class="font-header-kpi float-end">
          <img src="\assets\images\HomeDepot\arrow.svg" style="height: 8px;">+ 
          {{this.datos[0].percent_collections_today}}%
        </p>
      </div>
      <!-- BODY -->
      <div class="col-12 height-kpi">
        <!-- KPI -->
        <strong class="font-kpi">
          {{consumerMetrics[0].package_today_pickup}}
        </strong>
      </div>
      <!-- FOOTER -->
      <div class="col-12 height-info-kpi">
        <small class="font-footer-kpi"> {{ 'kpi.kpi-footer-4' | translate }} </small>
      </div>
    </div>

  </div>
  
  <!--? KPI 5 -->
  <div class="col-2 container-kpi">
    <div class="row">
      <!--HEADER -->
      <div class="col-12 height-kpi-header">
        <p class="font-header-kpi float-end">
          <img src="\assets\images\HomeDepot\arrow.svg" style="height: 8px;"> 
          + {{this.datos[0].percent_deliveries_all}}%
        </p>
      </div>
      <!-- BODY -->
      <div class="col-12 height-kpi">
        <!-- KPI -->
        <strong class="font-kpi">
          160,000 <!--! Falta KPI -->
        </strong>
      </div>
      <!-- FOOTER -->
      <div class="col-12 height-info-kpi">
        <small class="font-footer-kpi"> {{ 'kpi.kpi-footer-5' | translate }} </small>
      </div>
    </div>

  </div>
  
  <!--? KPI 5 -->
  <div class="col-2 container-kpi">
    <div class="row">
      <!--HEADER -->
      <div class="col-12 height-kpi-header">
        <p class="font-header-kpi float-end">
          <img src="\assets\images\HomeDepot\arrow.svg" style="height: 8px;"> 
          + {{this.datos[0].percent_deliveries_all}}%
        </p>
      </div>
      <!-- BODY -->
      <div class="col-12 height-kpi">
        <!-- KPI -->
        <strong class="font-kpi">
          42,000 <!--! Falta KPI -->
        </strong>
      </div>
      <!-- FOOTER -->
      <div class="col-12 height-info-kpi">
        <small class="font-footer-kpi"> {{ 'kpi.kpi-footer-5' | translate }} </small>
      </div>
    </div>

  </div>




</div>

<!--* CONTAINER GRAPHICS & GOOGLE MAPS  -->
<div class="row justify-content-around">
  <!--? GRAPHICS -->
  <!-- FILTER BY YEAR -->
  <div class="col-md-12 col-lg-6">
    <app-table-filter-by-year></app-table-filter-by-year>
  </div>
  <!-- FILTER BY DAY -->
  <div class="col-md-12 col-lg-6">
    <app-table-filter-by-day></app-table-filter-by-day>
  </div>
  
  <!-- ? GOOGLE MAPS -->
  <div class="col-12" style="margin-top:10px;">
    <!-- CARD -->
    <mat-card class="cardmap">
      <!-- HEADER -->
        <div class="row">
          <div class="col header-txt">
            {{'general.lockerLocation' | translate}}
          </div>
        </div>

      <mat-card-content id="map-content" class="mt-3">
        <ng-container *ngIf="arrayMarker.length>0">
          <!-- MAPS -->
          <app-google-maps latitud="{{latitud}}" longitud="{{longitud}}" [zoom]="zoom" [coords]="arrayMarker"></app-google-maps>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
</div>


    
