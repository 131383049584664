<div class="card text-center" style="height: auto; width: 100%">
	<div class="card-body">
		<div class="row">
			<div class="col-sm-5 col-md-5 col-lg-5 col-xl-5">
				<h5 class="">
					{{ "lockers.used_size" | translate | uppercase }}
				</h5>
			</div>
			<div class="col-sm-7 col-md-7 col-lg-7 col-xl-7">
				<div class="row">
					<div class="col-4 col-md-4 col-lg-4 col-xl-4" *ngFor="let item of metrics[1][1]; let i = index">
						<img src="\assets\images\HomeDepot\arrow.svg" alt="" class="arrow">
						<div style="font-size: 18px;">
							<b class="colorsize">
								{{item.compartment_name}}
							</b> 
							({{item.countCompartment}})
						</div>
					</div>
				</div>
				<i class="fas fa-info-circle famarg" matTooltip="{{'info.lockers.historical.most_used_compartment_size' | translate }}" matTooltipPosition="above"></i>


			</div>
		</div>
	</div>
</div>
