<div class="container-overview">
    <div class="txt-header">
        <span class="title">{{'overview.welcomeHeader' | translate}}</span>
        <span class="title">{{ username | titlecase }}</span>
    </div>
    
    <app-card
    class="animate__animated animate__fadeIn"
    [gridStyle]="gridOverview"
    [dataCardSm]="dataCardSm"
    ></app-card>
    
    <div class="grid-charts">
        <!-- CHARTS -->
    
        <!-- CARD 7 -->
        <div class="card-grid card-7">
            <app-historical-grid2></app-historical-grid2>
        </div>
        <!-- CARD 8 -->
        <div class="card-grid card-8">
            <app-delivered-returned-packages></app-delivered-returned-packages>
        </div>
        <!-- CARD 11 -->
        <div class="card-grid card-9">
            <app-ratings></app-ratings>
        </div>
        <!-- CARD 12 -->
        <div class="card-grid-10 card-10">
            <app-headquarters-grid2></app-headquarters-grid2>
        </div>
        <!-- CARD 13 -->
        <div class="card-grid card-11">
            <app-delivered-packages-size></app-delivered-packages-size>
        </div>
    </div>
</div>