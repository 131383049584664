import { Component,Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CardData } from 'src/app/core/models';


@Component({
  selector: 'cards-overview-users',
  templateUrl: './cards-overview.component.html',
  styleUrls: ['./cards-overview.component.scss']
})
export class CardsOverviewComponent  implements OnInit, OnChanges, OnDestroy {
	@Input() colorBg: string = '--color-container';
	@Input() dataCard: CardData[] = [];
	@Input() footer: boolean;
	@Input() header: boolean;
	@Input() body: boolean;
	private storeCardsIds: string | null = null;
	showTooltip:boolean=false;
	idCard:number;

	constructor(private translate: TranslateService){
	}

	ngOnInit() {
		this.translate.use(localStorage.getItem('language'));
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
		})
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.translate.use(localStorage.getItem('language'));
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
		})
	}

	ngOnDestroy() {
		if (this.storeCardsIds) {
			const cardsIds = this.dataCard.map((card) => card.id);
			localStorage.setItem('cardsIds', JSON.stringify(cardsIds));
		}
	}

	activateTooltip(i){
		this.idCard = i;
		this.showTooltip = true;
	}
	desactivateToolTip(i){
		this.idCard = null;
		this.showTooltip = false;
	}
}
