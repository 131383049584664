// Angular
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import {
	HttpClient,
	HttpClientModule,
	HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { NgModule } from '@angular/core';
// Module
import { AppRoutingModule } from './app.routing';
import { MaterialModule } from './shared/libraries/angular-material/material.module';
import { AgGridModule } from 'ag-grid-angular';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ToastrModule } from 'ngx-toastr';
// Translate
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// Components
import { AppComponent } from './app.component';
import { DialogsModule } from './modules/admin-client/components/dialogs-admin/dialogs.module';
//Services
import { Interceptor } from './core/interceptors/interceptor.service';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		CommonModule,
		RouterModule,
		FormsModule,
		GoogleMapsModule,
		HttpClientModule,
		NgApexchartsModule,
		AppRoutingModule,
		DialogsModule,
		ToastrModule.forRoot(),
		MatDatepickerModule,
		MatInputModule,
		MatFormFieldModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MaterialModule,
		CalendarModule,
		DropdownModule,
		AgGridModule
	],
	// providers: [DatePipe],
	providers: [
		{
			provide: HTTP_INTERCEPTORS, // Proporcionar el interceptor
			useClass: Interceptor, // Usar la clase del interceptor
			multi: true, // Permitir la inyección múltiple de interceptores
		},
		DatePipe, // Agregar el provider del DatePipe
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/languaje/', '.json');
}
