//ANGULAR
import { Component, ViewChild } from '@angular/core';
// APEXCHARTS
import {
	ApexAxisChartSeries,
	ApexChart,
	ChartComponent,
	ApexDataLabels,
	ApexPlotOptions,
	ApexYAxis,
	ApexLegend,
	ApexStroke,
	ApexXAxis,
	ApexFill,
	ApexTooltip,
} from 'ng-apexcharts';

//Tipos de ApexCharts, que son utilizados para definir opciones de gráficos.
export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	yaxis: ApexYAxis;
	xaxis: ApexXAxis;
	fill: ApexFill;
	tooltip: ApexTooltip;
	stroke: ApexStroke;
	legend: ApexLegend;
};
@Component({
	selector: 'app-delivered-returned-packages',
	templateUrl: './delivered-returned-packages.component.html',
	styleUrls: ['./delivered-returned-packages.component.scss'],
})
export class DeliveredReturnedPackagesComponent {
	tooltipHistorical: string;

	@ViewChild('chart') chart: ChartComponent;
	public chartOptions: Partial<ChartOptions>;

	constructor() {
		this.chartOptions = {
			legend: {
				position: 'top',
				show: false,
			},
			series: [
				{
					name: 'Recolectados',
					data: [
						400, 355, 257, 156, 261, 358, 163, 261, 358, 163, 526,
						200,
					],
					color: 'var(--color-chart-300)',
				},
				{
					name: 'Devueltos',
					data: [
						200, 150, 100, 280, 287, 105, 391, 600, 200, 300, 142,
						130,
					],
					color: 'var(--color-chart-200)',
				},
			],
			chart: {
				type: 'bar',
				width: 699,
				height: 289,
				stacked: true,
				toolbar: {
					show: false, // Oculta el menú
				},
				zoom: {
					enabled: true,
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '65%',
					borderRadius: 3, // ajusta el valor según lo necesites
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				show: true,
				width: 2,
				colors: ['transparent'],
			},
			xaxis: {
				categories: [
					'Jan.',
					'Feb.',
					'Mar.',
					'Apr.',
					'May.',
					'Jun.',
					'Jul.',
					'Aug.',
					'Sep.',
					'Oct.',
					'Nov.',
					'Dec.',
				],
				labels: {
					style: {
						colors: [
							'var(--color-primary-300',
							'var(--color-primary-300',
							'var(--color-primary-300',
							'var(--color-primary-300',
							'var(--color-primary-300',
							'var(--color-primary-300',
							'var(--color-primary-300',
							'var(--color-primary-300',
							'var(--color-primary-300',
							'var(--color-primary-300',
							'var(--color-primary-300',
							'var(--color-primary-300',
						],
						fontSize: 'var(--font-c2)',
						fontFamily: 'var(--primary-font);',
					},
				},
			},
			yaxis: {
				labels: {
					style: {
						colors: ['var(--color-primary-300'],
						fontSize: 'var(--font-c2)',
						fontFamily: 'var(--primary-font);',
					},
				},

				tickAmount: (400 - 50) / 50, // Este cálculo muestra divisiones cada 50 unidades
			},
			fill: {
				opacity: 1,
			},
			tooltip: {
				y: {
					formatter: function (val) {
						return '$ ' + val + ' thousands';
					},
				},
			},
		};
	}
}
