import { Component, Input, OnInit } from '@angular/core';
import Highcharts from 'highcharts';

@Component({
	selector: 'app-chart-bar-admin',
	templateUrl: './chart-bar-admin.component.html',
	styleUrls: ['./chart-bar-admin.component.scss'],
})
export class ChartBarAdminComponent implements OnInit {
	chartOptions: {};
	lang: string;
	Highcharts = Highcharts;
	@Input() arrayName;
	@Input() type;
	months;
	data = [];
	categories = [];
	total = 0;
	cant1 = [];
	cant2 = [];
	cant3 = [];
	c1;
	c2;
	c3;
	c4;
	c5;
	widthgraphics;
	columns = [];
	datar = [];
	constructor() {}

	ngOnInit() {
		this.lang = localStorage.getItem('language');
		this.lang == 'EN'
			? (this.months = [
					'Jan',
					'Feb',
					'Mar',
					'Apr',
					'May',
					'Jun',
					'Jul',
					'Aug',
					'Sep',
					'Oct',
					'Nov',
					'Dec',
			  ])
			: (this.months = [
					'Ene',
					'Feb',
					'Mar',
					'Abr',
					'May',
					'Jun',
					'Jul',
					'Ago',
					'Sep',
					'Oct',
					'Nov',
					'Dic',
			  ]);
	}

	ngOnChanges() {
		if (this.type == 'orders' && this.arrayName) {
			if (this.arrayName.length != 0 && this.categories.length == 0) {
				this.arrayName.forEach((element: any) => {
					this.categories.push(element.locker_name);
					element.MONTH.forEach((e: any, i) => {
						this.cant1.length <= 11
							? this.cant1.push(e.cant)
							: this.cant2.length <= 11
							? this.cant2.push(e.cant)
							: this.cant3.push(e.cant);
					});
				});
			}

			if (this.arrayName != '') {
				this.chartOptions = {
					chart: {
						type: 'column',
						//inverted: true
					},
					title: {
						text: '',
					},
					xAxis: {
						categories: this.months,
						crosshair: true,
						labels: {
							style: {
								color: '#000000',
								//fontFamily: "Font-Roboto",
								fontSize: '14px',
								fontWeight: 'bold',
							},
						},
					},
					yAxis: {
						min: 0,
						title: {
							text: '',
						},
					},
					plotOptions: {
						series: {
							borderRadius: 6,
							pointWidth: 7,
						},
						column: {
							pointPadding: 0.01,
							borderWidth: 0,
						},
					},
					colors: [
						'#00C48C',
						'#BB6BD9',
						'#96C0FF',
						'#7262FD',
						'#65789B',
						'#F96302',
					],
					series: [
						{
							name: this.categories[0],
							data: this.cant1,
							dataLabels: {
								enabled: true,
							},
						},
						{
							name: this.categories[1],
							data: this.cant2,
							dataLabels: {
								enabled: true,
							},
						},
						{
							name: this.categories[2],
							data: this.cant3,
							dataLabels: {
								enabled: true,
							},
						},
					],
					credits: {
						enabled: false,
					},
				};
			} else {
				this.arrayName = [];
				this.data = [];
			}
		} else {
			if (this.arrayName && this.arrayName.length != 0) {
				this.arrayName = JSON.parse(this.arrayName);
				this.arrayName.forEach((e) => {
					this.columns.push(e.compartment_name);
					this.datar.push({
						name: e.compartment_name,
						y: e.cantidad,
						drilldown: e.compartment_name,
					});
				});
				this.chartOptions = {
					chart: {
						type: 'column',
						inverted: true,
					},
					title: {
						text: '',
					},
					accesibility: {
						announceNewData: {
							enabled: true,
						},
					},
					xAxis: {
						type: 'category',
						labels: {
							style: {
								color: '#000000',
								//fontFamily: "Font-Roboto",
								fontSize: '14px',
								fontWeight: 'bold',
							},
						},
					},
					yAxis: {
						visible: false,
						title: {
							text: '',
						},
					},
					legend: {
						enabled: false,
					},
					plotOptions: {
						series: {
							borderWidth: 6,
							pointWidth: 3,
							dataLabels: {
								enabled: true,
								format: '{point.y:.1f}',
							},
						},
					},
					colors: [
						'#00C48C',
						'#BB6BD9',
						'#96C0FF',
						'#7262FD',
						'#65789B',
						'#F96302',
					],
					series: [
						{
							name: 'Orders',
							colorByPoint: true,
							data: this.datar,
							borderRadius: 7,
						},
					],
					credits: {
						enabled: false,
					},
				};
			}
		}
	}
}
