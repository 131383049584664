import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class LogsService {
	public baseUrl: string = environment.url_server_backend;
	private token: string;

	setToken(token: string) {
		this.token = token;
	}

	getToken() {
		return this.token ? this.token : localStorage.getItem('token');
	}
	constructor(private http: HttpClient, public router: Router) {}

	async getNotifications(params: any) {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params: params,
		};
		let url = this.baseUrl + '/server/logs/log-order';
		return await this.http.get(url, options).toPromise();
	}
	async getUsers(params: any) {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				token: this.getToken(),
			}),
			params: params,
		};
		let url = this.baseUrl + '/server/logs/log-user';
		return await this.http.get(url, options).toPromise();
	}
}
