import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CardData } from 'src/app/core/models';

@Component({
	selector: 'app-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent{
	@Input() tooltip:string;
	@Input() showTooltip:boolean;
	arrTooltip: CardData[] =[];
	constructor(private translate: TranslateService){
		this.translate.use(localStorage.getItem('language').toLocaleLowerCase());
	}
}
