import { HttpClient } from '@angular/common/http';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GridOptions } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { AdminClientService } from 'src/app/core/http/admin-client.service';
import { TranslateService } from '@ngx-translate/core';
import { DateTextComponent } from '../date-text/date-text.component';
import { EstatusTableOrderAdminComponent } from '../estatus-table-order-admin/estatus-table-order-admin.component';
import { Component, Inject, Input } from '@angular/core';

@Component({
	selector: 'app-dialog-audit-user',
	templateUrl: './dialog-audit-user.component.html',
	styleUrls: ['./dialog-audit-user.component.scss'],
})
export class DialogAuditUserComponent {
	@Input() dataSend;
	rowDataEmployee: any[];
	rowDataPackage: any[];

	gridOptionsPackage: GridOptions;
	gridOptionsEmployee: GridOptions;

	viewUserInformation = true;
	viewQrInformation = false;
	expiresDate: Date;
	status;

	constructor(private dialogRef: MatDialogRef<DialogAuditUserComponent>, private http: HttpClient, private toastr: ToastrService, @Inject(MAT_DIALOG_DATA) private data: any, 	private adminService: AdminClientService, private translate: TranslateService) {
		this.rowDataPackage = data.audit_package;
		this.rowDataEmployee = data.audit_employee;
		this.gridOptionsPackage = {
			columnDefs: [
				{
					headerName: this.translate.instant('table-audit.idEvent'),
					field: 'id_package',
					width: 120,
					sortable: true,
					cellClass:'AuditUserClass'
				},
				{
					headerName: this.translate.instant('table-audit.tracking'),
					field: 'tracking_number',
					width: 100,
					sortable: true,
					cellClass:'AuditUserClass'
				},
				{
					headerName: this.translate.instant('table-audit.lockerID'),
					field: 'locker_name',
					width: 164,
					sortable: true,
					cellClass:'AuditUserClass'
				},
				{
					headerName: this.translate.instant('table-audit.eventName'),
					field: 'id_status',
					width: 130,
					sortable: true,
					cellClass:'AuditUserClass',
					cellRenderer: EstatusTableOrderAdminComponent
				},
				{
					headerName: this.translate.instant('table-audit.eventDate'),
					field: 'registration_date',
					width: 150,
					sortable: true,
					cellClass:'AuditUserClass',
					cellRenderer: DateTextComponent
				},
			],
			animateRows: true,
			pagination: true,
			paginationPageSize: 10,
			rowHeight:59,
			headerHeight:59,
			paginationAutoPageSize: true,
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`,
		}

		this.gridOptionsEmployee = {
			columnDefs: [
				{
					headerName: this.translate.instant('table-audit.idEvent'),
					field: 'id_audit_company_employee',
					width: 130,
					cellClass:'AuditUserClass',
					sortable: true,
					cellRenderer: this.centeredCellRenderer,
				},
				{
					headerName: this.translate.instant('table-audit.lockerID'),
					field: 'locker_name',
					width: 200,
					sortable: true,
					cellClass:'AuditUserClass'
					// cellRenderer: this.centeredCellRenderer
				},
				{
					headerName: this.translate.instant('table-audit.eventName'),
					field: 'topic_name',
					width: 180,
					sortable: true,
					cellClass:'AuditUserClass',
					cellRenderer: EstatusTableOrderAdminComponent
				},
				{
					headerName: this.translate.instant('table-audit.eventDate'),
					field: 'registration_date',
					sortable: true,
					cellClass:'AuditUserClass',
					width: 154,
					cellRenderer: DateTextComponent
				},
			],
			animateRows: true,
			pagination: true,
			paginationPageSize: 9,
			paginationAutoPageSize: true,
			rowHeight:59,
			headerHeight:59,
			overlayNoRowsTemplate:
			`<div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-info-circle icon-no-info" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
					</svg>
				</div>
				<div class="labelNoRegistros">
					Aun no hay registros, <br>
					intentalo mas tarde
				</div>
			</div>`,
		}
	}

	ngOnInit(): void {
	}
	closeModal() {
		this.dialogRef.close();
	}
	ViewUserInformation() {
		this.viewUserInformation = true;
		this.viewQrInformation = false;
	}
	ViewQrInformation() {
		this.viewUserInformation = false;
		this.viewQrInformation = true;
	}
	centeredCellRenderer(params) {
		const cellValue = params.value;
		const cellDiv = document.createElement('div');
		cellDiv.style.display = 'flex';
		cellDiv.style.alignItems = 'center';
		cellDiv.style.justifyContent = 'center';
		cellDiv.style.height = '100%';
		cellDiv.innerText = cellValue;
		return cellDiv;
	}
}
