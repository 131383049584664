import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'button-create-carrier',
	templateUrl: './button-create-carrier.component.html',
	styleUrls: ['./button-create-carrier.component.scss']
})
export class ButtonCreateCarrierComponent implements OnInit {

	constructor(private translate: TranslateService) {
		this.translate.use(localStorage.getItem('language').toLocaleLowerCase());
	}
	ngOnInit(): void {
	}

}
