import { Component } from '@angular/core';

@Component({
	selector: 'calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent {
	// TODAY
	public currentDate: Date;
	// DAYS OF WEEK
	daysWeek: string[] = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	];
	// MONTH AND YEAR
	public currentMonth: string;
	public currentYear: number;
	public firstDay: number;
	//DAYS ARRAY
	public get daysInMonth(): Date[] {
		const days: Date[] = [];
		const daysInMonth: number = new Date(
			this.currentDate.getFullYear(),
			this.currentDate.getMonth() + 1,
			0
		).getDate();

		for (let i = 1; i <= daysInMonth; i++) {
			const date = new Date(
				this.currentDate.getFullYear(),
				this.currentDate.getMonth(),
				i
			);
			days.push(date);
		}
		return days;
	}

	//! ----------------------------------------selected day---------------------------------------------->
	// Selected dates string
	public dateSelected: string = '';
	// SELECTED DATES
	public startDate: Date | null;
	public endDate: Date | null;

	// SELECT DAY
	selectDay(date: Date) {
		if (!this.startDate) {
			// If no start date is selected, set it as the selected date
			this.startDate = date;
			this.endDate = null; // Reset the end date
		} else if (!this.endDate) {
			// If start date is already selected but no end date, set the current date as the end date
			this.endDate = date;
		} else {
			// If both start and end dates are already selected, reset the selected dates
			this.startDate = null;
			this.endDate = null;
		}
	}
	//! ----------------------------------------selected day---------------------------------------------->
	constructor() {
		// START IN CURRENT DATE
		this.currentDate = new Date();
		// SET INITIAL MONTH AND YEAR
		this.currentMonth = this.currentDate.toLocaleString('es-MX', {
			month: 'short',
		});
		// YEAR
		this.currentYear = this.currentDate.getFullYear();
		// GET FIRST DAY OF MONTH
		this.firstDay = new Date(
			this.currentDate.getFullYear(),
			this.currentDate.getMonth()
		).getDay();
		// INITIALIZE SELECTED DATES AS NULL
		this.startDate = null;
		this.endDate = null;
	}
	// ADD A MONTH IN CURRENT DATE
	nextMonth() {
		this.currentDate.setMonth(this.currentDate.getMonth() + 1);
		this.currentMonth = this.currentDate.toLocaleString('es-MX', {
			month: 'short',
		});
		this.currentYear = this.currentDate.getFullYear();
		this.firstDay = new Date(
			this.currentDate.getFullYear(),
			this.currentDate.getMonth()
		).getDay();
	}

	// SUBTRACT ONE MONTH FROM THE CURRENT DATE
	prevMonth() {
		this.currentDate.setMonth(this.currentDate.getMonth() - 1);
		this.currentMonth = this.currentDate.toLocaleString('es-MX', {
			month: 'short',
		});
		this.currentYear = this.currentDate.getFullYear();
		this.firstDay = new Date(
			this.currentDate.getFullYear(),
			this.currentDate.getMonth()
		).getDay();
	}
}
