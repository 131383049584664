import { Injectable,  OnChanges,  OnInit, SimpleChanges} from '@angular/core';
import { DropdownMenu, Data , ReturnMenu } from '../models';
@Injectable({
	providedIn: 'root',
})
export class DataService implements OnInit ,OnChanges {
	alias_username:string;
	role:string;
	circle_profile:string;
	company:string;
	mode:string;
	data;
	menu_navbar:ReturnMenu[];
	dropMenu: DropdownMenu[]
	constructor() {
		const data = localStorage.getItem('data')
		const userData = JSON.parse(data);
		const company_name = userData.data_company.company_name;
		this.company = company_name;
		this.alias_username = userData.data_company.alias_username;
		this.role = userData.result.user_type;
		this.mode = localStorage.getItem('mode');
		this.data = userData;
		this.menu_navbar = userData.data_menu.return_menu;
		this.dropMenu = userData.data_menu.return_profiles;
	}
	ngOnInit(){
		const data = localStorage.getItem('data')
		const userData:Data = JSON.parse(data);
		const company_name = userData.data_company.company_name;
		this.company = company_name;
		this.alias_username = userData.data_company.alias_username;
		this.role = userData.result.user_type;
		this.mode = localStorage.getItem('mode');
		this.data = userData;
		this.menu_navbar = userData.data_menu.return_menu;
		this.dropMenu = userData.data_menu.return_profiles;
	}
	ngOnChanges(changes: SimpleChanges): void {
		const data = localStorage.getItem('data')
		const userData:Data = JSON.parse(data);
		const company_name = userData.data_company.company_name;
		this.company = company_name;
		this.alias_username = userData.data_company.alias_username;
		this.role = userData.result.user_type;
		this.mode = localStorage.getItem('mode');
		this.data = userData;
		this.menu_navbar = userData.data_menu.return_menu;
		this.dropMenu = userData.data_menu.return_profiles;
	}
}
