import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Data, DropdownMenu } from 'src/app/core/models';
import { DataService } from 'src/app/core/services/data.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
	selector: 'dropdown-navbar',
	templateUrl: './dropdown-navbar.component.html',
	styleUrls: ['./dropdown-navbar.component.scss'],
})
export class DropdownNavbarComponent implements OnInit {
	// APPEARANCE
	mode: string = localStorage.getItem('mode') || 'light'; // default'light'
	// MODE
	modeSelected: string;
	// CIRCLE PROFILE
	icon_profile: string;
	// LANGUAGES.
	supportLanguages = ['es', 'en'];
	// DROPDOWN MENU
	dropMenu: DropdownMenu[] = [];
	lang: string;
	id_locker: string;

	constructor(
	public dataService: DataService,
	public translate: TranslateService,
	private router: Router,
	private us: UserService) {

	}
	ngOnInit(): void {
		// EXTRACT DATA
		const data = localStorage.getItem('data')
		const userData:Data = JSON.parse(data);
		// INITIALIZE SUPPORT LANGUAGES
		this.supportLanguages;
		this.translate.setDefaultLang(localStorage.getItem('language').toLowerCase());
		this.lang = localStorage.getItem('language').toLowerCase();
		// DROPDOWN MENU
		this.dropMenu = userData.data_menu.return_profiles;
		// ICON PROFILE
		this.icon_profile = this.dataService.alias_username.slice(0, 2);
		this.selectLang(this.lang);
	}

	// CHANGE LANGUAGE
	langSelected:string;
	selectLang(lang: string) {
		this.translate.use(lang);
		localStorage.setItem('language',lang);
		if (lang === 'en') {
			this.langSelected = 'en';
		} else {
			this.langSelected = 'es';
		}
	}

	// CHANGE METOD
	changeMode(mode: string): void {
		this.mode = mode;
		document.body.classList.toggle('dark-mode', this.mode === 'dark');
		localStorage.setItem('mode', mode);
		this.modeSelected = this.dataService.mode;
	}

	// LOGOUT
	logout() {
		localStorage.removeItem('token');
		localStorage.removeItem('data');
		localStorage.removeItem('user_type');
		localStorage.removeItem('id_user');
		localStorage.removeItem('id_company');
		this.router.navigateByUrl('/auth');
		this.us.logout();
	}
}
