<!-- code-with-line-numbers.component.html -->
<div class="row animate__animated animate__fadeIn" style="display: flex;position: absolute;right: 45px;z-index: 10;">
	<div class="col-12" style="justify-content: end; display:flex">
		<app-button-icon *ngIf="this.mostrarButtonCopiado === false" [activo]="false" btnNombre="Copiar" btnType="secondaryMedium" icon="icon-copy" (click)="copyJson()">
		</app-button-icon>
		<app-button-icon *ngIf="this.mostrarButtonCopiado === true" [activo]="false" btnNombre="Copiado" btnType="secondaryMedium" icon="icon-check" (click)="copyJson()">
		</app-button-icon>
	</div>
</div>
<div class="animate__animated animate__fadeIn" style="display: flex; ">
	<div class="numbersCodes">
		<pre style="width:20px; overflow-x: hidden; white-space: initial;">
			<code>
				<span class="line-number" *ngFor="let line of numberedCodeLines; let i = index">{{ i + 1 }}</span>
			</code>
		</pre>
	</div>
	<div class="JsonCodes">
		<pre style="white-space: initial;">
			<code>
				<span class="code-line" *ngFor="let line of numberedCodeLines">{{ line }}</span>
			</code>
		</pre>
	</div>
</div>





