<div class="row">
	<div class="col-11">
        <label class="header-user-detail">
            {{'userDetail.title' | translate}}
        </label>
    </div>
	<div class="col-1" style="text-align: end; cursor: pointer;" (click)="closeModal()">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" class="bi bi-x-lg icon" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
        </svg>
    </div>
	<div class="col-12">
        <hr>
    </div>
	<div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
		<div class="row" style="height: 296px;">
			<div class="col-6" style="height: 40px;">
				<label class="header-qr">
					{{'userDetail.menuAccessQR' | translate}}
				</label>
			</div>
			<div class="col-6" style="	text-align: -webkit-right;" *ngIf="imgError === false">
				<a href="{{this.userData.url_token}}" target="_blank" style="text-decoration: none;">
					<app-button-icon
						[activo]="false"
						btnNombre="userDetail.btnDownloadQR"
						btnType="primaryMedium"
						icon="icon-qr">

					</app-button-icon>
				</a>
			</div>
			<div class="col-12" style="text-align: center; height: 192px;margin-top:12px;" *ngIf="imgError === false">
				<img [src]="this.userData.url_token" (error)="url_view_image=false" *ngIf="url_view_image" [width]="192" [height]="192"  alt="qr">
				<ng-container *ngIf="!url_view_image">
					<div class="row">
						<div class="col-12" style="display: flex;justify-content: center;">
							<div class="container-image">
								<div class="container-image-panding">
									<img src="../../../../assets/icons/icon-qr-dark.svg" style="margin-bottom:1rem" width="20%" alt="">
									<h2>{{'message.title_err_img_404' | translate }}</h2>
									<p>{{'message.err_img_404' | translate }}</p>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
			<div class="col-6" style="	text-align: -webkit-right;" *ngIf="imgError === true">
				<a target="_blank" style="text-decoration: none;">
					<app-button-icon [activo]="true" btnNombre="userDetail.btnDownloadQR" btnType="primaryMedium" icon="icon-qr" >

					</app-button-icon>
				</a>
			</div>
			<div class="col-12 no-access-qr" *ngIf="imgError === true">
				<div>
					<img src="../../../../../assets/icons/icon-qr-dark.svg" [width]="150" [height]="150"  alt="qr">
				</div>
				<label class="text">
					El QR no esta disponible
				</label>

			</div>
		</div>

		<div class="row" style="margin-top:12px">
			<div class="col-12">
				<label class="header-qr">
					{{'userDetail.menuInformation' | translate}}
				</label>
			</div>
		</div>

		<div class="row" style="padding-left: 22px;">

			<div class="row" style="border: 1px solid #e5e5e5;	border-radius: 20px;">
                <div class="col-12">
                    <div class="name">
                        <div class="name-txt">
                            {{'userDetail.fieldNameUser' | translate}}
                        </div>
                        <div class="name-value">
                            {{this.userData.first_name}}
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="name">
                        <div class="name-txt">
                            {{'userDetail.fieldEmailUser' | translate}}
                        </div>
                        <div class="name-value">
                            {{this.userData.email}}
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="name">
                        <div class="name-txt">
                            {{'userDetail.fieldUserID' | translate}}
                        </div>
                        <div class="name-value">
                            {{this.userData.company_employee_id || this.userData.id_user}}
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="name">
                        <div class="name-txt">
                            {{'userDetail.fieldCarrierUser' | translate}}
                        </div>
                        <div class="name-value">
                            N/A
                        </div>
                    </div>
                </div>


                <div class="col-12">
                    <div class="name">
                        <div class="name-txt">
                            {{'userDetail.fieldDayActive' | translate}}
                        </div>
                        <div class="name-value">
                            {{this.userData.active_days}}
                        </div>
                    </div>
                </div>

				<div class="col-12">
                    <div class="name">
                        <div class="name-txt">
                            {{'userDetail.fieldDateRegister' | translate}}
                        </div>
                        <div class="name-value">
                            {{this.userData.registration_date | dateFormat}}
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="name">
                        <div class="name-txt">
                            {{'userDetail.fieldExpiredDateQR' | translate}}
                        </div>
                        <div class="name-value">
                            {{this.userData.date_expire_password || this.userData.registration_date | dateFormat}}
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="name-no-border">
                        <div class="name-txt">
                            {{'userDetail.fieldAccessQR' | translate}}
                        </div>
                        <div class="name-value">
                            {{this.userData.enable_token}}
                        </div>
                    </div>
                </div>
            </div>

		</div>
	</div>
	<div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
		<div class="row" style="margin-left:6px">
			<app-dialog-audit-user [dataSend]="this.userData">
			</app-dialog-audit-user>
		</div>
	</div>
</div>
