import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ApexAxisChartSeries, ApexChart, ChartComponent, ApexDataLabels, ApexPlotOptions, ApexResponsive, ApexXAxis, ApexLegend, ApexFill, ApexStroke } from 'ng-apexcharts';

@Component({
	selector: 'app-use-lockers-operation',
	templateUrl: './use-lockers-operation.component.html',
	styleUrls: ['./use-lockers-operation.component.scss'],
})
export class UseLockersOperationComponent implements OnInit, OnChanges {
	@ViewChild('chart') chart: ChartComponent;
	public dataChartUseLockerOperation: Partial<ChartOptions>;
	// DATES
	@Input() periodBase: string;
	@Input() periodComp: string;
	@Input() dataBaseRecolect: number[] = [];
	@Input() dataBaseDelivery: number[] = [];
	@Input() dataCompRecolect: number[] = [];
	@Input() dataCompDelivery: number[] = [];
	@Input() dataBase: number[] = [];
	@Input() dataComp?: number[] = [];
	@Input() dataDays: string[] = [];
	@Input() dataDaysComp: string[] = [];
	@Input() addComp: boolean;
	tooltipUseLocker: string;
	base = [];
	comp = [];
	days = [];
	delivery = [];
	recolect = [];

	constructor(private translate: TranslateService) {
		translate.setDefaultLang(
			localStorage.getItem('language').toLowerCase()
		);
		this.tooltipUseLocker = 'tooltip.useLockersByOperation';
	}
	ngOnInit() {
		this.getData();
		this.loadDataChart();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.translate.use(event.lang);
			this.getData();
			this.loadDataChart();
		})
	}
	ngOnChanges(changes: SimpleChanges) {
		this.getData();
		this.loadDataChart();
		if(changes.periodComp !== undefined){
			this.getData();
			this.loadDataChart();
		}
		if(changes.addComp !== undefined){
			this.getData();
			this.loadDataChart();
		}
	}

	getData(){
		this.days = [];
		this.delivery = [];
		this.recolect = [];
		this.dataBaseDelivery.forEach((res) => {
			this.delivery.push(res);
		});
		this.dataBaseRecolect.forEach((res) => {
			this.recolect.push(res);
		});
		this.dataDays.forEach((res) => {
			this.days.push(res);
		});
		if(this.addComp === true){
			this.delivery.push(0);
			this.recolect.push(0);
			this.days.push(' ');
			if (this.dataCompRecolect.length > 0 &&	this.dataCompDelivery.length > 0 &&	this.dataDaysComp.length > 0) {
				this.dataCompRecolect.forEach((res) => {
					this.recolect.push(res);
				});
				this.dataCompDelivery.forEach((res) => {
					this.delivery.push(res);
				});
				this.dataDaysComp.forEach((res) => {
					this.days.push(res);
				});
			}
		}
	}
	private loadDataChart() {
		this.dataChartUseLockerOperation = {
			series: [
				{
					name: this.translate.instant('overview.orderDelivered'),
					data: this.recolect,
				},
				{
					name: this.translate.instant('overview.orderRecolected'),
					data: this.delivery,
				},
			],
			chart: {
				type: 'bar',
				height:310,
				stacked: true,
				toolbar: {
					show: true,
					tools:{
						download:`
							<div class="downloadClassUseLockerOperation">
								<label class="textExport">
									${this.translate.instant('buttons.export')}
								</label>
								<div class="icon">
								</div>
							</div>
						`,
						pan:false,
						reset:false,
						zoom:false,
						zoomin:false,
						zoomout:false,
						selection:true
					},
				},
				zoom: {
					enabled: true,
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
				},
			},
			xaxis: {
				labels: {
					show: true,
				},
				type: 'category',
				categories: this.addComp ? this.days.map((day) => this.translate.instant('day.'+day)) : this.days.map((day) => this.translate.instant('day.'+day)),
			},
			legend: {
				show: false,
				position: 'bottom',
				offsetY: 0,
				markers: {
					fillColors: ['#18345D', '#0082CB'], // Colores personalizados para los marcadores de la leyenda
				},
			},
			dataLabels: {
				enabled: true,
			},
			fill: {
				opacity: 1,
				colors: ['#18345D', '#0082CB'],
			},
		};
	}

	openToolbarMenu() {
		this.chart.chart.toolbar.show = true;
	}
}

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	responsive: ApexResponsive[];
	xaxis: ApexXAxis;
	legend: ApexLegend;
	fill: ApexFill;
	stroke: ApexStroke;
};
